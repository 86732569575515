import { defineComponent, computed } from "vue";
import SceneHeatmap from "@/components/Scene-heatmap/Scene-heatmap.vue";

export default defineComponent({
    name: "BaseDisplayImage",
    components: {
        SceneHeatmap,
    },
    props: {
        dataSrc: {
            type: String,
            required: true
        },
        dataWidth: {
            type: String,
            required: false
        },
        dataHeight: {
            type: String,
            required: false
        },
        dataObjects: {
            type: Object,
            required: false
        }
    },
    setup(props) {

        const HeatMapList = computed(() => {
            const percent = 1000 / props.dataObjects?.vdoWidth;
            const heatmap = props.dataObjects?.heatmap
            return heatmap.map((o: any) => {
                return {
                    x: Math.ceil(((o.left * props.dataObjects?.vdoWidth) / 100) * percent),
                    y: Math.ceil(((o.top * props.dataObjects?.vdoHeight) / 100) * percent),
                    value: o.value * 70,
                }
            });
        });

        const coordinateStyle = computed(() => {
            return {
                left: `${props.dataObjects?.eyecontact.left}%`,
                top: `${props.dataObjects?.eyecontact.top}%`,
                width: `${props.dataObjects?.eyecontact.width}%`,
                height: `${props.dataObjects?.eyecontact.height}%`,
            }
        })

        return {
            HeatMapList,
            coordinateStyle
        }

    }
});
