import { defineComponent } from 'vue'

export default defineComponent({
    name: "PackageItems",
    props: {
        dataItems: {
            type: Object,
            required: true
        },
        dataSummary: {
            type: Number,
            required: false
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
        locked: {
            type: Boolean,
            required: false,
            default: () => true
        }
    }
})