import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-973646be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-heatmap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heatmapjs_vue = _resolveComponent("heatmapjs-vue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_heatmapjs_vue, {
      class: "heatmapjs-container",
      id: "heatmapjs-container",
      max: 100,
      min: 0,
      data: _ctx.HeatMapList,
      options: {
        radius:100
      }
    }, null, 8, ["data"])
  ]))
}