/* eslint-disable @typescript-eslint/no-explicit-any */
// HTTP Base service Interceptor
import { HTTPBaseService } from "@/utils/httpbase.service";

import axios from "axios";

// Interfaces
import { PayloadTypes } from "@/interfaces/brand.interface";
import ResponseData from "@/interfaces/response.interface";

// Config API endpoint
import { backendHeader, backendGCPUrl, backendGCPVdo } from "@/config";

export default class BrandService extends HTTPBaseService {

    constructor() {
        super(`${backendGCPUrl}`);
    }

    public createVdo(payload: PayloadTypes) {
        const data = this.instance.post(`${backendGCPUrl}/ads`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

    public runQueueTask(queueUrl: string, payload: PayloadTypes) {

        const appToken = localStorage.getItem('hashToken');
        if (!appToken) window.location.assign('/login');

        const config = {
            headers: {
                'content-Type': 'application/json; charset=utf-8',
                [`${backendHeader}`]: `${appToken}`
            }
        };

        const data = axios.post(`${queueUrl}`, payload, config)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

    public upload(payload: PayloadTypes) {

        const config = {
            headers: {
                'content-type': 'text/plain',
            }
        };

        const data = axios.put(`${payload.uploadUrl}`, payload.fileUploaded, config)
            .then((response: ResponseData) => {
                return response.data;
            })
            .catch(function (err) {
                return err;
            });
        return data;
    }

    public createViewer(payload: PayloadTypes) {
        const data = this.instance.post(`${backendGCPUrl}/viewers`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            })
            .catch(function (err) {
                return err;
            });

        return data;
    }

    public createPoster(payload: PayloadTypes) {

        const config: any = {
            url: `${backendGCPVdo}/poster`,
            method: 'POST',
            responseType: 'blob',
            data: payload
        }

        const data = this.instance(config)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });

        return data;
    }

    public getVdoById(payload: PayloadTypes) {
        const data = this.instance.get(`${backendGCPUrl}/ads/${payload}`)
            .then((response: ResponseData) => {
                if (response) {
                    // console.log(response.data.filter((data: any) => data._id == payload).map((o: any) => o._id));
                    // const dataById = response.data.filter((o: any) => o._id === payload);
                    // console.log(response.data);
                    return response.data;
                }
            });
        return data;
    }

    public updateVdoById(id: string, payload: PayloadTypes) {
        const data = this.instance.put(`${backendGCPUrl}/ads/${id}`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

    public getTesterById(payload: PayloadTypes) {
        const id = payload;
        const data = this.instance.get(`${backendGCPUrl}/ads/${id}/viewers`)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

    public updateTesterById(payload: PayloadTypes) {
        const data = this.instance.put(`${backendGCPUrl}/viewers`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

    public getMp4LinkById(payload: PayloadTypes) {
        const data = this.instance.post(`${backendGCPVdo}/link`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            })
            .catch(function (err) {
                return err;
            });

        return data;
    }

}

