import { defineComponent, toRefs, reactive, watch } from "vue";
import { useRouter } from "vue-router";

// Components
import { AdsDetailsForm } from "@/components";

export default defineComponent({
  name: "AdsDetails",
  components: {
    AdsDetailsForm
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      isVisible: false,
      message: `Creating “โซเชียลที่ออกรสที่สุด”...`,
      type: "success",
      timeout: 0,
      isButton: true
    })

    const handleResutUpdate = (result: any) => {
      const { status, message } = result;
      if (status) {
        Object.assign(state, { isVisible: true, message: `แก้ไขข้อมูลเรียบร้อยแล้ว`, isButton: false });
      } else {
        Object.assign(state, { isVisible: true, message, isButton: false, type: "warning" });
        setTimeout(() => {
          router.push('/');
        }, 1000);
      }
    }

    const handleCopyLink = (message: string) => {
      Object.assign(state, { isVisible: true, message, isButton: false });
    }

    const handleDeleteViewer = (message: string) => {
      Object.assign(state, { isVisible: true, message, isButton: false });
    }

    watch(() => state.isVisible, (visible) => {
      if (visible) {
        state.timeout = setTimeout(() => {
          Object.assign(state, { isVisible: false });
        }, 3000);
      } else {
        clearTimeout(state.timeout);
      }
    });

    return {
      ...toRefs(state),
      handleCopyLink,
      handleResutUpdate,
      handleDeleteViewer,
    }
  }
});