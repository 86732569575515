import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "login-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, { class: "login-layout-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component, route }) => [
              _createVNode(_Transition, {
                name: "slide-fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("div", {
                    key: route.meta.usePathKey ? route.path : undefined,
                    class: "x-sight-wrapper"
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}