import { defineComponent, ref } from "vue";

// Echart Package Import and Use
import VChart from "vue-echarts";

export default defineComponent({
    name: "ExpressionChart",
    components: {
        VChart
    },
    props: {
        title: {
            type: String,
            required: true
        },
        option: {
            type: Object,
            required: true
        },
        average: {
            type: [String, Number],
            required: true
        },
        isAverageTxt: {
            type: Boolean,
            required: false
        },
    },
    setup() {

        const donutChart = ref();

        return {
            donutChart,
        }
    }
});
