import { defineComponent } from "vue";

export default defineComponent({
    name: "CompareBar",
    props: {
        amountSelected: {
            type: Number,
            required: true
        }
    },
    emits: ["action:linkto"],
    setup(_, { emit }) {

        const handleClick = () => {
            emit("action:linkto")
        }

        return { handleClick }
    }
});
