// HTTP Base service Interceptor
import { HTTPBaseService } from "@/utils/httpbase.service";

// Interfaces
import { IAdsListPayloads, IAdsDetailPayloadTypes, IPayloads } from "@/interfaces/advertise.interface";
import ResponseData from "@/interfaces/response.interface";

// Config API endpoint
import { backendDataUrl, backendGCPUrl } from "@/config";

export default class AdvertiseService extends HTTPBaseService {
    private static classInstance?: AdvertiseService;

    constructor(userIdToken?: string) {
        super(`${backendDataUrl}`, userIdToken);
    }

    public static getInstance(userIdToken?: string) {

        if (!this.classInstance) {
            this.classInstance = new AdvertiseService(userIdToken);
        }

        return this.classInstance;
    }

    public getBrands() {

        const data = this.instance.post(`${backendDataUrl}/brands/list`)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public getVideos(payload: IAdsListPayloads) {

        const data = this.instance.post(`${backendDataUrl}/videos/list`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public getVideosByID(payload: IAdsDetailPayloadTypes) {

        const data = this.instance.post(`${backendDataUrl}/videos/info`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public getVideoFrame(payload: IAdsDetailPayloadTypes) {

        const data = this.instance.post(`${backendDataUrl}/videos/getframes`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public getAdsStat(payload: IAdsDetailPayloadTypes) {

        const data = this.instance.post(`${backendDataUrl}/data/adsstat`, payload)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public deleteAds(payload: IAdsDetailPayloadTypes) {
        const data = this.instance.delete(`${backendGCPUrl}/ads/${payload}`)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public deleteViewer(payload: IAdsDetailPayloadTypes) {
        const { adid, id } = payload;
        const data = this.instance.delete(`${backendGCPUrl}/viewers/${adid}/${id}`)
            .then((response: ResponseData) => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public purgeTesterById(payload: IPayloads) {
        const { adId, viewerId } = payload;
        const data = this.instance.delete(`${backendGCPUrl}/purge/${adId}/${viewerId}`)
            .then((response: ResponseData) => {
                if (response) {
                    return response.data;
                }
            });
        return data;
    }

}

