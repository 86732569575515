import { RouteRecordRaw } from "vue-router";

// Views
import {
    AdsDetails,
    AdsListView,
    CompareView,
    NotFoundView,
    ScenesListView,
    VdoInformationView,
    PackageInformationView,
} from "@/views";

// Layouts
import {
    MainLayout,
    DetailLayout,
    CompareLayout,
} from "@/views/Layout";

// Routes
const routeDashboard: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "MainLayout",
        component: MainLayout,
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: "AdsListView",
                component: AdsListView,
                meta: {
                    title: "Ads list information",
                    className: "ads-head"
                }
            },
            {
                path: "/create-ads",
                name: "AdsDetails",
                component: AdsDetails,
                meta: {
                    title: "Ads details",
                    className: "create-head"
                }
            },
            {
                path: "/update/:id",
                name: "AdsDetailsUpdate",
                component: AdsDetails,
                meta: {
                    title: "Ads Update",
                    className: "create-head"
                }
            },
        ],
    },
    {
        path: "/vdo-information/:id",
        name: "VdoInformation",
        component: DetailLayout,
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: "VdoInformationView",
                component: VdoInformationView
            },
            {
                path: "/vdo-information/:id/:type/:filter?",
                name: "ScenesListView",
                component: ScenesListView,
                meta: {
                    title: "Scenes List",
                }
            }
        ],
    },
    {
        path: "/package-information/:id",
        name: "PackageInformation",
        component: DetailLayout,
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: "PackageInformationView",
                component: PackageInformationView
            }
        ],
    },
    {
        path: "/compare",
        component: CompareLayout,
        meta: {
            title: "Dashboard",
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: "CompareView",
                component: CompareView,
                meta: {
                    title: "Compare",
                }
            }
        ],
    },
    {
        path: "/:catchAll(.*)",
        component: DetailLayout,
        children: [
            {
                path: "",
                name: "NotFoundView",
                component: NotFoundView,
            }
        ]
    }
];

export default routeDashboard;

