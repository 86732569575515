/* eslint-disable @typescript-eslint/no-explicit-any */
import { createVNode, defineComponent, reactive, toRefs, watch, computed, onMounted } from "vue";
import { log, getTesterLink } from "@/helpers"
import { useRouter } from "vue-router"
import { useStore } from 'vuex';
import { key } from "@/store";

import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

// Components
import {
    AdsList,
    CompareBar,
} from '@/components';

// Interfaces and Types
import { AnyObjectTypes } from '@/interfaces/component.interface';
import { IBrandAndProjectList } from '@/interfaces/brand.interface';

export default defineComponent({
    name: "AdsListView",
    components: {
        Modal,
        AdsList,
        CompareBar
    },
    setup() {
        const store = useStore(key);
        const router = useRouter();

        const state = reactive({
            isVisible: false,
            message: ``,
            type: "success",
            timeout: 0,
            isButton: true
        });

        const initBrands = async () => {
            await resetExpand();
            await store.dispatch("advertise/REQUEST_BRAND_DATA");
        }

        const resetExpand = async () => {
            await brandList.value.map((o: AnyObjectTypes) => {
                o.expand = false
            });
        }

        const adsSelected = computed<IBrandAndProjectList>(() => store.getters['advertise/ADS_SELECTED']);
        const brandList = computed<IBrandAndProjectList>(() => store.getters['advertise/REQUEST_BRAND_DATA']);
        const compateSelected = computed<IBrandAndProjectList>(() => store.getters['advertise/COMPARE_ITEMS']);

        const handleExpand = (index: number): void => {
            brandList.value.map((o: AnyObjectTypes, i: number) => {
                o.expand = i === index && !o.expand ? true : false
            });
        }

        const handleLink = ({ adsId, adsMode }: any): void => {

            // console.log({ adsId, adsMode, adsJson })
            const mode = adsMode?.toLowerCase()
            let name = "VdoInformationView"

            if (mode.indexOf("video") === -1) {
                name = "PackageInformationView"
            }

            // console.log({ mode, name })
            router.push({
                name,
                params: {
                    id: adsId
                }
            });
        }

        const handleCompare = (id: string): void => {
            const compares = { id };
            store.commit("advertise/COMPARE_ITEMS", compares);
            // store.dispatch("advertise/COMPARE_ITEMS", id);
        }

        const handleClick = async () => {

            // update compare list data
            const compares = store.getters['advertise/COMPARE_ITEMS'];
            const compareMapping = await compares.map(async (o: any) => {
                return await store.dispatch("advertise/COMPARE_ITEMS", o.id);
            });

            Promise.all(compareMapping).then((values) => {
                if (values) {
                    router.push({
                        name: "CompareView"
                    });
                }
            });

        }

        const handleEdit = (id: string) => {
            router.push({
                path: `/update/${id}`
            });
        };

        const handleCopyLink = (id: string) => {
            const text = getTesterLink(id)
            navigator.clipboard.writeText(text).then(function () {
                Object.assign(state, { isVisible: true, message: `Copy Link หลักสำหรับส่งให้ Tester เพื่อมาลงทะเบียน และดูวีดีโอนี้เรียบร้อยแล้ว`, isButton: false });
            }, function (err) {
                log('Error: Could not copy text: ', err);
            });
        };

        const handleDelete = (id: string) => {

            Modal.confirm({
                centered: true,
                wrapClassName: 'delete-modal',
                content: `ต้องการลบข้อมูล ใช่ หรือ ไม่?`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'ตกลง',
                async onOk() {
                    try {
                        return await new Promise((resolve, reject) => {

                            const result: any = store.dispatch("advertise/DELETE_ADS", id);

                            if (result) {
                                Object.assign(state, { isVisible: true, message: `ลบวีดีโอเรียบร้อยแล้ว`, isButton: false });

                                setTimeout(() => {
                                    // store.commit("APP_LOADING", false);
                                    initBrands();
                                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);

                                }, 4000);
                            }

                        });
                    } catch {
                        throw new Error
                    }
                },
                cancelText: 'ยกเลิก',
                onCancel() {
                    Modal.destroyAll();
                },
            });

        };

        watch(() => state.isVisible, (visible) => {
            if (visible) {
                state.timeout = setTimeout(() => {
                    Object.assign(state, { isVisible: false });
                }, 3000);
            } else {
                clearTimeout(state.timeout);
            }
        });

        onMounted(async () => initBrands());

        return {
            ...toRefs(state),
            brandList,
            handleLink,
            handleEdit,
            handleClick,
            adsSelected,
            handleDelete,
            handleExpand,
            handleCompare,
            handleCopyLink,
            compateSelected
        };
    },
});