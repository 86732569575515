<template>
	<a-layout-footer class="x-sight layout-footer">
		© 2022 adapterdigital. All Rights Reserved
	</a-layout-footer>
</template>

<style lang="scss" scoped>
.x-sight {
	background: var(--bg-white);
	font-family: var(--font-regular);
	font-size: var(--font-sm);
	color: var(--color-gray-dim);
	.layout-footer {
		text-align: center;
	}
}
</style>