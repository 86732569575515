import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51543b87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "compare-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompareList = _resolveComponent("CompareList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.dataCompareList)
      ? (_openBlock(), _createBlock(_component_CompareList, {
          key: 0,
          "title-donut-chart": "Emotion Intensity Overview",
          "title-line-chart": "Emotion Intensity",
          "option-donut-chart": _ctx.optionDonut,
          "option-line-chart": _ctx.optionLine,
          "data-scenes": _ctx.dataScenes,
          "data-eye-impact": _ctx.dataEyeImpact,
          "data-compare-list": _ctx.dataCompareList,
          "onAction:compare": _ctx.handleCompareList,
          "onAction:remove": _ctx.handleCompareRemove
        }, null, 8, ["option-donut-chart", "option-line-chart", "data-scenes", "data-eye-impact", "data-compare-list", "onAction:compare", "onAction:remove"]))
      : _createCommentVNode("", true)
  ]))
}