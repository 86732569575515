import { LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, h, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from "@/store";

export default defineComponent({
    setup() {
        const store = useStore(key);
        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '24px',
            },
            spin: true,
        });

        const appLoading = computed(() => store.getters["APP_LOADING"]);

        return {
            indicator,
            appLoading
        };
    },
});
