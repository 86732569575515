/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineComponent, computed, ref, toRef, onMounted, inject } from "vue";
// import type { Ref } from 'vue'

// Helpers
// import { log } from '@/helpers';

// Interfaces and Types
// import { RefTypes } from '@/interfaces/component.interface';

// interface List extends IBrandAndProjectList {
//     id?: Ref<number>
// }

export default defineComponent({
    name: "AdsList",
    props: {
        data: {
            type: Object,
            required: true
        },
        compareSelected: {
            type: Array,
            required: false,
            default: () => { return [] }
        },
        adsSelected: {
            type: Array,
            required: false,
            default: () => { return [] }
        }
    },
    emits: ["action:expand", "action:link", "action:compare", "action:edit", "action:copylink", "action:delete"],
    setup(props, { emit }) {

        const propsAdsSelected = toRef(props, 'adsSelected');
        const propsCompareSelected = toRef(props, 'compareSelected');
        const roleUser: any = inject('authUser');
        const isCreateProject: any = inject('isCreateProject');

        const handleClick = (index: number) => {
            emit("action:expand", index);
        }

        const vdoLink = ({ adsId, adsMode, adsJson }: any) => {
            // console.log({ adsId, adsMode, adsJson })
            emit("action:link", { adsId, adsMode, adsJson });
        }

        const handleCompare = (id: string) => {
            emit("action:compare", id);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const countSelected: any = (id: string) => props.adsSelected.find((o: any) => o.id == id);
        const isCompareSelected: any = (id: string) => props.compareSelected.some((o: any) => o.id === id);
        const isCompareMaxSelected = computed(() => props.compareSelected.length < 6 ? true : false);

        const handleEdit = (id: string) => {
            emit("action:edit", id);
        };

        const handleCopyLink = (id: string) => {
            emit("action:copylink", id);
        };

        const handleDelete = (id: string) => {
            emit("action:delete", id);
        };

        return {
            isCreateProject,
            vdoLink,
            roleUser,
            handleEdit,
            handleClick,
            handleDelete,
            countSelected,
            handleCompare,
            handleCopyLink,
            propsAdsSelected,
            isCompareSelected,
            isCompareMaxSelected,
            propsCompareSelected,
        }
    }
});
