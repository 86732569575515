import { defineComponent, toRef } from "vue";

export default defineComponent({
    name: "BaseLogo",
    props: {
        isExpand: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            required: true
        }
    },
    setup(prop) {

        const propsImage = toRef(prop, 'data');
        const propsIsExpand = toRef(prop, 'isExpand');

        return { propsImage, propsIsExpand }
    }
});
