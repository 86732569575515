import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0a441cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_spin, { indicator: _ctx.indicator }, null, 8, ["indicator"])
  ], 512)), [
    [_vShow, _ctx.appLoading]
  ])
}