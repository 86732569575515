import { defineComponent, toRef } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "BaseButton",
    props: {
        isExpand: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            required: true,
            default: () => {
                const button = {
                    name: 'Create Project',
                    icon: require('@/assets/images/icons/icon-plus.png'),
                    link: "/"
                }
                return button;
            }
        }
    },
    setup(prop) {

        const router = useRouter();
        const propsButton = toRef(prop, 'data');
        const propsIsExpand = toRef(prop, 'isExpand');

        const handleClick = (link: string) => {
            router.push(link);
        }

        return {
            handleClick,
            propsButton,
            propsIsExpand,
        }
    }
});
