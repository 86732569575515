import { defineComponent } from 'vue'
export default defineComponent({
    name: "BaseSwitch",
    emits: ['swith:heatmap', 'swith:eyecontact'],
    props: ['isHeatmap', 'isEyecontact'],
    setup(_, { emit }) {

        const toggleHeatmapHeadler = () => {
            emit('swith:heatmap')
        };

        const toggleEyecontactHeadler = () => {
            emit('swith:eyecontact')
        };

        return {
            toggleHeatmapHeadler,
            toggleEyecontactHeadler
        }
    }
})