// HTTP Base service Interceptor
import { HTTPBaseService } from '@/utils/httpbase.service'

// Interfaces
import { SignInGoogleTypes, AuthenAPITypes, PayloadTypes } from "@/interfaces/authen.interface";

// Config API endpoint
import { backendHostUrl, backendDataUrl } from "@/config"

export default class AuthenService extends HTTPBaseService {
    private static classInstance?: AuthenService;

    constructor(userIdToken?: string) {
        super(`${backendHostUrl}`, userIdToken);
    }

    public static getInstance(userIdToken?: string) {

        if (!this.classInstance) {
            this.classInstance = new AuthenService(userIdToken);
        }

        return this.classInstance;
    }

    public signIn(playload: object): Promise<SignInGoogleTypes> {

        const data = this.instance
            .post(`${backendHostUrl}/profile/adapterloginwithgoogletoken`, playload)
            .then(response => {
                if (response) {
                    return response.data;
                }
            });

        return data;
    }

    public verify(userIdToken: AuthenAPITypes) {

        const payload = { token: userIdToken };

        const data = this.instance
            .post(`${backendHostUrl}/profile/validateToken`, payload)
            .then(response => {
                if (response) {
                    return response;
                }
            });

        return data;
    }

    public getInfo(token: AuthenAPITypes) {

        const payload = { token: token };

        const data = this.instance
            .post(`${backendHostUrl}/profile/getinfo`, payload)
            .then(response => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public pagePermission(payload: PayloadTypes) {
        const data = this.instance
            .post(`${backendHostUrl}/profile/checkPagePermission`, payload)
            .then(response => {
                if (response) {
                    return response;
                }
            });
        return data;
    }

    public appConfig(token: PayloadTypes) {

        const payload = { token: token };

        const data = this.instance
            .post(`${backendDataUrl}/appconfig`, payload)
            .then(response => {
                if (response) {
                    return response?.data;
                }
            });
        return data;
    }
}

