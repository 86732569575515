import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0248d191"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "package-container" }
const _hoisted_2 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoMain = _resolveComponent("VideoMain")!
  const _component_TopDesignScore = _resolveComponent("TopDesignScore")!
  const _component_PackagingModalContent = _resolveComponent("PackagingModalContent")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_VideoMain, {
        SetVDO: _ctx.SetVDO,
        optionDonutData: _ctx.optionChart,
        "data-packages": _ctx.packagingData
      }, null, 8, ["SetVDO", "optionDonutData", "data-packages"]),
      _createVNode(_component_TopDesignScore, {
        data: _ctx.packagingData,
        "onClick:modal": _ctx.handlerModal,
        "onSwitch:heatmap": _ctx.handlerHeatmap,
        "onSwitch:eyecontact": _ctx.HandlerEyeContact
      }, null, 8, ["data", "onClick:modal", "onSwitch:heatmap", "onSwitch:eyecontact"])
    ]),
    _createVNode(_component_BaseModal, {
      "is-visible": _ctx.isVisibleScene,
      "data-title": _ctx.packageType == 'design' ? 'Design test' : 'Competitor test',
      "data-mask-style": {
			backgroundColor: '#131523',
			opacity: 0.3,
		},
      "data-popup": {
			head: _ctx.packageType == 'design' ? _ctx.packagingData?.popup?.design?.head : _ctx.packagingData?.popup?.competitor?.head,
			body: _ctx.packageType == 'design' ? _ctx.packagingData?.popup?.design?.body : _ctx.packagingData?.popup?.competitor?.body
		},
      "data-class": "modal-design-details",
      "data-width": "944px",
      "onModal:update": _ctx.handlerModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PackagingModalContent, {
            "package-list": _ctx.packageList,
            "package-type": _ctx.packageType,
            "is-visible": _ctx.isVisibleScene,
            "package-data": _ctx.packagingData,
            "package-details": _ctx.packageDetails
          }, null, 8, ["package-list", "package-type", "is-visible", "package-data", "package-details"])
        ])
      ]),
      _: 1
    }, 8, ["is-visible", "data-title", "data-popup", "onModal:update"])
  ], 64))
}