import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69dccff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.imageLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "cropimg-container",
        style: _normalizeStyle(_ctx.imageLoaded.container)
      }, [
        _createElementVNode("div", {
          class: "cropimg",
          style: _normalizeStyle(_ctx.imageLoaded.cropimg)
        }, [
          _createElementVNode("img", {
            class: "croped",
            src: _ctx.data.src,
            alt: _ctx.data.alt,
            style: _normalizeStyle(_ctx.imageLoaded.croped)
          }, null, 12, _hoisted_1)
        ], 4)
      ], 4))
    : _createCommentVNode("", true)
}