import { defineComponent, ref, toRef, computed } from "vue";

// Echart Package Import and Use
import VChart from "vue-echarts";

export default defineComponent({
    name: "ExpressionAndSignal",
    components: {
        VChart
    },
    props: {
        titleDonutChart: {
            type: String,
            required: true
        },
        titleLineChart: {
            type: String,
            required: true
        },
        optionDonutChart: {
            type: Object,
            required: true
        },
        labelLine: {
            type: Object,
            required: true
        },
        optionLineChart: {
            type: Object,
            required: true
        },
        signalAverage: {
            type: [String, Number],
            required: true
        },
        expressionAverage: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {

        const donutChart = ref();
        const lineChart = ref();
        const titleDonut: any = toRef(props, 'titleDonutChart');
        const titleLine: any = toRef(props, 'titleLineChart');
        const option: any = toRef(props, 'optionDonutChart');
        const propsLabelLine: any = toRef(props, 'labelLine');
        const optionLine: any = toRef(props, 'optionLineChart');
        const graphLine: any = computed(() => optionLine.value.series[0].data || []);

        return {
            option,
            lineChart,
            titleLine,
            titleDonut,
            graphLine,
            optionLine,
            donutChart,
            propsLabelLine,
        }

    },
});
