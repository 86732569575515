import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!

  return (_openBlock(), _createBlock(_component_Vue3Lottie, {
    animationData: _ctx.animationData,
    height: _ctx.height,
    width: _ctx.width
  }, null, 8, ["animationData", "height", "width"]))
}