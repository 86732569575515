import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b778a27a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.propsButton)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["btn ads-create mt-m mb-s", { collapse: _ctx.propsIsExpand }])
      }, [
        _createElementVNode("img", {
          class: "icon",
          src: _ctx.propsButton.icon,
          alt: "plus"
        }, null, 8, _hoisted_1),
        (!_ctx.propsIsExpand)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.propsButton.name), 1))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}