import { defineComponent } from 'vue'
import { capitalizeFirstLetter } from "@/helpers";

export default defineComponent({
    name: "VideoLegends",
    emits: ['action:handler-series'],
    props: ['DashboardConfig'],
    setup(_, { emit }) {

        const ToggleSerie = (item: any) => {
            emit('action:handler-series', item)
        }

        return { ToggleSerie, capitalizeFirstLetter }
    }
})