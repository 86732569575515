/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, computed } from 'vue';

// Components
import {
    CompareList,
} from '@/components';

// Echart Package Import and Use
import { use, graphic, ComposeOption } from "echarts/core"
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    GridComponent,
    GridComponentOption
} from 'echarts/components';
import {
    PieChart,
    PieSeriesOption,
    LineChart,
    LineSeriesOption
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { store } from '@/store';
import { useRouter } from 'vue-router';

use([
    PieChart,
    LineChart,
    LabelLayout,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    TooltipComponent,
    UniversalTransition
]);

type EChartsOption = ComposeOption<
    TooltipComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PieSeriesOption
    | LineSeriesOption
>;

export default defineComponent({
    name: "CompareView",
    components: {
        CompareList
    },
    setup() {

        const router = useRouter();

        // Expression & Signal
        const optionDonut = ref<EChartsOption>({
            tooltip: {
                show: false,
                trigger: 'item'
            },
            legend: {
                show: false,
                orient: "vertical",
                left: "left",
                data: [
                    'Angry',
                    'Disgusted',
                    'Happy',
                    'Sad',
                    'Surprised',
                    'Fearful'
                ]
            },
            series: [
                {
                    // name: 'Access From',
                    type: 'pie',
                    radius: ['70%', '100%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 2,
                        // borderColor: '#fff',
                        // borderWidth: 2
                    },
                    color: ["#D63031", "#00B894", "#FD79A8", "#2970FD", "#FDCB6E", "#6C5CE7"],
                    label: {
                        // show: false,
                        rotate: 0,
                        position: 'inside',
                        alignTo: 'none',
                        color: '#FFFFFF',
                        fontSize: 10,
                        // fontStyle: 'normal',
                        // fontWeight: 'normal',
                        // lineHeight: 10,
                        // bleedMargin: 10,
                        // edgeDistance: '2%',
                        // distanceToLabelLine: 0,
                        fontFamily: 'IBM Plex Bold',
                        padding: 0,
                        // distance: 35,
                        align: 'center',
                        verticalAlign: 'middle',
                        // offset: [5, 5],
                        formatter: '{d}%',
                        // rich: {
                        //     d: {
                        //         color: 'red',
                        //         lineHeight: 0,
                        //     }
                        // }
                    },
                    emphasis: {
                        scale: false,
                        label: {
                            // show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 20, name: 'Angry' },
                        { value: 2, name: 'Disgusted' },
                        { value: 40, name: 'Happy' },
                        { value: 20, name: 'Sad' },
                        { value: 10, name: 'Surprised' },
                        { value: 8, name: 'Fearful' },
                    ]
                }
            ]
        });

        const optionLine = ref<EChartsOption>({
            color: ['#6C5CE7'],
            grid: {
                // show: false,
                left: '0',
                right: '0',
                bottom: '0',
                top: '10%',
                containLabel: false,
                backgroundColor: 'transparent',
            },
            legend: {
                show: false,
                padding: 0
            },
            aria: {
                enabled: false,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    label: {
                        width: 10,
                        backgroundColor: '#6C5CE7'
                    },
                    lineStyle: {
                        color: '#00CEC9',
                        width: 3,
                        type: 'dotted',
                    }
                },
                borderRadius: 100,
                borderWidth: 1,
                borderColor: 'rgba(193, 17, 21, 0.3)',
                backgroundColor: '#2D3436',
                padding: [5, 10],
                textStyle: {
                    fontFamily: 'IBM Plex Light',
                    fontSize: 10,
                    color: '#FFFFFF'

                },
                formatter: '{b0} {c0}%'
            },
            xAxis: {
                show: false,
                type: 'category',
                boundaryGap: false,
                data: ['Angry', 'Disgusted', 'Happy', 'Sad', 'Surprised', 'Fearful', 'Signal'],
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    show: false
                },
                splitLine: {
                    show: false
                },
            },
            series: [
                {
                    data: [20, 5, 30, 20, 10, 5, 10],
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 2
                    },
                    zlevel: 10,
                    z: 10,
                    showSymbol: false,
                    symbol: 'circle',
                    symbolSize: 8,
                    itemStyle: {
                        borderWidth: 2,
                        borderColor: '#353F6C',
                    },
                    areaStyle: {
                        opacity: 0.1,
                        color: new graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#6C5CE7'
                            },
                            {
                                offset: 1,
                                color: '#6C5CE7'
                            }
                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    }
                }
            ]
        });

        const dataScenes = computed(() => {
            const data = [
                {
                    StartTime: "00",
                    EndTime: "5.40",
                    image: "URL",
                    emotiondata: {
                        "angry": 22,
                        "disgusted": 22,
                        "fearful": 0,
                        "happy": 1,
                        "sad": 2,
                        "surprised": 1
                    },
                    expression: 57,
                    eyeimpact: 2,
                    eyeattention: 2,
                    speechtext: `เกย์ วอลล์เซ็นเตอร์โฮสเตสศิลปวัฒนธรรมดีพาร์ตเมนต์ ลาติน แดรี่ผิดพลาดเยอร์บีราซิ่ง ซีน สไตรค์`,
                    Photolist: [
                        require("@/assets/images/dashboard/scene_mockup/scene1.jpg"),
                        require("@/assets/images/dashboard/scene_mockup/scene2.jpg")
                    ]
                }
            ];
            return data;
        });

        const dataEyeImpact = computed(() => {
            const data = [
                {
                    StartTime: "00",
                    EndTime: "5.40",
                    image: "URL",
                    emotiondata: {
                        "angry": 22,
                        "disgusted": 22,
                        "fearful": 0,
                        "happy": 1,
                        "sad": 2,
                        "surprised": 1
                    },
                    expression: 57,
                    eyeimpact: 2,
                    eyeattention: 2,
                    speechtext: '',
                    Photolist: [
                        require("@/assets/images/dashboard/scene_mockup/scene1.jpg"),
                        require("@/assets/images/dashboard/scene_mockup/scene2.jpg")
                    ],
                    eyeListData: [
                        {
                            time: '0',
                            left: 0.3,
                            top: 0.3,
                            right: 0.5,
                            buttom: 0.6
                        },
                        {
                            time: '1',
                            left: 0.5,
                            top: 0.5,
                            right: 0.7,
                            buttom: 0.9
                        },
                    ]
                }
            ];
            return data;
        });

        const dataCompareList = computed(() => store.getters['advertise/COMPARE_ITEMS']);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleCompareList = (data: any) => {
            store.commit('advertise/COMPARE_ITEMS_MOVE', data);
            // console.log({ data });
            if (data.length == 0) {
                router.push('/');
            }
        }

        const handleCompareRemove = (id: string) => {
            const compare = store.getters['advertise/COMPARE_ITEMS'];
            if (compare.length == 0) {
                router.push('/');
            }
            const compares = { id };
            store.commit("advertise/COMPARE_ITEMS", compares);
        }

        return {
            optionLine,
            optionDonut,
            dataScenes,
            dataEyeImpact,
            dataCompareList,
            handleCompareList,
            handleCompareRemove
        };
    }
});