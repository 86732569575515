/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, onMounted, watch, toRef, onBeforeUpdate } from "vue";
import { HeatMapPoint } from "@/interfaces/dashboard.interface";
import { iHeatmapdata } from "@/interfaces/advertise.interface";
import SceneHeatmap from "@/components/Scene-heatmap/Scene-heatmap.vue";

export default defineComponent({
  name: "AttentionSignalScene",
  props: {
    imageList: {
      type: Array,
      required: true,
    },
    eyeListData: {
      type: Array,
      required: false,
    },
    eyeImpactShow: {
      type: Boolean,
    },
    objectCoordinate: {
      type: Array,
    },
    playAnimateScenes: {
      type: Object,
    },
    frameSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    peakScene: {
      type: Object,
    },
    HeatMapData: {
      type: Array,
      required: false,
    },
    vdoWidth: {
      type: Number,
      default: 1920,
    },
    vdoHeight: {
      type: Number,
      default: 1080,
    },
    switchHeatmap: {
      type: Boolean,
      required: false,
      default: false,
    },
    switchEyecontact: {
      type: Boolean,
      required: false,
      default: false,
    },
    frameBorder: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    SceneHeatmap,
  },
  emits: ['switch:heatmap', 'switch:eyecontact'],
  setup(prop, { emit }) {
    const showIndex = ref<number>(0);
    const coordinateStyle = ref<any>(null);
    const objectFilter = ref<any | null>();
    const showImage = ref<any | null>(null);
    const showFrame = ref<any | null>(null);
    const peakSceneProp = toRef(prop, "peakScene");
    const imageListProp = toRef(prop, "imageList");
    const eyeListDataProp = toRef(prop, "eyeListData");
    const eyeImpactShowProp = toRef(prop, "eyeImpactShow");
    const objectCoordinateProp = toRef(prop, "objectCoordinate");
    const playAnimateScenesProp = toRef(prop, "playAnimateScenes");
    const HeatMapDataProp = toRef(prop, "HeatMapData");

    const HeatMapList = ref<HeatMapPoint[]>();

    const handleImageChange = () => {
      HeatMapList.value = [];
      if (typeof imageListProp.value[showIndex.value] !== "undefined") {
        showImage.value =
          peakSceneProp.value?.poster ?? imageListProp.value?.[showIndex.value];

        if (eyeListDataProp.value && eyeImpactShowProp.value) {
          if (playAnimateScenesProp.value) {
            if (playAnimateScenesProp.value.index >= 0) {
              showFrame.value =
                eyeListDataProp.value[playAnimateScenesProp.value.index];
              coordinateStyle.value = {
                left: `${showFrame.value.left}%`,
                top: `${showFrame.value.top}%`,
                width: `${showFrame.value.width}%`,
                height: `${showFrame.value.height}%`,
              };

              if (objectCoordinateProp.value) {
                objectFilter.value =
                  objectCoordinateProp.value[playAnimateScenesProp.value.index];
              } else {
                objectFilter.value = null;
              }

              //
              // if (HeatMapDataProp.value) {
              //   const HeatMapArray = <iHeatmapdata[]>(
              //     HeatMapDataProp.value[playAnimateScenesProp.value.index]
              //   );
              //   HeatMapList.value = [];

              //   console.log(HeatMapArray);

              //   const CompilePercent = 1000 / prop.vdoWidth;

              //   HeatMapArray.forEach((point) => {
              //     const PointData = {
              //       x: Math.ceil(
              //         ((point.left * prop.vdoWidth) / 100) * CompilePercent
              //       ),
              //       y: Math.ceil(
              //         ((point.top * prop.vdoHeight) / 100) * CompilePercent
              //       ),
              //       value: point.value * 70,
              //     };
              //     HeatMapList.value?.push(PointData);
              //   });
              //   console.log(HeatMapList);
              // }
            } else {
              coordinateStyle.value = null;
              objectFilter.value = null;
            }
          } else if (peakSceneProp.value) {
            showFrame.value = eyeListDataProp.value[peakSceneProp.value.index];
            coordinateStyle.value = {
              left: `${showFrame.value.left}%`,
              top: `${showFrame.value.top}%`,
              width: `${showFrame.value.width}%`,
              height: `${showFrame.value.height}%`,
            };

            if (objectCoordinateProp.value) {
              objectFilter.value =
                objectCoordinateProp.value[peakSceneProp.value.index];
            } else {
              objectFilter.value = null;
            }
          } else {
            coordinateStyle.value = null;
            objectFilter.value = null;
          }
        }

        // heatmap show
        if (HeatMapDataProp.value && prop.switchHeatmap) {
          if (playAnimateScenesProp.value) {
            if (playAnimateScenesProp.value.index >= 0) {
              //

              const HeatMapArray = <iHeatmapdata[]>(
                HeatMapDataProp.value[playAnimateScenesProp.value.index]
              );
              HeatMapList.value = [];


              const CompilePercent = 1000 / prop.vdoWidth;

              HeatMapArray.forEach((point) => {
                const PointData = {
                  x: Math.ceil(
                    ((point.left * prop.vdoWidth) / 100) * CompilePercent
                  ),
                  y: Math.ceil(
                    ((point.top * prop.vdoHeight) / 100) * CompilePercent
                  ),
                  value: point.value * 70,
                };
                HeatMapList.value?.push(PointData);
              });
            }
          } else if (peakSceneProp.value) {
            const HeatMapArray = <iHeatmapdata[]>(
              HeatMapDataProp.value[0]
            );
            HeatMapList.value = [];

            // console.log(HeatMapArray);

            const CompilePercent = 1000 / prop.vdoWidth;

            HeatMapArray.forEach((point) => {
              const PointData = {
                x: Math.ceil(
                  ((point.left * prop.vdoWidth) / 100) * CompilePercent
                ),
                y: Math.ceil(
                  ((point.top * prop.vdoHeight) / 100) * CompilePercent
                ),
                value: point.value * 70,
              };
              HeatMapList.value?.push(PointData);
            });
            // console.log(HeatMapList);

          }
        }
      }
    };

    const toggleHeatmapHeadler = () => {
      emit("switch:heatmap")
    }

    const toggleEyecontactHeadler = () => {
      emit("switch:eyecontact")
    }

    watch(
      () => prop.switchHeatmap,
      () => {
        // console.log('switchHeatmap')
        handleImageChange();
      }
    );

    watch(
      () => prop.switchEyecontact,
      () => {
        // console.log('switchEyecontact')
        handleImageChange();
      }
    );

    watch(
      () => objectCoordinateProp.value,
      () => {
        handleImageChange();
      }
    );

    watch(
      () => playAnimateScenesProp.value,
      () => {
        handleImageChange();
      }
    );

    onMounted(() => {
      handleImageChange();
    });

    onBeforeUpdate(() => {
      // console.log("onBeforeUpdate", prop.switchHeatmap)
      handleImageChange();
    });

    return {
      showImage,
      showFrame,
      objectFilter,
      coordinateStyle,
      eyeImpactShowProp,
      playAnimateScenesProp,
      HeatMapList,
      toggleHeatmapHeadler,
      toggleEyecontactHeadler
    };
  },
});
