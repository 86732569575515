/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRef, reactive, toRefs, computed, watch } from "vue"

// Interfaces
import { IAgeRange, IFilters } from '@/interfaces/component.interface';

import { log } from '@/helpers';

export default defineComponent({
    name: "FilterComponent",
    props: {
        data: { type: Object },
        filter: { type: Object },
    },
    emits: ["expand:filters", "filter:agerange"],
    setup(props, { emit }) {
        const propsData = toRef(props, 'data');
        const propsFilters = toRef(props, 'filter');
        const ageFilterList = ref<IFilters[]>([]);
        const customFilterList = ref<IFilters[]>([]);
        const customRangeValue = reactive<IAgeRange>({ start: 0, end: 0 });
        const ageRangeCustom = ref<[number, number]>([0, 100]);
        const disabled = ref<boolean>(false);
        const disabledAgeRange = ref<boolean>(false);
        const isExpandAgeRange = ref<boolean>(false);
        const isExpandGender = ref<boolean>(false);
        const countFilterSelected = ref<number>(0);
        const payload = reactive({ ageRange: "", genders: "" });

        const handleSelecteAgeRange = (id: number) => {

            // reset custom age
            // console.log({ id })
            // console.log(ageRangeCustom.value)
            // console.log(countFilterSelected.value)
            // console.log({ ageFilterList: ageFilterList.value })

            const totalFilters = ageFilterList.value.filter((o: any) => o.name !== 'All').length

            if (customRangeValue.start > 0 && customRangeValue.end > 0 && countFilterSelected.value == 0)
                ageRangeCustom.value = [0, 100]

            if (id == 0) {

                const selectedFalse = ageFilterList.value.filter((o: any) => o.isSelected).length

                if (selectedFalse < totalFilters) {
                    ageFilterList.value.map((o: IFilters) => {
                        o.isSelected = true
                    });
                } else {
                    ageFilterList.value.map((o: IFilters) => {
                        if (o.isSelected)
                            o.isSelected = false
                        else
                            o.isSelected = true
                    });
                }

            } else {
                const findIndex = ageFilterList.value.findIndex((o, index) => index === id);


                if (findIndex !== -1) {

                    ageFilterList.value.map((o: IFilters, i) => {
                        if (i === 0) o.isSelected = false
                        if (i === findIndex && o.isSelected)
                            o.isSelected = false
                        else if (i === findIndex && !o.isSelected)
                            o.isSelected = true
                    });

                    const selectedFalse = ageFilterList.value.filter((o: any) => o.isSelected).length
                    // console.log({ selectedFalse })

                    if (selectedFalse == totalFilters) {
                        ageFilterList.value.map((o: IFilters, i) => {
                            if (i === 0) o.isSelected = true
                        });
                    }
                }
            }

            // count selected
            countFilterSelected.value = ageFilterList.value.filter((o: IFilters) => o?.isSelected == true)?.length;

            // call api ...
            if (ageFilterList.value) {

                const filters = ageFilterList.value
                    .filter((o: IFilters, i: any) => o?.isSelected == true && i > 0)
                    .map((m: any) => m.name);

                const num = filters.length;

                let ageRange = ""

                if (num > 0) {
                    // filters.shift();
                    ageRange = filters.join('|');
                }

                payload.ageRange = ageRange

                emit("filter:agerange", payload);
                // console.log({ 'call api': payload })
            }

        }

        const handleCustomRange = (id: number) => {

            // if (customRangeValue.start > 0 && customRangeValue.end > 0 && countFilterSelected.value == 0)
            //     ageRangeCustom.value = [0, 100]

            const totalFilters = customFilterList.value.filter((o: any) => o.name !== 'All').length

            if (id == 0) {

                const selectedFalse = customFilterList.value.filter((o: any) => o.isSelected).length

                if (selectedFalse < totalFilters) {
                    customFilterList.value.map((o: IFilters) => {
                        o.isSelected = true
                    });
                } else {
                    customFilterList.value.map((o: IFilters) => {
                        if (o.isSelected)
                            o.isSelected = false
                        else
                            o.isSelected = true
                    });
                }

            } else {
                const findIndex = customFilterList.value.findIndex((o, index) => index === id);

                if (findIndex !== -1) {

                    customFilterList.value.map((o: IFilters, i) => {
                        if (i === 0) o.isSelected = false
                        if (i === findIndex && o.isSelected)
                            o.isSelected = false
                        else if (i === findIndex && !o.isSelected)
                            o.isSelected = true
                    });

                    const selectedFalse = customFilterList.value.filter((o: any) => o.isSelected).length

                    if (selectedFalse == totalFilters) {
                        customFilterList.value.map((o: IFilters, i) => {
                            if (i === 0) o.isSelected = true
                        });
                    }

                }
            }

            // count selected
            countFilterSelected.value = customFilterList.value.filter((o: IFilters) => o?.isSelected == true)?.length;

            if (customFilterList.value) {

                const filters = customFilterList.value
                    .filter((o: IFilters, i: any) => o?.isSelected == true && i > 0)
                    .map((m: any) => m.name);

                const num = filters.length;

                let genders = ""

                if (num > 0) {
                    // filters.shift();
                    genders = filters.join('|');
                }

                payload.genders = genders

                emit("filter:agerange", payload);
                // console.log({ 'call api': payload })
            }
        }

        const ageFilterListFetch = computed(() => {
            const data = propsData.value?.ageRange;
            if (data) {

                data.map((o: IFilters) => {
                    // const objectAdd: IFilters = {
                    //     name: o.name,
                    //     value: o.value,
                    //     isSelected: false
                    // }
                    if (ageFilterList.value)
                        ageFilterList.value.push(o)
                });

            }
            return ageFilterList.value;
        })

        const customFilterListFetch = computed(() => {
            const genders = propsData.value?.gender;
            if (genders) {

                genders.map((o: IFilters) => {
                    // const objectAdd: IFilters = {
                    //     name: o.name,
                    //     value: o.value,
                    //     isSelected: false
                    // }
                    if (customFilterList.value)
                        customFilterList.value.push(o)
                });

            }
            return customFilterList.value
        })

        const handleSlideChange = (value: any) => {
            const [start, end] = value

            customRangeValue.start = start
            customRangeValue.end = end

            if (start > 0 || end > 0) {
                // reset age range
                // console.log(countFilterSelected.value);

                payload.ageRange = `${start}-${end}`
                emit("filter:agerange", payload);

                countFilterSelected.value = 0;
                ageFilterList.value.map((o: IFilters) => {
                    o.isSelected = false
                });
                // customFilterList.value.map((o: IFilters) => {
                //     o.isSelected = false
                // });
            }
        }

        // check watch reset filters
        watch(() => propsFilters.value, (filter) => {
            log('filter', [filter])
            if (!filter) return;
            if (filter && (!filter.ageRange && !filter.genders)) {
                ageRangeCustom.value = [0, 100];
                payload.ageRange = '';
                payload.genders = '';
            }
        });

        return {
            disabled,
            propsData,
            propsFilters,
            ageFilterList,
            isExpandGender,
            ageRangeCustom,
            isExpandAgeRange,
            disabledAgeRange,
            handleCustomRange,
            handleSlideChange,
            ageFilterListFetch,
            countFilterSelected,
            handleSelecteAgeRange,
            customFilterListFetch,
            ...toRefs(customRangeValue)
        }
    }
})