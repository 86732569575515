import { defineComponent } from "vue";

export default defineComponent({
    name: "LoadingPlayback",
    props: {
        animationData: {
            type: Object,
            required: true
        },
        width: {
            type: Number,
            required: true,
            default: 174
        },
        height: {
            type: Number,
            required: true,
            default: 174
        }
    }
});
