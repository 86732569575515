/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, reactive, computed, watch, onMounted, onUpdated, inject, provide } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { key } from "@/store";
import _ from "lodash";

// Helpers
import { log } from '@/helpers';

// Interfaces
import {
    IFilters,
    propsLogo,
    IParticipants,
    IBasePropsList,
} from "@/interfaces/component.interface";
import { RefTypes } from "@/interfaces/component.interface";
// import { CodepenOutlined } from "@ant-design/icons-vue";

export default defineComponent({
    name: "DetailLayout",
    setup() {

        const route = useRoute();
        const router = useRouter();
        const store = useStore(key);
        const isExpand = ref(false);
        const totalSelected = ref(0)
        const ShowRouteFilter = ref(true);
        const sceneData: RefTypes = ref(null);
        const isParticipantsLoad: any = ref(true);
        const dataSelectItems = reactive<IFilters[]>([]);
        const dataParticipants = reactive<IParticipants[]>([]);
        const roleUser: any = inject('authUser');

        // Logo
        const dataLogo = reactive<propsLogo>({
            image_expand: require('@/assets/images/logo_xsight.png'),
            image_collapse: require('@/assets/images/logo_xsight_sidebar.png'),
        });

        const dataPlayer = computed(() => {

            const AdsInfo = store.getters['advertise/REQUEST_ADS_DETAIL'];
            const allBrands = store.getters['advertise/REQUEST_ADVERTISE_DATA'];
            // log({ allBrands })
            // grouping ads list
            let dataBrands: any[] = allBrands.filter((o: any) => o.adid !== AdsInfo.adid).map((a: any) => {
                return {
                    adid: a.adid,
                    name: a.adtitle,
                    value: a.adtitle,
                    mode: a.displaymode
                }
            });

            dataBrands = [...dataBrands];

            // dataBrands = [...dataBrands,
            // {
            //     adid: "1",
            //     name: "เปลี่ยนมุมธรรมดา ให้ไม่ธรรมดา...",
            //     value: "เปลี่ยนมุมธรรมดา ให้ไม่ธรรมดา...",
            // },
            // {
            //     adid: "2",
            //     name: "Awesome 5G Colors",
            //     value: "Awesome 5G Colors",
            // }
            // ]

            let dataExpression = [{
                title: "Participants",
                percent: AdsInfo.participant || 0,
                filterpercent: AdsInfo.Filterviewer || 0,
                FilterEmpty: AdsInfo.FilterEmpty,
            }]

            const displaymode = AdsInfo?.displaymode?.toLowerCase()

            if (displaymode && displaymode.indexOf('video') !== -1) {

                dataExpression = [...dataExpression, {
                    title: "Attention Signals Score",
                    percent: AdsInfo.attentionsignalscore || 0,
                    filterpercent: AdsInfo.FilterSignalScore || 0,
                    FilterEmpty: AdsInfo.FilterEmpty,
                },
                {
                    title: "Emotion Intensity",
                    percent: AdsInfo.expression || 0,
                    filterpercent: AdsInfo.FilterExpression || 0,
                    FilterEmpty: AdsInfo.FilterEmpty,
                },
                {
                    title: "Visual Impact",
                    percent: AdsInfo.eyeimpact || 0,
                    filterpercent: AdsInfo.FilterEyeImpact || 0,
                    FilterEmpty: AdsInfo.FilterEmpty,
                }]
            }

            const players = {
                dataBrand: {
                    account: AdsInfo.brandtitle || "",
                    project: AdsInfo.projecttitle || "",
                    adtitle: AdsInfo.adtitle || "",
                    adslist: dataBrands,
                },
                dataExpression
                // dataExpression: [
                //     {
                //         title: "Participants",
                //         percent: AdsInfo.participant || 0,
                //         filterpercent: AdsInfo.Filterviewer || 0,
                //         FilterEmpty: AdsInfo.FilterEmpty,
                //     },
                //     {
                //         title: "Attention Signals Score",
                //         percent: AdsInfo.attentionsignalscore || 0,
                //         filterpercent: AdsInfo.FilterSignalScore || 0,
                //         FilterEmpty: AdsInfo.FilterEmpty,
                //     },
                //     {
                //         title: "Emotion Intensity",
                //         percent: AdsInfo.expression || 0,
                //         filterpercent: AdsInfo.FilterExpression || 0,
                //         FilterEmpty: AdsInfo.FilterEmpty,
                //     },
                //     {
                //         title: "Visual Impact",
                //         percent: AdsInfo.eyeimpact || 0,
                //         filterpercent: AdsInfo.FilterEyeImpact || 0,
                //         FilterEmpty: AdsInfo.FilterEmpty,
                //     },
                // {
                //     title: "Video Attention",
                //     percent: AdsInfo.eyeattention || 0,
                //     filterpercent: AdsInfo.FilterEyeattention || 0,
                //     FilterEmpty: AdsInfo.FilterEmpty
                // },
                // ],
            };

            return players;
        })

        provide("AdsInfo", dataPlayer);

        const dataSortBy = reactive<IFilters[]>([
            // {
            //     name: "Overall Score",
            //     value: "overall",
            //     isSelected: false
            // },
            {
                name: "Top Attention Signals Score",
                value: "attentionsignalscore",
                isSelected: false,
            },
            {
                name: "Top Emotion Intensity",
                value: "expression",
                isSelected: false,
            },
            {
                name: "Top Visual Impact",
                value: "eyeimpact",
                isSelected: false,
            },
            // {
            //     name: "Video Attention",
            //     value: "eyeattention",
            //     isSelected: false
            // }
        ]);

        // Create a reactive object to store the setting data
        const dataButton = computed(() => {
            const button: IBasePropsList[] = [];
            // Button show data for vdo and package information
            if (route.name === "VdoInformationView" || route.name === "PackageInformationView") {
                Object.assign(button, [{
                    name: "Back to home",
                    icon: 'home',
                    path: "/"
                }])
            } else {
                Object.assign(button, [{
                    name: "Back",
                    icon: 'back',
                    path: `/vdo-information/${route.params.id}`
                }])
            }
            return button;
        })

        const handleExpand = (id: number) => {
            const findIndex = dataSelectItems.findIndex((o, index) => index === id);
            dataSelectItems.map((o: IFilters, i) => {
                if (i === findIndex && !o.isSelected)
                    o.isSelected = true
                else
                    o.isSelected = false
            });
            return dataSelectItems;
        }

        const handleSelect = (object: any) => {

            const dataSelectItems = store.getters["advertise/REQUEST_SELECT_OBJECTS"];
            // console.log({ showtime: object.showtime })
            // console.log({ eyecatch: object.eyecatch })
            // console.log({ name: object.name })

            const scenelistArray = object.scenelist;

            const sceneListData = store.getters['advertise/VDO_SCENE'];
            const sceneSortings = [...sceneListData];

            log({ sceneData: sceneData.value })

            sceneSortings.map(({ peakScenesObj }: any) => {
                Object.assign(peakScenesObj, {
                    playingVdoEyeImpact: false,
                    playingVdo: false,
                    playingTime: 0,
                    playingTimeEyeImpact: 0,
                });
                return peakScenesObj;
            });

            ShowRouteFilter.value = false;

            sceneData.value = [];

            if (object.isSelected) {

                //ติ๊กออก 
                object.isSelected = !object.isSelected;

                sceneSortings.forEach((scene: any) => {
                    scene.isShow = true;
                    scene.ObjectData = null;
                });

            } else {

                const CoordinateObject = <any[]>[];
                //ติ๊กเลือก
                dataSelectItems.forEach((objectlist: any) => {
                    objectlist.isSelected = false;
                });

                object.isSelected = true;

                //กำหนดค่ากรอบ
                object.objects.forEach((element: any) => {
                    element.coordinates.forEach((data: any) => {
                        const coordinatevalue = data.value;

                        const Timedata = CoordinateObject.find((point: any) => point.timeIndex == data.timeIndex)

                        if (Timedata) {
                            Timedata.value.push(coordinatevalue)
                        } else {
                            const TimedataNew = {
                                timeIndex: data.timeIndex,
                                value: <any[]>[]
                            }
                            TimedataNew.value.push(coordinatevalue)
                            CoordinateObject.push(TimedataNew)
                        }

                    });
                });

                object.objects.forEach((element: any) => {
                    const coordinates = element.coordinates
                });

                sceneSortings.forEach((scene: any) => {

                    if (scenelistArray.includes(scene.index)) {
                        scene.isShow = true

                        //set Object Data 
                        const ObjectCoData = <any>[];

                        for (let SceneStartTime = scene.StartTime; SceneStartTime <= scene.EndTime; SceneStartTime++) {

                            const CoData = CoordinateObject.find((point: any) => point.timeIndex == SceneStartTime)
                            if (CoData) {
                                ObjectCoData.push(CoData.value)
                            } else {
                                ObjectCoData.push([])
                            }

                        }
                        scene.ObjectData = ObjectCoData;

                    } else {
                        scene.isShow = false;
                        scene.ObjectData = null;
                    }
                });
            }

            store.commit('advertise/VDO_SCENE', sceneSortings);
        }

        const handleClear = () => {
            dataSelectItems.map((o: IFilters) => {
                o.isSelected = false
                o.totalSelected = null
                o.objects.map((o: IFilters) => {
                    o.isSelected = false
                })
            });
            return dataSelectItems;
        }

        const handleExpandAdsList = (data?: any) => {
            const { adid, mode } = data || {};
            if (adid) {
                // TODO: handle change mode redirect url
                let patch = `${window.location.origin}/vdo-information`;
                if (mode.toLowerCase()?.indexOf('packaging') != -1) {
                    patch = `${window.location.origin}/package-information`;
                }
                window.location.replace(`${patch}/${adid}`);
            } else {
                // TODO: handle expand
                isExpand.value = !isExpand.value;
            }
        }

        const handleFilters = async (payload: object) => {
            await store.dispatch('advertise/REQUEST_ADS_STAT', payload);
        }

        const handleSortby = async ({ name }: any) => {

            store.commit("APP_LOADING", true);

            const sceneListData = await store.getters['advertise/VDO_SCENE'];
            const sceneSortings = [...sceneListData];

            sceneData.value = [];

            if (name) {

                const nameSort = name.toLowerCase();

                if (nameSort === "emotion intensity") {
                    sceneData.value = sceneSortings.sort((a, b) => (parseFloat(b.expression) - parseFloat(a.expression)));
                } else if (nameSort === "visual impact") {
                    sceneData.value = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)));
                } else if (nameSort === "video attention") {
                    sceneData.value = sceneSortings.sort((a, b) => (parseFloat(b.eyeattention) - parseFloat(a.eyeattention)));
                }

                log({ nameSort, sceneSortings, sceneData: sceneData.value });

                sceneData.value.map(({ peakScenesObj }: any) => {
                    Object.assign(peakScenesObj, {
                        playingVdoEyeImpact: false,
                        playingVdo: false,
                        playingTime: 0,
                        playingTimeEyeImpact: 0,
                    });
                    return peakScenesObj;
                });

                store.commit('advertise/VDO_SCENE', sceneData.value);
                store.commit("APP_LOADING", false);

            }
        }

        watch(dataSelectItems, (itemsNew) => {
            // log({ itemsNew })

            const routefilter = route.params.filter;
            if (routefilter && ShowRouteFilter.value == true) {
                const SelectFilter = itemsNew.find((item: any) => item.name == routefilter)
                if (SelectFilter) {
                    handleSelect(SelectFilter)
                }
            }

            let totalSelect: any = [];
            itemsNew.filter((item) => {
                if (item.totalSelected !== null) {
                    totalSelect = [...totalSelect, item.totalSelected.length];
                }
                totalSelected.value = totalSelect.reduce((total: number, value: number) => total + value, 0);
                return totalSelected.value
            })
        });

        watch(() => store.getters['advertise/REQUEST_ADS_PARTICIPANTS'], (viewers: any) => {
            isParticipantsLoad.value = viewers.length > 0 ? false : true;
            if (viewers)
                Object.assign(dataParticipants, viewers);

        });

        watch(() => store.getters['advertise/REQUEST_SELECT_OBJECTS'], (objects: any) => {
            if (objects)
                Object.assign(dataSelectItems, objects);
        });

        watch(dataSortBy, (newValue) => {

            const nameSort = newValue.find(item => item.isSelected);
            // console.log(nameSort);

            if (nameSort) {
                const name = nameSort.value

                const sceneListData = store.getters['advertise/VDO_SCENE'];
                const sceneSortings = [...sceneListData];
                sceneData.value = [];

                if (name === "expression") {
                    sceneData.value = sceneSortings.sort((a, b) => (parseFloat(b.expression) - parseFloat(a.expression)))
                } else if (name === "eyeimpact") {
                    sceneData.value = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)))
                } else if (name === "attentionsignalscore") {
                    sceneData.value = sceneSortings.sort(
                        (a, b) =>
                            parseFloat(b.attentionsignalscore) -
                            parseFloat(a.attentionsignalscore)
                    );
                }

                log({ dataSortBy: name, sceneSortings, sceneData: sceneData.value })

                sceneData.value.map(({ peakScenesObj }: any) => {
                    Object.assign(peakScenesObj, {
                        playingVdoEyeImpact: false,
                        playingVdo: false,
                        playingTime: 0,
                        playingTimeEyeImpact: 0,
                    });
                    return peakScenesObj;
                });

                store.commit('advertise/VDO_SCENE', sceneData.value);
                store.commit('advertise/VDO_SCENE_TYPE', name);

            }

        }, { deep: true })

        // const fnClick = () => {

        //     window.onload = function () {

        //         document.onclick = function (e: any) {
        //             const container: NodeListOf<Element> = document.querySelectorAll('.objects-content');
        //             if (e.target.className !== 'name' && e.target.className !== 'ant-checkbox-input') {
        //                 container.forEach(function (Item: any) {
        //                     Item.style.opacity = '0';
        //                 });

        //                 // clear all selected
        //                 const objects = store.getters['advertise/REQUEST_SELECT_OBJECTS'];
        //                 if (objects)
        //                     Object.assign(dataSelectItems, objects);

        //                 dataSelectItems.map((o: IFilters) => {
        //                     o.isSelected = false
        //                 });
        //             } else {
        //                 if (e.target.className !== 'objects-content') {
        //                     container.forEach(function (Item: any) {
        //                         Item.style.removeProperty('opacity')
        //                         // log(e.target.className)
        //                     });
        //                 }
        //             }
        //             // log(e.target.className)
        //             // log(container)
        //         };
        //     };
        // }

        onMounted(async () => {

            // viewer redirect 
            // if (!roleUser.value) {
            //     router.push('/');
            //     return;
            // }

            const payload = { adid: route.params.id };
            const getInfo = await store.dispatch('advertise/REQUEST_ADS_DETAIL', payload);
            const AdsInfo = await store.getters['advertise/REQUEST_ADS_DETAIL'];

            const payloadAds = { brandid: AdsInfo?.brandid, projectid: AdsInfo?.projectid };
            const adsStat = await store.dispatch("advertise/REQUEST_ADVERTISE_DATA", payloadAds);

            if (adsStat) {
                const viewers = await store.getters['advertise/REQUEST_ADS_PARTICIPANTS'];
                isParticipantsLoad.value = viewers.length > 0 ? false : true;
                if (viewers)
                    Object.assign(dataParticipants, viewers);

                const objects = await store.getters['advertise/REQUEST_SELECT_OBJECTS'];
                if (objects)
                    Object.assign(dataSelectItems, objects);
            }

            const Sorttype = route.params.type;
            if (Sorttype) {

                const SortSelected = dataSortBy.find(
                    (sort) => sort.value === Sorttype
                );

                if (SortSelected) {
                    SortSelected.isSelected = true;
                }

            }

            // document.body.addEventListener('click', fn, true);
            // fnClick();

            if (getInfo == false) {
                router.push('/');
                return;
            }

        });

        onUpdated(async () => {
            ShowRouteFilter.value = true;
            const viewers = await store.getters['advertise/REQUEST_ADS_PARTICIPANTS'];
            isParticipantsLoad.value = viewers.length > 0 ? false : true;
            if (viewers)
                Object.assign(dataParticipants, viewers);

            const objects = await store.getters['advertise/REQUEST_SELECT_OBJECTS'];
            if (objects)
                Object.assign(dataSelectItems, objects);

            const Sorttype = route.params.type;
            if (Sorttype) {

                dataSortBy.forEach(element => {
                    element.isSelected = false
                });

                const SortSelected = dataSortBy.find(
                    (sort) => sort.value === Sorttype
                );
                if (SortSelected) {
                    SortSelected.isSelected = true;
                }
            }

            const routefilter = route.params.filter;
            if (routefilter && ShowRouteFilter.value == true) {
                const SelectFilter = dataSelectItems.find((item: any) => item.name == routefilter)
                if (SelectFilter) {
                    handleSelect(SelectFilter)
                }
            }
        });



        return {
            isExpand,
            dataLogo,
            dataButton,
            dataPlayer,
            dataSortBy,
            handleClear,
            handleSortby,
            handleExpand,
            handleSelect,
            totalSelected,
            handleFilters,
            dataSelectItems,
            dataParticipants,
            isParticipantsLoad,
            handleExpandAdsList,
        };
    },
});