import { defineComponent, computed } from "vue";
import { ElementCropImageData } from "@/interfaces/component.interface";

export default defineComponent({
    name: "BaseImage",
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {
                const image: ElementCropImageData = {
                    alt: "package image",
                    src: require("@/assets/images/package/image-est-bottle-01.png"),
                    width: 60,
                    height: 60,
                };
                return image;
            },
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
    },
    setup(prop) {

        const setImage = (conf: any) => {

            /*---------------
            * Setup
            * --------------*/
            const container_size_w = conf.container_size_w; //px
            const container_size_h = conf.container_size_h; //px
            const padding = conf.padding; //px
            const container_color = conf.container_color;
            let video_width = conf.video_width;
            let video_height = conf.video_height;
            const is_thumbnail = conf.is_thumbnail; //thumbnail is fix_width 300px

            const image_crop_w = conf.image_crop_w;
            const image_crop_h = conf.image_crop_h;
            const image_crop_left = conf.image_crop_left;
            const image_crop_top = conf.image_crop_top;

            if (is_thumbnail) {
                video_width = 300;
                video_height = video_height * (300 / conf.video_width);
            }

            //calculation
            const space_width = container_size_w - padding * 2;
            const space_height = container_size_h - padding * 2;

            //make a width to full size
            let crop_width = (image_crop_w / 100);
            let crop_height = (image_crop_h / 100) * (video_height / video_width);
            const crop_scale_up = Math.min(space_width / crop_width, space_height / crop_height)
            //make sure crop does not exceed space size
            crop_width *= crop_scale_up;
            crop_height *= crop_scale_up;

            const video_scale = crop_width / (video_width * (image_crop_w / 100));
            //if cropped video size is lesser than crop_width it should scale up;

            //result
            const scaled_video_width = video_width * video_scale;
            const scaled_video_height = video_height * video_scale;
            const scaled_video_translation_left = -video_width * (image_crop_left / 100) * video_scale;
            const scaled_video_translation_top = -video_height * (image_crop_top / 100) * video_scale;
            //------------------------------------------

            return {
                container: `width: ${container_size_w}px; height: ${container_size_h}px; background-color: ${container_color}`,
                cropimg: `width: ${crop_width}px; height: ${crop_height}px; margin: -${crop_height * 0.5}px auto`,
                croped: `width: ${scaled_video_width}px; height: ${scaled_video_height}px; top: ${scaled_video_translation_top}px; left: ${scaled_video_translation_left}px`
            }

        }

        const imageLoaded = computed(() => {
            const { padding, width, height, crop, is_thumbnail, container_color } = prop.data;
            // console.log(prop.data)
            const config = {
                container_size_w: width, //px
                container_size_h: height, //px
                padding: padding, //px
                container_color: container_color || "#ffffff",
                video_width: prop.width,
                video_height: prop.height,
                is_thumbnail: is_thumbnail || false, //thumbnail is fix_width 300px
                image_crop_w: crop.width,
                image_crop_h: crop.height,
                image_crop_left: crop.left,
                image_crop_top: crop.top
            }
            return setImage(config)
        })

        return {
            setImage,
            imageLoaded
        };
    },
});
