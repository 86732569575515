/* eslint-disable @typescript-eslint/no-explicit-any */
import { createVNode, defineComponent, h, ref, computed, watch, onMounted } from "vue";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { getVideoID, imgPreview, getTesterLink } from "@/helpers";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { key } from "@/store";
import { saveAs } from "file-saver";
import _ from 'lodash'

import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

// Helpers
import { log } from '@/helpers';

export default defineComponent({
    name: "AdsDetailsForm",
    components: {
        Modal,
        PlusOutlined
    },
    emits: ["result:update", "action:copylink", "action:delete-viewer"],
    setup(prop, { emit }) {
        const store = useStore(key);
        const route = useRoute();
        const inputRef = ref();
        const projectList = ref([]);
        const countAccountText = ref(0);
        const countProjectText = ref(0);
        const isUpdate = ref<boolean>(false);
        const isVisible = ref<boolean>(false);
        const adsIdCreated = ref<string>("");
        const isValidSubmit = ref<boolean>(true);
        const isValidUpload = ref<boolean>(true);
        const isVisibleWarning = ref<boolean>(false);
        const isValidSubmitTester = ref<boolean>(true);
        const isTabChaged = ref<boolean>(false);
        const callbackSubmit = ref<any>({});
        const callbackSubmitTester = ref<any>({});
        const currentPage = ref(1);
        const currentPageSize = ref(10);
        const totalItems = ref(0);
        const paginationData = ref([
            {
                value: 10,
                label: 10,
            },
            {
                value: 20,
                label: 20,
            },
            {
                value: 30,
                label: 30,
            },
            {
                value: 40,
                label: 40,
            },
            {
                value: 50,
                label: 50,
            },
            {
                value: 100,
                label: 100,
            }
        ]);
        const updateDataById = ref<any>(null);
        const updateTesterDataByid = ref<any>([]);

        const fileInfo = computed(() => store.getters["brands/UPLOAD_FILE"]);
        const isVdoLink = computed(() => store.getters["brands/GET_VDO_LINK"]);
        const isPreload = computed(() => store.getters["brands/PRELOAD_FILE"]);
        const previewVdo = computed(() => store.getters["brands/GET_VDO_PREVIEW"]);
        const fileUploaded = computed(() => store.getters["brands/UPLOADED_FILE"]);
        const isUploadFile = computed(() => store.getters["brands/GET_UPLOADFILE"]);
        const brandData = computed(() => store.getters["advertise/REQUEST_BRANDS"]);

        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '24px',
            },
            spin: true,
        });

        const hidePreload = () => {
            setTimeout(() => {
                // upload preload
                store.commit("brands/PRELOAD_FILE", false);
            }, 3000);
        };

        const handleModal = (val: boolean) => {
            isVisible.value = val;
            isUpdate.value = val;
            adsIdCreated.value = "";
        };

        const handleSubmitAds = async (): Promise<void> => {

            const format = fileInfo.value.includes('youtube') ? 'youtube' : 'mp4';
            const { projectName, accountName, vdoName } = callbackSubmit.value;

            // create project
            const payload = {
                title: vdoName,
                format: format,
                url: fileInfo.value,
                brandId: accountName,
                projectId: projectName
            };

            const result = await store.dispatch("brands/CREATE_VIDEO", payload);

            if (result) {

                // create queue task
                let taskResult;
                const { queueUrl, uploadUrl, task } = result;
                const payloadQueue = {
                    url: queueUrl,
                    task,
                };

                // get ads id created
                adsIdCreated.value = task.adId;

                // popup uploading
                isVisible.value = true;

                // upload file
                if (uploadUrl) {

                    const payloadUpload = {
                        uploadUrl,
                        fileUploaded: fileUploaded.value.upload
                    }

                    await store.dispatch("brands/UPLOAD_MEDIA", payloadUpload);

                    taskResult = await store.dispatch("brands/CREATE_TASK", payloadQueue);

                } else { // youtube and mp4
                    taskResult = await store.dispatch("brands/CREATE_TASK", payloadQueue);
                }

                log('taskResult', taskResult);

                if (taskResult?.status) {
                    // isVisibleWarning.value = true;
                    // show modal progressing
                    isUpdate.value = true;
                    isValidUpload.value = true;
                    isValidSubmit.value = true;
                    store.commit("brands/UPLOAD_FILE", "");
                }

            }
        };

        const handleUpdateAds = async (): Promise<void> => {

            const { projectName, accountName, vdoName } = callbackSubmit.value;

            const payload = {
                id: route.params.id,
                update: {
                    title: vdoName,
                    brandId: accountName,
                    projectId: projectName
                }
            };

            const result = await store.dispatch("brands/UPDATE_VIDEO", payload);

            if (result) {
                emit("result:update", result);
            }
        };

        const handleSubmitViewers = async () => {
            // store.commit("APP_LOADING", true);

            const payload = {
                adId: adsIdCreated.value,
                viewers: callbackSubmitTester.value,
            };

            await store.dispatch("brands/CREATE_VIEWER", payload);
            // log('result', result);

            // show modal progressing
            isUpdate.value = true;
            isVisible.value = true;
            adsIdCreated.value = "";
        }

        const handleUpdateViewers = async (): Promise<void> => {
            log('update tester', callbackSubmitTester.value)

            const payload = {
                adId: route.params.id,
                viewers: callbackSubmitTester.value
            };

            const result = await store.dispatch("brands/UPDATE_TESTER_DATA", payload);

            const { status, viewers } = result;

            if (status && viewers) {
                // update done
                updateTesterDataByid.value = viewers
            }

            emit("result:update", result);
        };

        const handleModalTester = () => {
            isVisibleWarning.value = !isVisibleWarning.value;
        };

        const handleModalWarning = (v: boolean) => {
            isVisibleWarning.value = v;
        };

        const handleCheckSubmit = (v: any) => {
            isValidSubmit.value = v.invalid;
            callbackSubmit.value = v;
            // log('callbackSubmit', callbackSubmit.value);
        };

        const handleCheckUpload = (valid: boolean) => {
            isValidUpload.value = valid
        };

        const handleCheckSubmitTester = (v: any) => {
            isValidSubmitTester.value = v.invalid;

            callbackSubmitTester.value = v.tester.map((o: any) => {
                let payload = {};
                if (route.params.id) {
                    payload = {
                        viewerId: o.viewerId || null,
                        // name: `${o.firstName} ${o.lastName}`,
                        name: `${o.firstName}`,
                        company: o.companyName,
                        email: o.email,
                        age: parseInt(o.age),
                        gender: o.gender
                    };
                } else {
                    payload = {
                        name: `${o.firstName}`,
                        company: o.companyName,
                        email: o.email,
                        age: parseInt(o.age),
                        gender: o.gender
                    };
                }
                return payload;
            });
            totalItems.value = v.tester.length;
            // log('callbackSubmitTester', callbackSubmitTester.value);
        };

        const handlePageChange = (page: any) => {
            currentPage.value = page;
            // log('handlePageChange', page);
        };

        const handlePageSizeChange = (pageSize: any) => {
            currentPageSize.value = pageSize;
            // log('handlePageSizeChange', pageSize);
        };

        const handleTotalItems = (totalItems: any) => {

            // total item equal zero
            if (totalItems.length === 0) {
                currentPage.value = 1;
                currentPageSize.value = 10;
            }

            totalItems.value = totalItems.length;
        };

        const initBrands = async () => {
            await store.dispatch("advertise/REQUEST_BRAND_DATA");
        };

        const dataBrandList = computed(() => store.getters["advertise/REQUEST_BRAND_DATA_CHECK"]);

        const handleUpdate = async (id: any) => {

            // store.commit("APP_LOADING", true);

            let fileData: any = "";
            let previewImage: any = "";

            const check: any = _.filter(dataBrandList.value, { 'id': id });

            // check permission for edit form
            if (check == 0) {
                window.location.assign(`${window.location.origin}/`);
                return;
            }

            await store.dispatch("brands/GET_VDO_DATA", id);
            await store.dispatch("brands/GET_TESTER_DATA", id);

            store.commit('brands/PRELOAD_FILE', true);

            // get file data
            updateDataById.value = await store.getters["brands/GET_VDO_DATA"];
            // get tester data
            updateTesterDataByid.value = await store.getters["brands/GET_TESTER_DATA"];

            if (!updateDataById.value) return;
            if (!updateTesterDataByid.value) return;

            // get vdo thumbnail by file type
            const o: any = updateDataById.value;
            if (o.format == 'youtube') {
                const ytdl: any = getVideoID(o.url);
                // Youtube 
                fileData = o.url;
                previewImage = ytdl.status ? `https://img.youtube.com/vi/${ytdl.id}/0.jpg` : '';
            } else {
                // MP4
                fileData = o.url;
                const link = await store.dispatch('brands/UPDATE_VDO_LINK', { adId: id, format: o.format });
                const poster = await store.dispatch('brands/GET_VDO_POSTER', { url: link.videoUrl });
                // log("link: ", link.videoUrl)
                store.commit('brands/GET_DOWNLOAD_FILE', `${link.videoUrl}`);
                previewImage = imgPreview(poster);
            }

            // get vdo thumbnail by file type
            const payload = { upload: fileData, preview: previewImage, update: true };
            store.commit("brands/UPLOAD_FILE", payload);
            // store.commit("APP_LOADING", false);

        }

        const handleAddTester = (visible: boolean) => {
            isTabChaged.value = visible;

            // check length of tester
            // if (updateTesterDataByid.value) {
            //     const testers: any = updateTesterDataByid.value
            //     if (testers.length == 0 && visible) handleModalTester();
            // }
        };

        const handleCopyLinkViewer = (viewer: any) => {

            const text = getTesterLink(route.params.id, viewer.viewerId)
            const message = `Copy Link สำหรับส่งให้ Tester "${viewer.firstName}" เพื่อเข้ามาดูวีดีโอนี้เรียบร้อยแล้ว`;
            navigator.clipboard.writeText(text).then(function () {
                // log('copy:', [text]);
                emit("action:copylink", message);
            }, function (err) {
                log('Error: Could not copy text: ', err);
            });

        };

        const handleCopyLinkFile = () => {

            const text = getTesterLink(route.params.id)
            const message = `Copy Link หลักสำหรับส่งให้ Tester เพื่อมาลงทะเบียน และดูวีดีโอนี้เรียบร้อยแล้ว`;
            navigator.clipboard.writeText(text).then(function () {
                // log('copy:', [text]);
                emit("action:copylink", message);
            }, function (err) {
                log('Error: Could not copy text: ', err);
            });
        };

        const handleDownload = async () => {
            const fileSource = await store.getters['brands/GET_DOWNLOAD_FILE'];

            new Promise(resolve => {
                saveAs(fileSource, fileInfo.value);
                setTimeout(() => resolve(store.commit("APP_LOADING", false)), 3000);
            });

        };

        // const handleAfterDeleteTester = async (viewer: any) => {
        //     setTimeout(async () => {
        //         const { adId, name } = viewer;
        //         await store.dispatch("brands/GET_TESTER_DATA", adId);
        //         // get tester data
        //         updateTesterDataByid.value = await store.getters["brands/GET_TESTER_DATA"];
        //         emit("action:delete-viewer", `ลบ Tester "${name}" เรียบร้อยแล้ว`);
        //         store.commit("APP_LOADING", false);
        //     }, 3000);
        // };

        const handleDeleteViewer = async (viewer: any) => {
            log('handleDeleteViewer', viewer);

            Modal.confirm({
                centered: true,
                wrapClassName: 'delete-modal',
                content: `ต้องการลบข้อมูล ใช่ หรือ ไม่?`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'ตกลง',
                async onOk() {
                    try {
                        return await new Promise((resolve, reject) => {

                            const { id, firstName } = viewer;
                            const viewers: any = store.dispatch("advertise/DELETE_VIEWER", { adid: route.params.id, id });

                            if (viewers) {
                                // handleAfterDeleteTester({ adId: route.params.id, name });
                                setTimeout(async () => {
                                    await store.dispatch("brands/GET_TESTER_DATA", route.params.id);
                                    // get tester data
                                    updateTesterDataByid.value = await store.getters["brands/GET_TESTER_DATA"];
                                    log({ update: updateTesterDataByid.value.length })
                                    emit("action:delete-viewer", `ลบ Tester "${firstName}" เรียบร้อยแล้ว`);
                                    // store.commit("APP_LOADING", false);
                                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                }, 4000);
                            }

                        });
                    } catch {
                        return log('Oops errors!');
                    }
                },
                cancelText: 'ยกเลิก',
                onCancel() {
                    Modal.destroyAll();
                },
            });


        };

        const handlePurgeTester = async (viewer: any) => {
            log('handleDeleteViewer', viewer);

            Modal.confirm({
                centered: true,
                wrapClassName: 'delete-modal',
                content: `ต้องการล้างข้อมูล ใช่ หรือ ไม่?`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'ตกลง',
                async onOk() {
                    try {
                        return await new Promise((resolve, reject) => {
                            const { id, name } = viewer;
                            const viewers: any = store.dispatch("advertise/PURGE_VIEWER", { adId: route.params.id, viewerId: id });

                            if (viewers) {
                                // handleAfterDeleteTester({ adId: route.params.id, name });
                                setTimeout(async () => {
                                    await store.dispatch("brands/GET_TESTER_DATA", route.params.id);
                                    // get tester data
                                    updateTesterDataByid.value = await store.getters["brands/GET_TESTER_DATA"];
                                    log({ update: updateTesterDataByid.value.length })
                                    emit("action:delete-viewer", `ระบบล้าง Tester "${name}" เรียบร้อยแล้ว โดยถาวร`);
                                    // store.commit("APP_LOADING", false);
                                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                }, 4000);
                            }

                        });
                    } catch {
                        return log('Oops errors!');
                    }
                },
                cancelText: 'ยกเลิก',
                onCancel() {
                    Modal.destroyAll();
                },
            });
        }

        watch(() => previewVdo.value, (preview) => {
            if (!preview) return;
            hidePreload();
        });

        onMounted(async () => {

            // brand data
            await initBrands();

            // default upload file
            store.commit("brands/UPLOAD_FILE", "");
            store.commit("brands/GET_VDO_PREVIEW", null);

            // update
            if (route.params.id) {
                const id = route.params.id;
                await handleUpdate(id);
            }

        });

        return {
            inputRef,
            fileInfo,
            isUpdate,
            isVisible,
            isVdoLink,
            indicator,
            isPreload,
            brandData,
            previewVdo,
            totalItems,
            handleModal,
            projectList,
            currentPage,
            isTabChaged,
            adsIdCreated,
            isUploadFile,
            handleUpdate,
            isValidSubmit,
            isValidUpload,
            handleDownload,
            updateDataById,
            paginationData,
            currentPageSize,
            handleSubmitAds,
            handleAddTester,
            handleUpdateAds,
            handleTotalItems,
            handlePageChange,
            countAccountText,
            countProjectText,
            isVisibleWarning,
            handleModalTester,
            handleCheckSubmit,
            handleCheckUpload,
            handleDeleteViewer,
            handleCopyLinkFile,
            handleModalWarning,
            handleSubmitViewers,
            handleUpdateViewers,
            isValidSubmitTester,
            handleCopyLinkViewer,
            handlePageSizeChange,
            updateTesterDataByid,
            handleCheckSubmitTester,
            handlePurgeTester
        };
    },
});
