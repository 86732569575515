import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_sidebar_detail = _resolveComponent("the-sidebar-detail")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "detail-layout" }, {
    default: _withCtx(() => [
      (_ctx.dataPlayer)
        ? (_openBlock(), _createBlock(_component_the_header, {
            key: 0,
            data: _ctx.dataPlayer,
            "data-expand-ads": _ctx.isExpand,
            "onAction:expandAdslist": _ctx.handleExpandAdsList
          }, null, 8, ["data", "data-expand-ads", "onAction:expandAdslist"]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          (_ctx.dataButton)
            ? (_openBlock(), _createBlock(_component_the_sidebar_detail, {
                key: 0,
                "data-logo": _ctx.dataLogo,
                "data-button": _ctx.dataButton,
                "data-sortby": _ctx.dataSortBy,
                "is-load": _ctx.isParticipantsLoad,
                "data-select-items": _ctx.dataSelectItems,
                "data-total-select": _ctx.totalSelected,
                "data-participants": _ctx.dataParticipants,
                "onAction:expand": _ctx.handleExpand,
                "onAction:clear": _ctx.handleClear,
                "onAction:sortby": _ctx.handleSortby,
                "onAction:SelectObject": _ctx.handleSelect,
                "onSet:participants": _ctx.handleFilters
              }, null, 8, ["data-logo", "data-button", "data-sortby", "is-load", "data-select-items", "data-total-select", "data-participants", "onAction:expand", "onAction:clear", "onAction:sortby", "onAction:SelectObject", "onSet:participants"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_layout_content, { class: "detail-layout-content" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component, route }) => [
                  _createVNode(_Transition, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock("div", {
                        key: route.path,
                        class: "content-wrapper"
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}