import { RouteRecordRaw } from "vue-router";

// Views
import { NotFoundView } from "@/views";

import { NotFoundLayout } from '@/views/Layout' //BlankLayout, 
// import { HomepageView } from '@/views'

// Dashboard route
import routeAuthen from '@/router/routeAuthen'
import routeDashboard from '@/router/routeDashboard'

const routes: Array<RouteRecordRaw> = [
    ...routeAuthen,
    ...routeDashboard,
    {
        path: "/:catchAll(.*)",
        component: NotFoundLayout,
        children: [
            {
                path: "",
                name: "NotFoundPage",
                component: NotFoundView,
            }
        ]
    },
];

export default routes;