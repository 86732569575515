import { defineComponent, reactive, ref, onUpdated, onMounted, inject } from "vue";

import { useStore } from 'vuex';
import { key } from "@/store";

// Interfaces
import { IBasePropsButton, IBasePropsList } from "@/interfaces/component.interface";
import router from "@/router";

export default defineComponent({
    name: "MainLayout",
    setup() {

        const store = useStore(key);
        const roleUser: any = inject('authUser');

        // Sidebar state
        const isSidebarExpand: any = ref<boolean>(false);
        const isVisible = ref<boolean>(false);

        // Logo
        const logoData = reactive({
            image_expand: require('@/assets/images/logo_xsight.png'),
            image_collapse: require('@/assets/images/logo_xsight_sidebar.png'),
            link: '/'
        });

        // Create a reactive object to store the button data
        const buttonData = reactive<IBasePropsButton>({
            name: 'Create Project',
            icon: require('@/assets/images/icons/icon-plus.png'),
            link: '/create-ads'
        });

        // Create a reactive object to store the list data
        const listData = reactive<Array<IBasePropsList>>([
            {
                name: 'Video Gallery',
                icon: 'vdo',
                path: '/',
                key: 'VdoGallery',
                isEnable: true
            },
            {
                name: 'Tester info',
                icon: 'tester',
                path: '/tester-info',
                key: 'TesterInfo',
                isEnable: true
            },
            {
                name: 'Survey',
                icon: 'survey',
                path: '/survey',
                key: 'Survey',
                isEnable: true
            },
            {
                name: 'User management',
                icon: 'user-manage',
                path: '/user-management',
                key: 'UserManagement',
                isEnable: true
            }
        ]);

        // Create a reactive object to store the setting data
        const settingData = reactive<Array<IBasePropsList>>([
            {
                name: 'Logout',
                icon: 'setting',
                path: '/logout',
                isEnable: true
            }
        ]);

        const handleSidebarExpand = () => {
            isSidebarExpand.value = !isSidebarExpand.value;
            localStorage.setItem('isSidebarExpand', isSidebarExpand.value)
        }

        const handleModal = () => {
            isVisible.value = !isVisible.value;
        }

        const handleCreateAds = () => {
            router.push('/create-ads');
        }

        onUpdated(() => {
            store.commit("advertise/REQUEST_ADS_PARTICIPANTS", []);
            const isModal = store.getters["brands/SHOW_MODAL"];
            if (isModal) {
                store.commit('brands/SHOW_MODAL', false);
                isVisible.value = true;
            }
        });

        onMounted(() => {

            // viewer redirect 
            if (!roleUser.value)
                router.push('/');

            store.commit("advertise/REQUEST_ADS_PARTICIPANTS", []);

            const isExpanded: any = localStorage.getItem('isSidebarExpand')
            isSidebarExpand.value = JSON.parse(isExpanded)

        });

        return {
            logoData,
            listData,
            isVisible,
            buttonData,
            settingData,
            handleModal,
            handleCreateAds,
            isSidebarExpand,
            handleSidebarExpand
        };
    },
});