import { defineComponent, toRef, ref } from "vue";

import { IFilters } from '@/interfaces/component.interface';

export default defineComponent({
    name: "BaseButtonSortBy",
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    emits: ['action:sortby'],
    setup(prop) {

        const propsButton = toRef(prop, 'data');
        const sortbySelected = ref<IFilters[]>([]);

        const handleSelectSortBy = (item: IFilters) => {

            propsButton.value.forEach((button: IFilters) => {
                button.isSelected = false
            });

            item.isSelected = true;

            // emit("action:sortby", item);

        }

        return {
            propsButton,
            sortbySelected,
            handleSelectSortBy
        }
    }
});
