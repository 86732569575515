/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    IAdsList,
    AdsTypes,
    IContext,
    IVDOFrame,
    IPayloads,
    iFilterHeader,
    IScenedata,
    IMutations,
    IScenedataResp,
    IAdsListPayloads,
    IAdsDetailPayloadTypes,
    EyePoint,
    EyePointFrame,
    iHeatmapdata,
} from "@/interfaces/advertise.interface";

import { graphic, number } from "echarts/core";
import { LineSeriesOption } from 'echarts/charts';
import { IBrandAndProjectList, AnyObjectTypes, IBrandAndProjectSeries } from "@/interfaces/brand.interface";

import AdvertiseService from "@/services/advertise.service";
import _ from "lodash";

// Helpers
import { log, formatNumber, capitalizeFirstLetter, SecondToMinute, isInteger, paginateData } from "@/helpers";
import router from "@/router";

import { getPSAScoreTest, getPSAScoreReal } from '@adapterdigital/xsight-packaging'

const AdsService = new AdvertiseService();

const comparer = (otherArray: any) => {
    return function (current: any) {
        return otherArray.filter(function (other: any) {
            return other.profileid == current.profileid
        }).length == 0;
    }
}

// Types for getters, mutations and actions
export const types = {
    VDO_FRAME: 'VDO_FRAME',
    VDO_SCENE: "VDO_SCENE",
    DELETE_ADS: "DELETE_ADS",
    VDO_EMOTION: 'VDO_EMOTION',
    VDO_HEATMAP: "VDO_HEATMAP",
    PURGE_VIEWER: "PURGE_VIEWER",
    ADS_SELECTED: "ADS_SELECTED",
    VDO_SCENE_ALL: "VDO_SCENE_ALL",
    DELETE_VIEWER: "DELETE_VIEWER",
    COMPARE_ITEMS: "COMPARE_ITEMS",
    SET_VDO_FILTER: "SET_VDO_FILTER",
    REQUEST_BRANDS: 'REQUEST_BRANDS',
    VDO_SCENE_TYPE: "VDO_SCENE_TYPE",
    REQUEST_ADS_STAT: "REQUEST_ADS_STAT",
    COMPARE_ITEMS_MOVE: "COMPARE_ITEMS_MOVE",
    REQUEST_BRAND_DATA: 'REQUEST_BRAND_DATA',
    REQUEST_ADS_DETAIL: 'REQUEST_ADS_DETAIL',
    REQUEST_PROJECT_LIST: 'REQUEST_PROJECT_LIST',
    REQUEST_ADVERTISE_DATA: 'REQUEST_ADVERTISE_DATA',
    REQUEST_SELECT_OBJECTS: "REQUEST_SELECT_OBJECTS",
    REQUEST_EXPRESSION_DATA: "REQUEST_EXPRESSION_DATA",
    REQUEST_ADS_PARTICIPANTS: "REQUEST_ADS_PARTICIPANTS",
    REQUEST_EYECATCH_OBJECTS: "REQUEST_EYECATCH_OBJECTS",
    REQUEST_BRAND_DATA_CHECK: 'REQUEST_BRAND_DATA_CHECK',
    REQUEST_PACKAGING_DATA: 'REQUEST_PACKAGING_DATA',
    REQUEST_DEFAULT_COLORS: 'REQUEST_DEFAULT_COLORS',
    REQUEST_DESIGN_COLORS: 'REQUEST_DESIGN_COLORS',
    REQUEST_COMPETITOR_COLORS: 'REQUEST_COMPETITOR_COLORS'
};

export default {
    namespaced: true,
    state: {
        order: 0,
        adsAll: [],
        brandList: [],
        brandData: [],
        AdsInfo: <AdsTypes>({
            adid: "",
            adtitle: "",
            thumbnail: "",
            video: "",
            url: "",
            format: "",
            resultstatus: "",
            projectid: "",
            projecttitle: "",
            brandid: "",
            brandtitle: "",
            created: "",
            modified: "",
            participant: 0,
            Filterviewer: 0,
            FilterExpression: 0,
            FilterEyeattention: 0,
            FilterEyeImpact: 0,
            FilterSignalScore: 0,
            FilterEmpty: 'DEFAULT',
            expression: 0,
            eyeimpact: 0,
            eyeattention: 0,
        }),
        data: [] as IAdsList[],
        FrameData: <IVDOFrame[]>[],
        SceneType: <string>"",
        SceneData: <IScenedata[]>[],
        SceneList: <IScenedata[]>[],
        EmotionData: <LineSeriesOption[]>[
            {
                name: "attentionsignalscore",
                data: [],
                color: "#12CFED",
                lineStyle: {
                    width: 1.5
                },
                type: 'line',
                showSymbol: false,
                smooth: true,
                areaStyle: {
                    opacity: 0.1,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: '#00CEFF'
                        },
                        {
                            offset: 1,
                            color: '#FFFFFF'
                        }
                    ])
                },
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxsignal",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-signalscore.png`)
                    }],
                }

            },
            {
                name: "angry",
                data: [],
                color: "#D63031",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxangry",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-angry.png`)
                    }],
                }
            },
            {
                name: "disgusted",
                data: [],
                color: "#00B894",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxdisgusted",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-disgusted.png`)
                    }],
                }
            },
            {
                name: "happy",
                data: [],
                color: "#FD79A8",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxhappy",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-happy.png`)
                    }],
                }
            },
            {
                name: "sad",
                data: [],
                color: "#2970FD",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxsad",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-sad.png`)
                    }],
                }
            },
            {
                name: "surprised",
                data: [],
                color: "#FDCB6E",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxsurprised",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-surprised.png`)
                    }],
                }
            },
            {
                name: "fearful",
                data: [],
                color: "#4B99FF",
                type: 'line',
                lineStyle: {
                    width: 1.2
                },
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxfearful",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-fearful.png`)
                    }],
                }
            },
            {
                name: "signal",
                data: [],
                color: "#6C5CE7",
                lineStyle: {
                    color: '#6C5CE7',
                    type: [2, 3],
                    cap: 'round',
                    width: 1.2
                },
                type: 'line',
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxsignal",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-signal.png`)
                    }],
                }

            },
            {
                name: "eye impact",
                data: [],
                color: "#FAAD14",
                lineStyle: {},
                type: 'line',
                showSymbol: false,
                smooth: true,
                markPoint: {
                    label: {
                        show: false
                    },
                    data: [{
                        name: "maxsignal",
                        type: 'max',
                        symbolSize: 16,
                        symbol: "image://" + require(`@/assets/images/dashboard/emo-visualimpact.png`)
                    }],
                }

            },
        ],
        adsSelected: <AnyObjectTypes[]>[],
        HEATMAPData: <EyePoint | null>null,
        dataParicipants: <AnyObjectTypes[]>[],
        dataSelectObjects: <AnyObjectTypes[]>[],
        compareItems: <IBrandAndProjectList[]>[],
        dataExpression: <IBrandAndProjectSeries>{
            expressionangry: 0,
            expressiondisgusted: 0,
            expressionfearful: 0,
            expressionhappy: 0,
            expressionsad: 0,
            expressionsurprised: 0,
            expressionneutral: 0,
        },
        dataEyeCatchObjects: <AnyObjectTypes[]>[],
        dataPackaging: {},
        defaultColors: ["#6D5DE8", "#B5BCBF", "#56AAF7", "#FCCA6D", "#FC6D9C", "#2DD6AF", "#98B3D7"],
        designColors: ["#FCCA6D", "#6D5DE8", "#2DD6AF", "#56AAF7", "#FC6D9C", "#B5BCBF", "#98B3D7"],
        competitorColors: ["#FC6D9C", "#6D5DE8", "#FCCA6D", "#2DD6AF", "#B5BCBF", "#98B3D7", "#56AAF7"]
    },
    getters: {
        [types.REQUEST_BRANDS]: (state: IMutations) => state.brandData,
        [types.REQUEST_ADVERTISE_DATA]: (state: IMutations) => state.data,
        [types.REQUEST_BRAND_DATA]: (state: IMutations) => state.brandList,
        [types.REQUEST_BRAND_DATA_CHECK]: (state: IMutations) => state.adsAll,
        [types.REQUEST_ADS_DETAIL]: (state: IMutations) => state.AdsInfo,
        [types.VDO_FRAME]: (state: IMutations) => state.FrameData,
        [types.VDO_EMOTION]: (state: IMutations) => state.EmotionData,
        [types.VDO_HEATMAP]: (state: IMutations) => state.HEATMAPData,
        [types.VDO_SCENE]: (state: IMutations) => state.SceneData,
        [types.VDO_SCENE_ALL]: (state: IMutations) => state.SceneList,
        [types.VDO_SCENE_TYPE]: (state: IMutations) => state.SceneType,
        [types.COMPARE_ITEMS]: (state: IMutations) => state.compareItems,
        [types.REQUEST_EXPRESSION_DATA]: (state: IMutations) => state.dataExpression,
        [types.REQUEST_ADS_PARTICIPANTS]: (state: IMutations) => state.dataParicipants,
        [types.REQUEST_EYECATCH_OBJECTS]: (state: IMutations) => state.dataEyeCatchObjects,
        [types.REQUEST_SELECT_OBJECTS]: (state: IMutations) => state.dataSelectObjects,
        [types.ADS_SELECTED]: (state: IMutations) => state.adsSelected,
        [types.REQUEST_PACKAGING_DATA]: (state: IMutations) => state.dataPackaging,
        [types.REQUEST_DEFAULT_COLORS]: (state: IMutations) => state.defaultColors,
        [types.REQUEST_DESIGN_COLORS]: (state: IMutations) => state.designColors,
        [types.REQUEST_COMPETITOR_COLORS]: (state: IMutations) => state.competitorColors,
    },
    mutations: {
        [types.REQUEST_BRAND_DATA](state: IMutations, payload: IPayloads): void {
            state.brandList = payload
        },
        [types.REQUEST_BRAND_DATA_CHECK](state: IMutations, payload: IPayloads): void {
            state.adsAll = payload;
        },
        [types.REQUEST_ADVERTISE_DATA](state: IMutations, payload: IPayloads): void {
            state.data = payload
        },
        [types.REQUEST_ADS_DETAIL](state: IMutations, payload: AdsTypes): void {
            state.AdsInfo = payload
        },
        [types.VDO_FRAME](state: IMutations, payload: IVDOFrame[]): void {
            state.FrameData = payload
        },
        [types.VDO_EMOTION](state: IMutations, payload: LineSeriesOption[]): void {
            const emotions = state.EmotionData;
            emotions?.map(emo => {

                const emoCheckname = emo.name?.toString().toLowerCase();
                const EmoData: any = payload.find((emodata: any) => emodata.name?.toString().toLowerCase() == emoCheckname);
                if (typeof EmoData !== "undefined") {
                    if (EmoData?.data) {
                        if (emoCheckname == "attentionsignalscore") {
                            emo.data = EmoData?.data.map(
                                (emo: number) => emo / 100
                            );
                        } else {
                            emo.data = EmoData?.data;
                        }
                        emo.data?.unshift(0);
                    } else {
                        emo.data = []
                    }
                } else {
                    if (emoCheckname !== "eye impact") emo.data = []
                }

                return emo;
            });
            state.EmotionData = emotions
        },
        [types.VDO_HEATMAP](state: IMutations, payload: EyePoint): void {
            const emotions = state.EmotionData;
            // const EyeAttentionArray = payload.heatmap.map(data => data.eyeAttention / 100);
            const EyeImpactArray = payload.heatmap.map(data => data.eyeImpact.percent / 100);

            // const serieEyeattention = emotions?.find((emodata: any) => emodata.name?.toString().toLowerCase() == "eye attention")
            // if (serieEyeattention) {

            //     serieEyeattention.data = EyeAttentionArray;
            //     serieEyeattention.data?.unshift(0)
            // }

            const serieEyeImpact: any = emotions?.find((emodata: any) => emodata.name?.toString().toLowerCase() == "eye impact")
            if (serieEyeImpact && EyeImpactArray.length > 0) {
                serieEyeImpact.data = EyeImpactArray;
                serieEyeImpact.data?.unshift(0)
            } else {
                serieEyeImpact.data = [];
            }

            state.HEATMAPData = payload
        },
        [types.VDO_SCENE](state: IMutations, payload: IScenedata): void {
            state.SceneData = payload
        },
        [types.VDO_SCENE_ALL](state: IMutations, payload: IScenedata): void {
            state.SceneList = payload
        },
        [types.VDO_SCENE_TYPE](state: IMutations, payload: string): void {
            state.SceneType = payload
        },
        [types.REQUEST_BRANDS](state: IMutations, payload: IPayloads): void {
            state.brandData = payload
        },
        [types.COMPARE_ITEMS](state: IMutations, payload: IPayloads): void {
            // find index by id campare
            const index = state.compareItems.findIndex((item: any) => item.id === payload.id);

            if (index !== -1) { // remove
                state.compareItems.splice(index, 1);
                state.adsSelected.splice(index, 1);
                state.order = state.order - 1;
                state.adsSelected.map((o: any, index: any) => o.order = index + 1);
            } else { // add
                if (state.compareItems.length > 5) return;
                state.order = state.order + 1;
                state.compareItems.push(payload);
                state.adsSelected.push({ id: payload.id, order: state.order });
            }

        },
        [types.COMPARE_ITEMS_MOVE](state: IMutations, payload: IVDOFrame[]): void {
            state.compareItems = payload
        },
        [types.REQUEST_EXPRESSION_DATA](state: IMutations, payload: IPayloads): void {
            // console.log(payload)
            // Object.assign(state.dataExpression, payload);
            state.dataExpression = payload
            // console.log({ "REQUEST_EXPRESSION_DATA": state.dataExpression })
        },
        [types.REQUEST_ADS_PARTICIPANTS](state: IMutations, payload: IPayloads): void {
            // Object.assign(state.dataParicipants, payload);
            state.dataParicipants = payload
        },
        [types.REQUEST_EYECATCH_OBJECTS](state: IMutations, payload: IPayloads): void {
            state.dataEyeCatchObjects = payload
        },
        [types.REQUEST_SELECT_OBJECTS](state: IMutations, payload: IPayloads): void {
            state.dataSelectObjects = payload
        },
        [types.SET_VDO_FILTER](state: IMutations, payload: iFilterHeader): void {
            if (state.AdsInfo) {
                state.AdsInfo.FilterUser = payload.FilterUser
                state.AdsInfo.Filterviewer = payload.Filterviewer
                state.AdsInfo.FilterExpression = payload.FilterExpression
                state.AdsInfo.FilterEyeattention = payload.FilterEyeattention
                state.AdsInfo.FilterSignalScore = payload.FilterSignalScore
                state.AdsInfo.FilterEyeImpact = payload.FilterEyeImpact
                state.AdsInfo.FilterEmpty = payload.FilterEmpty
            }
        },
        [types.REQUEST_PACKAGING_DATA](state: IMutations, payload: IPayloads): void {
            state.dataPackaging = payload
            // console.log({ "REQUEST_PACKAGING_DATA": state.dataPackaging })
        },
    },
    actions: {
        async [types.REQUEST_BRAND_DATA]({ commit, dispatch, rootState }: IContext): Promise<void> {

            // loading
            commit("APP_LOADING", true, { root: true });

            // api
            const response = await AdsService.getBrands();

            if (response && response?.data) {
                // destructuring data
                const { brands } = response?.data;
                const brandList: IBrandAndProjectList = [];
                const adAllBrand: any = [];

                if (!brands) return;

                let newBrands: any = [{
                    label: "เลือก Brand",
                    value: null,
                    disabled: true,
                }];

                // log({ brands })

                await brands.map((o: any) => {

                    let newProjects: any = [{
                        label: "เลือก Project",
                        value: null,
                        disabled: true,
                    }];

                    if (o.projects.length > 0) {
                        o.projects.map((p: any) => {
                            newProjects = [...newProjects, {
                                label: p.projecttitle,
                                value: p.projectid,
                                totalparticipant: p.totalparticipant
                            }];
                        });

                    }

                    newBrands = [...newBrands, {
                        label: o.brandtitle,
                        value: o.brandid,
                        projects: newProjects,
                        totalparticipant: o.totalparticipant,
                    }];

                    return newBrands;
                });

                // log({ newBrands })

                commit("REQUEST_BRANDS", newBrands);

                if (brands.length > 0) {

                    await brands.map(async (brand: IBrandAndProjectList) => {

                        const projects: IBrandAndProjectList = [];

                        if (brand.projects.length > 0) {

                            await brand.projects.map(async (project: IBrandAndProjectList) => {

                                const adsInProject: IBrandAndProjectList = [];

                                const payload = { brandid: brand.brandid, projectid: project.projectid };
                                const { ads, result } = await dispatch("REQUEST_ADVERTISE_DATA", payload);

                                // log('data:', [result])

                                if (ads) {

                                    // Ads data
                                    await ads.map(async (ads: IBrandAndProjectList) => {

                                        // packaging mockup id
                                        const mode = ads.displaymode
                                        // if (ads.adid === "645dc97d176440de9348b5d6") {
                                        //     mode = "Packaging Analysis"
                                        // }
                                        // end

                                        await adsInProject.push({
                                            adsId: ads.adid,
                                            adsName: ads.adtitle,
                                            adsCover: ads.thumbnail,
                                            adsTags: [],
                                            adsCompare: {
                                                qr: false,
                                                link: ads.url ? ads.url : false
                                            },
                                            adsStats: [
                                                {
                                                    id: 1,
                                                    name: "Participants",
                                                    value: ads.participant ? formatNumber(ads.participant) : 0
                                                },
                                                {
                                                    id: 2,
                                                    name: "Attention Signals Score",
                                                    value: isInteger(ads.attentionsignalscore.toFixed(1)) ? (ads.attentionsignalscore).toFixed(0) : (ads.attentionsignalscore).toFixed(1)
                                                },
                                                {
                                                    id: 3,
                                                    name: "Emotion Intensity",
                                                    value: isInteger(ads.expression.toFixed(1)) ? (ads.expression).toFixed(0) : (ads.expression).toFixed(1)
                                                },
                                                {
                                                    id: 4,
                                                    name: "Visual Impact",
                                                    value: isInteger(ads.eyeimpact.toFixed(1)) ? (ads.eyeimpact).toFixed(0) : (ads.eyeimpact).toFixed(1)
                                                },
                                                // {
                                                //     id: 4,
                                                //     name: "Video Attention",
                                                //     value: isInteger(ads.eyeattention.toFixed(1)) ? (ads.eyeattention).toFixed(0) : (ads.eyeattention).toFixed(1)
                                                // }
                                            ],
                                            adsMode: mode,
                                            adsJson: ads.jsonconfig,
                                            result: ads.resultstatus
                                        });

                                        // group all ads of project
                                        await adAllBrand.push({
                                            id: ads.adid,
                                        })

                                    });
                                }

                                // Project data
                                await projects.push({
                                    adsProjectId: project.projectid,
                                    adsProjectName: project.projecttitle,
                                    adsList: adsInProject
                                })

                            });
                        }

                        // Brand data
                        await brandList.push(
                            {
                                brandId: brand.brandid,
                                brandName: brand.brandtitle,
                                brandTotal: formatNumber(brand.totalparticipant),
                                brandLogo: "",
                                adsData: projects,
                                expand: false
                            }
                        )
                    })

                }

                if (brandList.length > 0) {
                    setTimeout(() => {
                        // commit to mutations
                        commit("REQUEST_BRAND_DATA", brandList);
                        commit("REQUEST_BRAND_DATA_CHECK", adAllBrand);
                        // hide loading
                        rootState.appLoading = false;
                    }, 2000);
                }
            }

        },
        async [types.REQUEST_ADS_DETAIL]({ commit }: IContext, payload: IAdsDetailPayloadTypes) {
            // commit("APP_LOADING", true, { root: true });

            const response = await AdsService.getVideosByID(payload);

            if (response && response?.data) {

                if (response.data.ads[0]) {
                    const adsData = response.data.ads[0]

                    // change display mode to packaging mode for testing purposes
                    // if (adsData.adid == "645dc97d176440de9348b5d6")
                    //     adsData.displaymode = "Packaging Analysis"

                    commit("REQUEST_ADS_DETAIL", response.data.ads[0]);
                    // commit("APP_LOADING", false, { root: true });

                    return true;
                } else {
                    return false
                }
            }
        },
        async [types.VDO_FRAME]({ commit }: IContext, payload: IAdsDetailPayloadTypes) {
            // commit("APP_LOADING", true, { root: true });
            const response = await AdsService.getVideoFrame(payload);
            if (response && response?.data) {
                if (response.data.frames[0]) {
                    commit("VDO_FRAME", response.data.frames);
                    // commit("APP_LOADING", false, { root: true });
                    return true;
                } else {
                    return false
                }
            }
        },
        async [types.VDO_SCENE]({ commit, state }: IContext, payload: IAdsDetailPayloadTypes) {

            const response = await AdsService.getAdsStat(payload);

            if (response && response?.data) {

                if (response.data.scenes[0]) {

                    const SceneList = response.data.scenes;

                    const SceneListComply = SceneList.map((scene: IScenedataResp) => {

                        const peakScenes = state.FrameData
                            .filter((frame: IVDOFrame) => parseInt(frame.time) >= scene.scenestart && parseInt(frame.time) <= scene.sceneend)
                            .map((frame: IVDOFrame, index: number) => {
                                return {
                                    index: index,
                                    time: frame.time,
                                    frameurl: frame.frameurl,
                                    scene: frame.framethumburl,
                                }
                            });

                        const prevScenes = state.FrameData
                            .filter((frame: IVDOFrame) => parseInt(frame.time) < scene.scenestart)
                            .map((frame: IVDOFrame) => {
                                return {
                                    time: frame.time,
                                    frameurl: frame.frameurl,
                                    scene: frame.framethumburl,
                                }
                            })?.splice(-4);

                        const nextScenes = state.FrameData
                            .filter((frame: IVDOFrame) => parseInt(frame.time) > scene.sceneend)
                            .map((frame: IVDOFrame) => {
                                return {
                                    time: frame.time,
                                    frameurl: frame.frameurl,
                                    scene: frame.framethumburl,
                                }
                            })?.splice(0, 4);

                        const peakSceneCut = peakScenes[0];
                        const arrPrev = prevScenes;
                        const arrNext = nextScenes;
                        const prevPeak = arrPrev.indexOf(peakSceneCut);
                        const nextPeak = arrNext.indexOf(peakSceneCut);
                        const totalMaxScene = 4;

                        arrPrev.slice(0, prevPeak);
                        arrNext.slice(0, nextPeak);

                        const prevData = arrNext.length < arrPrev.length ? arrPrev.slice(-4) : arrNext.length == arrPrev.length ? arrPrev.slice(-2) : [];
                        const nextData = arrPrev.length < arrNext.length ? arrNext.slice(0, 4) : arrPrev.length == arrNext.length ? arrNext.slice(0, 2) : [];
                        const totalSec = (prevData.length + peakScenes.length + nextData.length);

                        const peakScenesObj = {
                            prev: prevData,
                            peak: peakScenes,
                            next: nextData,
                            totalSec: totalSec,
                            playingVdoEyeImpact: false,
                            playingVdo: false,
                            playingTime: 0,
                            playingTimeEyeImpact: 0
                        };

                        const eyecatchs = state.FrameData;

                        const eyeCatchObjects: any = eyecatchs

                        const newScene = {
                            index: parseInt(scene.index),
                            StartTime: scene.scenestart,
                            EndTime: scene.sceneend,
                            attentionsignalscore: scene.attentionsignalscore.toFixed(2),
                            emotiondata: {
                                angry: (scene.expressionangry * 100).toFixed(2),
                                disgusted: (
                                    scene.expressiondisgusted * 100
                                ).toFixed(2),
                                happy: (scene.expressionhappy * 100).toFixed(2),
                                sad: (scene.expressionsad * 100).toFixed(2),
                                surprised: (
                                    scene.expressionsurprised * 100
                                ).toFixed(2),
                                fearful: (scene.expressionfearful * 100).toFixed(2),
                            },
                            expression: (scene.expression * 100).toFixed(2),
                            eyeimpact: scene.eyeImpact.percent.toFixed(2),
                            eyeattention: scene.eyeattention.toFixed(2),
                            speechtext: scene.transcript,
                            Photolist: state.FrameData.filter(
                                (frame: IVDOFrame) =>
                                    parseInt(frame.time) >= scene.scenestart &&
                                    parseInt(frame.time) <= scene.sceneend
                            ).map((frame: IVDOFrame) => {
                                return frame.frameurl;
                            }),
                            peakScenesObj,
                            eyeListData: state.HEATMAPData.heatmap
                                .filter(
                                    (frame: EyePointFrame) =>
                                        frame.time >= scene.scenestart &&
                                        frame.time <= scene.sceneend
                                )
                                .map((frame: EyePointFrame) => {
                                    return frame.eyeImpact;
                                }),
                            vdoWidth: state.HEATMAPData?.vdoWidth,
                            vdoHeight: state.HEATMAPData?.vdoHeight,
                            HeatMapData: state.HEATMAPData?.heatmap
                                .filter(
                                    (frame: EyePointFrame) =>
                                        frame.time >= scene.scenestart &&
                                        frame.time <= scene.sceneend
                                )
                                .map((frame: EyePointFrame) => {
                                    return frame.heatmapdata.map(
                                        ({ left, top, value }) => ({ left, top, value })
                                    );
                                }),
                            eyeCatchObjects: eyeCatchObjects
                        };

                        return newScene
                    })

                    commit("VDO_SCENE", SceneListComply);
                    return true;

                } else {
                    return false
                }
            }
        },
        async [types.VDO_SCENE_TYPE]({ commit, state }: IContext, payload: string) {
            commit("VDO_SCENE_TYPE", payload);
            return true;
        },
        async [types.REQUEST_ADVERTISE_DATA]({ commit, rootState, rootGetters }: IContext, payload: IAdsListPayloads): Promise<void> {
            // const idToken = rootGetters.GET_APP_TOKEN;
            // const isLoggedIn = rootGetters.VERIFY_APP_TOKEN;

            // payload
            // const dataPayload = Object.assign({}, payload, { token: idToken });

            // api
            const response = await AdsService.getVideos(payload);

            // loading
            // commit("APP_LOADING", true, { root: true });

            if (response && response?.data) {
                // destructuring data
                const { ads } = response?.data;
                const newAds = ads.filter((ad: any) => ad.participant > 0);

                // change display mode to packaging mode for testing purposes
                // newAds.map((ad: any) => {
                //     if (ad.adid == "645dc97d176440de9348b5d6")
                //         ad.displaymode = "Packaging Analysis"
                // })
                // end

                // console.log({ newAds })
                // hide loading
                // rootState.appLoading = false;

                // commit to mutations
                // commit("REQUEST_ADVERTISE_DATA", ads);
                commit("REQUEST_ADVERTISE_DATA", newAds);

                return response?.data;
            }

        },
        async [types.REQUEST_ADS_STAT]({ commit, state }: IContext, payload: IAdsDetailPayloadTypes) {

            // loading
            commit("APP_LOADING", true, { root: true });

            const { crtiteriatype } = payload;
            // console.log({ payload })
            // console.log({ payload: state.data })

            const response = await AdsService.getAdsStat(payload);
            const displaymode = state.data.filter((d: any) => d.adid == payload.adid)?.[0]?.displaymode || ''
            const jsonconfig: any = state.data.filter((d: any) => d.adid == payload.adid)?.[0]?.jsonconfig || '';

            // console.log({ displaymode: displaymode.toLowerCase() })
            // console.log({ jsonconfig })
            // console.log({ response })
            // console.log({ FrameData: state.FrameData })

            if (response && response?.data) {

                const {
                    scenes,
                    heatmap,
                    vdoHeight,
                    vdoWidth,
                    adsviewers,
                    PointData,
                    expression,
                    sceneseyecatchobjectstep3,
                    sceneseyecatchobjectstep4,
                    sqlProfileIDList
                } = response.data;

                // set line style
                let lineStyleJoin = {}
                const lineStyle = {
                    color: '#FAAD14',
                    width: 1.2
                }

                // Get Package Information
                // const packaging = getPSAScoreTest(response?.data, { frames: state.FrameData }, jsonconfig)
                if (displaymode.toLowerCase()?.indexOf('packaging') !== -1) {
                    const packaging = getPSAScoreReal(response?.data, { frames: state.FrameData }, jsonconfig)
                    commit("REQUEST_PACKAGING_DATA", packaging);
                } else {
                    // change color on chart for eye impact
                    lineStyleJoin = {
                        ...lineStyle,
                        type: [2, 3],
                        cap: 'round',
                    }
                }

                state.EmotionData?.map((e: any) => {
                    if (e.name === 'eye impact') {
                        e.lineStyle = lineStyleJoin
                        return e
                    }
                    return e
                })
                // end

                if (crtiteriatype) { // payload.crtiteriatype

                    let FilterHeader = {}

                    if (sqlProfileIDList === "'NOPROFILEID_FILTERED'") {
                        // console.log({ sqlProfileIDList })
                        FilterHeader = {
                            FilterUser: adsviewers,
                            Filterviewer: adsviewers.length,
                            FilterExpression: 0,
                            FilterEyeattention: 0,
                            FilterSignalScore: 0,
                            FilterEyeImpact: 0,
                            FilterEmpty: true
                        }
                    } else {
                        // console.log('normal');
                        FilterHeader = {
                            FilterUser: adsviewers,
                            Filterviewer: adsviewers.length,
                            FilterExpression: (expression.expression * 100),
                            FilterEyeattention: _.mean(heatmap.map((data: any) => data.eyeAttention)),
                            FilterEyeImpact: _.mean(heatmap.map((data: any) => data.eyeImpact.percent)),
                            FilterSignalScore: _.mean(PointData.filter((data: any) => data.name === 'AttentionSignalScore').map((point: any) => point.data)[0]),
                            FilterEmpty: false
                        }
                    }

                    commit("SET_VDO_FILTER", FilterHeader);
                    // console.log({ x: FilterHeader })

                } else {
                    const FilterHeader = {
                        FilterUser: adsviewers,
                        Filterviewer: 0,
                        FilterExpression: 0,
                        FilterEyeattention: 0,
                        FilterSignalScore: 0,
                        FilterEyeImpact: 0,
                        FilterEmpty: 'DEFAULT'
                    }
                    commit("SET_VDO_FILTER", FilterHeader);
                    // console.log({ y: FilterHeader })
                }

                commit("VDO_HEATMAP", { heatmap, vdoHeight, vdoWidth });
                commit("VDO_EMOTION", PointData);
                commit("REQUEST_EXPRESSION_DATA", expression);

                // Eye catch object and expression
                const eyeCatchObjects = await sceneseyecatchobjectstep3.map((scenes: any, index: number) => {

                    const {
                        showtime,
                        expression,
                        expressiondata,
                        eyeattention,
                        eyecatch,
                        eyeimpact,
                        objectname,
                        attentionsignalscore
                    } = scenes;

                    const {
                        expangry,
                        expdisgusted,
                        exphappy,
                        expsad,
                        expsurprised,
                        expfearful,
                    } = expressiondata;

                    const percentAngry: any = (expangry * 100);
                    const convertAngry: any = parseFloat(percentAngry);

                    const percentDisgusted: any = (expdisgusted * 100);
                    const convertDisgusted: any = parseFloat(percentDisgusted);

                    const percentHappy: any = (exphappy * 100);
                    const convertHappy: any = parseFloat(percentHappy);

                    const percentSad: any = (expsad * 100);
                    const convertSad: any = parseFloat(percentSad);

                    const percentSurprised: any = (expsurprised * 100);
                    const convertSurprised: any = parseFloat(percentSurprised);

                    const percentFearful: any = (expfearful * 100);
                    const convertFearful: any = parseFloat(percentFearful);

                    const percentExpression: any = (expression * 100);
                    const convertExpression: any = parseFloat(percentExpression);

                    const percentEyeImpact: any = eyeimpact;
                    const convertEyeImpact: any = parseFloat(percentEyeImpact);

                    const percentSignal: any = eyecatch;
                    const convertSignal: any = parseFloat(percentSignal);

                    const percentAttention: any = eyeattention;
                    const convertAttention: any = parseFloat(percentAttention);

                    const dataEyeCatchObjects = {
                        key: index + 1,
                        name: capitalizeFirstLetter(objectname),
                        showtime: showtime,
                        angry: convertAngry,
                        disgusted: convertDisgusted,
                        happy: convertHappy,
                        sad: convertSad,
                        surprised: convertSurprised,
                        fearful: convertFearful,
                        signal: convertSignal,
                        expression: convertExpression,
                        eye_impact: convertEyeImpact,
                        eyeattention: convertAttention,
                        attentionsignalscore: attentionsignalscore ? attentionsignalscore : 0,
                    };
                    return dataEyeCatchObjects;
                });

                commit("REQUEST_EYECATCH_OBJECTS", eyeCatchObjects);

                // Select objects
                const selectObjects = await sceneseyecatchobjectstep3.map((scenes: any, index: number) => {

                    const {
                        showtime,
                        eyecatch,
                        scenelist,
                        objectname,
                        objectgroupnamelist
                    } = scenes;

                    const objectChilds = objectgroupnamelist.map((objName: any, i: number) => {

                        const objects = {
                            id: i + 1,
                            name: capitalizeFirstLetter(objName),
                            value: objName,
                            isSelected: false,
                            coordinates: _.zipWith(
                                sceneseyecatchobjectstep4.filter((o: any) => o.objectgroupnamelist[0] === objName)[0].objectcoord.map((point: any) => {
                                    const Pointdata = {
                                        left: point.left,
                                        top: point.top,
                                        width: point.right - point.left,
                                        height: point.bottom - point.top
                                    }
                                    return Pointdata
                                }),
                                sceneseyecatchobjectstep4.filter((o: any) => o.objectgroupnamelist[0] === objName)[0].timeindex,
                                function (a: any, b: number) {
                                    const C = {
                                        timeIndex: b,
                                        value: a
                                    }
                                    return C
                                }
                            )

                        }

                        return objects;
                    });

                    const eyecatchPercent = isInteger(eyecatch.toFixed(1))
                        ? `${eyecatch.toFixed(0)}%`
                        : `${eyecatch.toFixed(1)}%`

                    const dataSelectObjects = {
                        id: index + 1,
                        name: capitalizeFirstLetter(objectname),
                        value: objectname,
                        isSelected: false,
                        totalSelected: null,
                        scenelist: scenelist,
                        objects: objectChilds,
                        showtime: SecondToMinute(showtime),
                        eyecatch: eyecatchPercent,
                    };

                    return dataSelectObjects;
                });

                commit("REQUEST_SELECT_OBJECTS", selectObjects);

                // Set participant data
                if (state.dataParicipants.length === 0) {

                    const participants = adsviewers.map((o: any, index: number) => {
                        return {
                            name: o.name,
                            company: o.company,
                            viewerid: o.viewerid,
                            profileid: o.profileid,
                            avatar: o.imageurl,
                            isSelected: true
                        };
                    });

                    commit("REQUEST_ADS_PARTICIPANTS", participants);
                }

                const SceneListComply = scenes.map((scene: IScenedataResp) => {

                    const peakScenes = state.FrameData
                        .filter((frame: any) => parseInt(frame.time) >= scene.scenestart && parseInt(frame.time) <= scene.sceneend)
                        .map((frame: IVDOFrame, index: number) => {
                            return {
                                index: index,
                                time: frame.time,
                                poster: frame.frameurl,
                                scene: frame.framethumburl,
                            }
                        });

                    const prevScenes = state.FrameData
                        .filter((frame: IVDOFrame) => parseInt(frame.time) < scene.scenestart)
                        .map((frame: IVDOFrame) => {
                            return {
                                time: frame.time,
                                poster: frame.frameurl,
                                scene: frame.framethumburl,
                            }
                        })?.splice(-4);

                    const nextScenes = state.FrameData
                        .filter((frame: IVDOFrame) => parseInt(frame.time) > scene.sceneend)
                        .map((frame: IVDOFrame) => {
                            return {
                                time: frame.time,
                                poster: frame.frameurl,
                                scene: frame.framethumburl,
                            }
                        })?.splice(0, 4);

                    const peakSceneCut = peakScenes[0];
                    const arrPrev = prevScenes;
                    const arrNext = nextScenes;
                    const prevPeak = arrPrev.indexOf(peakSceneCut);
                    const nextPeak = arrNext.indexOf(peakSceneCut);

                    arrPrev.slice(0, prevPeak);
                    arrNext.slice(0, nextPeak);

                    const prevData = arrNext.length < arrPrev.length ? arrPrev.slice(-4) : arrNext.length == arrPrev.length ? arrPrev.slice(-2) : [];
                    const nextData = arrPrev.length < arrNext.length ? arrNext.slice(0, 4) : arrPrev.length == arrNext.length ? arrNext.slice(0, 2) : [];
                    const totalSec = (prevData.length + peakScenes.length + nextData.length);

                    const peakScenesObj = {
                        prev: prevData,
                        peak: peakScenes,
                        next: nextData,
                        totalSec: totalSec,
                        playingVdoEyeImpact: false,
                        playingVdo: false,
                        playingTime: 0,
                        playingTimeEyeImpact: 0
                    };

                    // Eye Catch Object
                    const eyecatchs = sceneseyecatchobjectstep4.filter(
                        (object: any) => object.scenelist.includes(parseInt(scene.index))
                    ).map((o: any) => {
                        o.thumbnail = state.FrameData.filter(
                            (frame: IVDOFrame) =>
                                parseInt(frame.time) === parseInt(o.timeindex[0])
                        ).map((frame: IVDOFrame) => {
                            const { framethumburl } = frame
                            return framethumburl;
                        })?.[0]
                        return o
                    });

                    const eyeCatchObjects: any = eyecatchs
                    // Eye Catch Object

                    const newScene = {
                        index: parseInt(scene.index),
                        StartTime: scene.scenestart,
                        EndTime: scene.sceneend,
                        attentionsignalscore: scene.attentionsignalscore.toFixed(2),
                        emotiondata: {
                            angry: (scene.expressionangry * 100).toFixed(2),
                            disgusted: (scene.expressiondisgusted * 100).toFixed(2),
                            happy: (scene.expressionhappy * 100).toFixed(2),
                            sad: (scene.expressionsad * 100).toFixed(2),
                            surprised: (scene.expressionsurprised * 100).toFixed(2),
                            fearful: (scene.expressionfearful * 100).toFixed(2),
                        },
                        expression: (scene.maxexpressionsignal * 100).toFixed(2),
                        //   eyeimpact: scene.eyeImpact.percent.toFixed(2),
                        eyeimpact: (scene.maxeyeimpact).toFixed(2),
                        eyeattention: scene.eyeattention.toFixed(2),
                        speechtext: scene.transcript,
                        Photolist: state.FrameData.filter(
                            (frame: IVDOFrame) =>
                                parseInt(frame.time) >= scene.scenestart &&
                                parseInt(frame.time) <= scene.sceneend
                        ).map((frame: IVDOFrame) => {
                            const { frameurl } = frame;
                            return frameurl;
                        }),

                        peakScenesObj,
                        eyeListData: state.HEATMAPData.heatmap
                            .filter(
                                (frame: EyePointFrame) =>
                                    frame.time >= scene.scenestart &&
                                    frame.time <= scene.sceneend
                            )
                            .map((frame: EyePointFrame) => {
                                return frame.eyeImpact;
                            }),
                        isShow: true,
                        vdoWidth: state.HEATMAPData?.vdoWidth,
                        vdoHeight: state.HEATMAPData?.vdoHeight,
                        HeatMapData: state.HEATMAPData?.heatmap
                            .filter(
                                (frame: EyePointFrame) =>
                                    frame.time >= scene.scenestart &&
                                    frame.time <= scene.sceneend
                            )
                            .map((frame: EyePointFrame) => {
                                return frame.heatmapdata.map(({ left, top, value }) => ({ left, top, value }))

                            }),
                        eyeCatchObjects: eyeCatchObjects
                    };

                    return newScene;
                });

                // console.log({ SceneListComply })
                commit("VDO_SCENE", SceneListComply);

                // not filter store scene for default
                if (!crtiteriatype)
                    commit("VDO_SCENE_ALL", SceneListComply);

                // loading
                commit("APP_LOADING", false, { root: true });

                return true;
            }

            return false;
        },
        async [types.COMPARE_ITEMS]({ commit, state }: IContext, payload: IAdsDetailPayloadTypes) {

            commit("APP_LOADING", true, { root: true });

            const dataPayload: any = { adid: payload };
            const responseVdo = await AdsService.getVideoFrame(dataPayload);
            const responseAds = await AdsService.getVideosByID(dataPayload);
            const responseStat = await AdsService.getAdsStat(dataPayload);

            if (responseAds && responseAds?.data && responseStat && responseStat?.data && responseVdo && responseVdo?.data) {

                // get ads information by id
                const { ads } = responseAds.data;
                const { adid, adtitle, brandtitle, thumbnail, projecttitle } = ads[0];
                const {
                    scenes,
                    heatmap,
                    vdoHeight,
                    vdoWidth,
                    adsviewers,
                    PointData,
                    expression
                } = responseStat.data;

                const frames = responseVdo.data?.frames;

                const participants = adsviewers.map((o: any, index: number) => {
                    o.imageurl = o.imageurl ? o.imageurl : false;
                    return o;
                });

                // console.log({ participants })

                // participants
                const dataParticipants = paginateData(participants, 12);
                const dataViewers = {
                    current: 1,
                    data: dataParticipants,
                    total: adsviewers.length,
                    pageSize: dataParticipants.length,
                    avatar: require(`@/assets/images/dashboard/user-avatar.png`)
                };

                // expression
                const dataAngry = (expression.expressionangry * 100).toFixed(2);
                const dataDisgusted = (expression.expressiondisgusted * 100).toFixed(2);
                const dataFearful = (expression.expressionfearful * 100).toFixed(2);
                const dataHappy = (expression.expressionhappy * 100).toFixed(2);
                const dataSad = (expression.expressionsad * 100).toFixed(2);
                const dataSurprised = (expression.expressionsurprised * 100).toFixed(2);
                const dataNeutral = (expression.expressionneutral * 100).toFixed(2);

                const expressionPercent = (expression.expression * 100);
                const averageExpression = expression ? isInteger(expressionPercent.toFixed(1))
                    ? `${expressionPercent.toFixed(0)}%`
                    : `${expressionPercent.toFixed(1)}%`
                    : 'N/A'

                const dataExpression = {
                    tooltip: {
                        show: false,
                        trigger: 'item'
                    },
                    legend: {
                        show: false,
                        orient: "vertical",
                        left: "left",
                        data: [
                            'Angry',
                            'Disgusted',
                            'Happy',
                            'Sad',
                            'Surprised',
                            'Fearful'
                        ]
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['70%', '100%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 2
                            },
                            color: ["#D63031", "#00B894", "#FD79A8", "#2970FD", "#FDCB6E", "#7DA6F9", "#CCC"],
                            label: {
                                rotate: 0,
                                position: 'inside',
                                alignTo: 'none',
                                color: '#FFFFFF',
                                fontSize: 10,
                                fontFamily: 'IBM Plex Bold',
                                padding: 0,
                                align: 'center',
                                verticalAlign: 'middle',
                                formatter: '{d}%',
                            },
                            emphasis: {
                                scale: false
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                { value: dataAngry, name: 'Angry' },
                                { value: dataDisgusted, name: 'Disgusted' },
                                { value: dataHappy, name: 'Happy' },
                                { value: dataSad, name: 'Sad' },
                                { value: dataSurprised, name: 'Surprised' },
                                { value: dataFearful, name: 'Fearful' },
                                { value: dataNeutral, name: 'Signal' },
                            ]
                        }
                    ]
                };

                // signal
                const labelLineData = [
                    // 'Angry',
                    // 'Disgusted',
                    // 'Happy',
                    // 'Sad',
                    // 'Surprised',
                    'Signal',
                ];

                let average = 0;
                PointData.filter((o: any) => o.name.toLowerCase() == 'signal').map((o: any) => {
                    const signalAvg = _.mean(o.data);
                    average = (signalAvg * 100);
                });

                const averageSignal = average ? isInteger(average.toFixed(1))
                    ? `${average.toFixed(0)}%`
                    : `${average.toFixed(1)}%`
                    : 'N/A';

                const signal = PointData.filter((o: any) => o.name.toLowerCase() == 'signal');
                const dataSignal = {
                    color: ['#6C5CE7'],
                    grid: {
                        left: '2%',
                        right: '2%',
                        bottom: '2%',
                        top: '30%',
                        containLabel: false,
                        backgroundColor: 'transparent',
                    },
                    legend: {
                        show: false,
                        padding: 0
                    },
                    aria: {
                        enabled: false,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'line',
                            label: {
                                width: 10,
                                backgroundColor: '#6C5CE7'
                            },
                            lineStyle: {
                                color: '#00CEC9',
                                width: 3,
                                type: 'dotted',
                            }
                        },
                        borderWidth: 1,
                        borderColor: 'rgba(193, 17, 21, 0.3)',
                        backgroundColor: '#2D3436',
                        borderRadius: 14,
                        padding: [5, 10],
                        textStyle: {
                            fontFamily: 'IBM Plex Light',
                            fontSize: 10,
                            color: '#FFFFFF'

                        },
                        formatter: (params: any) => {
                            if (params[0].color) {

                                //     const TxtTooltip = `
                                // <span style="color:${params[0].color};">Signal</span> :  ${params[0].data.toFixed(2)}`;

                                const TxtTooltip = `
                            <span style="color:var(--color-white); line-height: var(--font-sm);">Emotion<br/> Intensity</span>: ${(params[0].data * 100).toFixed(2)} %`;

                                return TxtTooltip;
                            } else {
                                return "";
                            }
                        },
                    },
                    xAxis: {
                        show: false,
                        type: 'category',
                        boundaryGap: false,
                        data: signal[0].data,
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                    },
                    series: [
                        {
                            data: signal[0].data,
                            type: 'line',
                            smooth: true,
                            lineStyle: {
                                width: 2
                            },
                            zlevel: 10,
                            z: 10,
                            showSymbol: false,
                            symbol: 'circle',
                            symbolSize: 15,
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: '#353F6C'
                            },
                            areaStyle: {
                                opacity: 0.1,
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: '#6C5CE7'
                                    },
                                    {
                                        offset: 1,
                                        color: '#6C5CE7'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            }
                        }
                    ]
                };

                // scenes
                const sceneList = scenes.map((scene: IScenedataResp) => {

                    const newScene = {
                        index: parseInt(scene.index),
                        StartTime: scene.scenestart,
                        EndTime: scene.sceneend,
                        attentionsignalscore: scene.attentionsignalscore.toFixed(2),
                        emotiondata: {
                            angry: (scene.expressionangry * 100).toFixed(2),
                            disgusted: (scene.expressiondisgusted * 100).toFixed(2),
                            happy: (scene.expressionhappy * 100).toFixed(2),
                            sad: (scene.expressionsad * 100).toFixed(2),
                            surprised: (scene.expressionsurprised * 100).toFixed(2),
                            fearful: (scene.expressionfearful * 100).toFixed(2),
                        },
                        expression: (scene.expression * 100).toFixed(2),
                        eyeimpact: scene.eyeImpact.percent.toFixed(2),
                        eyeattention: scene.eyeattention.toFixed(2),
                        speechtext: scene.transcript,
                        Photolist: frames
                            .filter(
                                (frame: IVDOFrame) =>
                                    parseInt(frame.time) >= scene.scenestart &&
                                    parseInt(frame.time) <= scene.sceneend
                            )
                            .map((frame: IVDOFrame) => {
                                return frame.framethumburl;
                            }),
                        eyeListData: heatmap
                            .filter(
                                (frame: EyePointFrame) =>
                                    frame.time >= scene.scenestart &&
                                    frame.time <= scene.sceneend
                            )
                            .map((frame: EyePointFrame) => {
                                return frame.eyeImpact;
                            }),
                        vdoWidth: vdoWidth,
                        vdoHeight: vdoHeight,
                        HeatMapData: heatmap
                            .filter(
                                (frame: EyePointFrame) =>
                                    frame.time >= scene.scenestart &&
                                    frame.time <= scene.sceneend
                            )
                            .map((frame: EyePointFrame) => {
                                return frame.heatmapdata.map(
                                    ({ left, top, value }) => ({ left, top, value })
                                );
                            }),
                    };

                    return newScene;
                });

                // scenes and eye impact sorting desc
                const sceneSortings = [...sceneList];

                const sceneSort: any[] = [];
                sceneSortings.sort((a, b) => (parseFloat(b.expression) - parseFloat(a.expression))).forEach((scene, index) => {
                    if (index > 0) {
                        return
                    } else {
                        sceneSort.push(scene);
                    }
                });

                const eyeImpactSort: any[] = [];
                sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact))).forEach((scene, index) => {
                    if (index > 0) {
                        return
                    } else {
                        eyeImpactSort.push(scene);
                    }
                });

                const adsData = {
                    id: adid,
                    adtitle,
                    thumbnail,
                    brandtitle,
                    projecttitle,
                    adsviewers: dataViewers,
                    dataExpression,
                    averageExpression,
                    dataSignal,
                    averageSignal,
                    labelLineData,
                    sceneList: sceneSort,
                    eyeImpactList: eyeImpactSort,
                };

                const index = state.compareItems.findIndex((item: any) => item.id === payload);

                if (index != -1) {
                    Object.assign(state.compareItems[index], adsData)
                }

                // console.log(state.compareItems);
                // state.compareItems.push(data);
                // commit("COMPARE_ITEMS", adsData);
                // commit("APP_LOADING", false, { root: true });
                return true;
            }

            return false;
        },
        async [types.DELETE_ADS]({ commit, state }: IContext, payload: IAdsDetailPayloadTypes) {
            // commit("APP_LOADING", true, { root: true });
            const response: any = await AdsService.deleteAds(payload);

            if (response && response?.status) {
                return response?.status;
            }
        },
        async [types.DELETE_VIEWER]({ commit, state }: IContext, payload: IAdsDetailPayloadTypes) {
            // commit("APP_LOADING", true, { root: true });
            const response: any = await AdsService.deleteViewer(payload);

            if (response && response?.status) {
                // commit("APP_LOADING", false, { root: true });
                return response?.status;
            }
        },
        async [types.PURGE_VIEWER]({ commit, state }: IContext, payload: IPayloads) {
            // commit("APP_LOADING", true, { root: true });
            const response: any = await AdsService.purgeTesterById(payload);

            if (response && response?.status) {
                // commit("APP_LOADING", false, { root: true });
                return response?.status;
            }
        },
    },
};