import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a292072"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "design-test-percent-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataItems.packages, ({ color, percent }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "percent",
        style: _normalizeStyle(`background-color: ${color}; width: ${percent}%;`)
      }, null, 4))
    }), 128))
  ]))
}