/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, reactive, onMounted, watch, onBeforeMount, provide } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { SceneAnalysisType } from '@adapterdigital/xsight-packaging/dist/lib/packaging-scene-analysis'
import { XSightType } from '@adapterdigital/xsight-packaging/dist/lib/type'

// Helpers
import { log, isInteger, SecondToMinute, percentConvert } from "@/helpers";

// Components
import VideoMain from "@/components/video-main/video-main.vue";

// Interfaces and Types
import type { TableColumnType } from "ant-design-vue";
import { TableDataType, RefTypes } from "@/interfaces/component.interface";

// Echart Package Import and Use
import { use, ComposeOption } from "echarts/core";
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    GridComponent,
    GridComponentOption,
    MarkAreaComponent,
    MarkAreaComponentOption
} from 'echarts/components';
import {
    PieChart,
    PieSeriesOption
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

use([
    PieChart,
    LabelLayout,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    TooltipComponent,
    UniversalTransition,
    MarkAreaComponent
]);

type EChartsOption = ComposeOption<
    TooltipComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PieSeriesOption
    | MarkAreaComponentOption
>;

import { useStore } from 'vuex';
import { key } from "@/store";

export default defineComponent({
    name: "PackageInformationView",
    components: {
        VideoMain
    },
    setup() {

        const route = useRoute();
        const router = useRouter();
        const store = useStore(key);
        const sceneData: RefTypes = ref(null);
        const eyeImpactData: RefTypes = ref(null);
        const isVisibleScene = ref<boolean>(false);
        const packageType = ref<string>('');
        const sortedAgeRangeSegmentation: RefTypes = ref();
        const sortedEyeCatchObjectAndExpression: RefTypes = ref();
        const signalAverage: RefTypes = ref(0);
        const vdoScenesData: RefTypes = ref(null);
        const SetVDO = ref(false);
        const packageList = ref([])
        const packageDetails = ref([])
        const packagingData: RefTypes = reactive({
            head: 'Top Outstanding Score',
            hints: `
                <ul>
                    <li>
                    Outstanding Score คือคะแนนที่บ่งบองถึงความโดดเด่นของ Design 
                    แต่ละตัวเปรียบเทียบกันกับ Design ตัวอื่นๆ โดยคำนวณมาจาก 2 ส่วนคือ
                    </li>
                    <li>
                    1. Design Test ซึ่งเป็นคะแนนเปรียบเทียบสัดส่วนการมอง Design 
                    แต่ละตัวเทียบกัน ทั้งการมองนาน Long Look และมองก่อน First Look
                    </li>
                    <li>
                    2. Competitor Test เป็นคะแนนเปรียบเทียบสัดส่วนการมอง Design 
                    แต่ละตัวเทียบกับ Design ของคู่แข่งเจ้าต่างๆพร้อมๆกัน
                    </li>
                </ul>
            `,
            signalScores: {},
            eyeCatchObjects: [],
            graphSignal: [],
            emotionData: [],
            sceneData: [],
            sorttype: 'expression',
            packageItems: [],
            TopDesignScore: [],
            popup: {
                design: {
                    head: 'Design Test',
                    body: `
                        <ul>
                            <li>
                            เป็นคะแนนเปรียบเทียบสัดส่วนการมอง Design แต่ละตัวเทียบกัน 
                            ทั้งการมองนาน Long Look และมองก่อน First Look
                            </li>
                            <li>
                            โดยการเทสต์จะมาจากการนำ Design แต่ละตัวมาวางเทสต์กันเป็นคู่ๆ 
                            แบบพบกันหมดจนครบทุกคู่ Design ตัวไหนมีการถูกมองก่อน 
                            และมองนานจะได้คะแนนมากที่สุดเป็นสัดส่วนเทียบกับ Design ตัวอื่นๆ
                            </li>
                        </ul>
                    `
                },
                competitor: {
                    head: 'Competitor Test',
                    body: `
                        <ul>
                            <li>
                            Competitor Test เป็นคะแนนเปรียบเทียบสัดส่วนการมอง Design 
                            แต่ละตัวเทียบกับ Design ของคู่แข่งเจ้าต่างๆพร้อมๆกัน
                            </li>
                            <li>
                            โดยการเทสต์จะมาจากการนำ Design ทีละตัวมาเทียบกับ Design ของคู่แข่งทั้งหมดพร้อมๆกัน 
                            และจะมีการสุ่มสลับตำแหน่งกัน เพื่อป้องกันการ Bias จาก การมองจุดเดิมซ้ำๆ หรือมองเฉพาะบางส่วนของหน้าจอ 
                            ซึ่งคะแนนที่ได้จะเป็นอัตราส่วนคะแนนการมองนาน Long Look และมองก่อน First Look ระหว่าง 
                            Design นั้นๆกับคู่แข่งทั้งหมด
                            </li>
                        </ul>
                    `
                }
            }
        })

        // Age range segmentation
        const columnsAgeRangeSegmentation = computed<TableColumnType[]>(() => {
            const sorted = sortedAgeRangeSegmentation.value || {};
            return [{
                name: 'Age Range',
                dataIndex: 'name',
                key: 'name',
                width: '20',
            },
            {
                title: 'Angry',
                dataIndex: 'angry',
                key: 'angry',
                sorter: (a: TableDataType, b: TableDataType) => a.angry - b.angry,
                sortOrder: sorted.columnKey === 'angry' && sorted.order,
            },
            {
                title: 'Disgusted',
                dataIndex: 'disgusted',
                key: 'disgusted',
                sorter: (a: TableDataType, b: TableDataType) => a.disgusted - b.disgusted,
                sortOrder: sorted.columnKey === 'disgusted' && sorted.order,
            },

            {
                title: 'Happy',
                dataIndex: 'happy',
                key: 'happy',
                sorter: (a: TableDataType, b: TableDataType) => a.happy - b.happy,
                sortOrder: sorted.columnKey === 'happy' && sorted.order,
            },
            {
                title: 'Sad',
                dataIndex: 'sad',
                key: 'sad',
                sorter: (a: TableDataType, b: TableDataType) => a.sad - b.sad,
                sortOrder: sorted.columnKey === 'sad' && sorted.order,
            },
            {
                title: 'Surprised',
                dataIndex: 'surprised',
                key: 'surprised',
                sorter: (a: TableDataType, b: TableDataType) => a.surprised - b.surprised,
                sortOrder: sorted.columnKey === 'surprised' && sorted.order,
            },
            {
                title: 'Fearful',
                dataIndex: 'fearful',
                key: 'fearful',
                sorter: (a: TableDataType, b: TableDataType) => a.fearful - b.fearful,
                sortOrder: sorted.columnKey === 'fearful' && sorted.order,
            },
            {
                title: 'Signal',
                dataIndex: 'signal',
                key: 'signal',
                sorter: (a: TableDataType, b: TableDataType) => a.signal - b.signal,
                sortOrder: sorted.columnKey === 'signal' && sorted.order,
            },
            {
                title: 'Emotion Intensity',
                dataIndex: 'expression',
                key: 'expression',
                sorter: (a: TableDataType, b: TableDataType) => a.expression - b.expression,
                sortOrder: sorted.columnKey === 'expression' && sorted.order,
            },
            {
                title: 'Visual Impact',
                dataIndex: 'eye_impact',
                key: 'eye_impact',
                sorter: (a: TableDataType, b: TableDataType) => a.eye_impact - b.eye_impact,
                sortOrder: sorted.columnKey === 'eye_impact' && sorted.order,
            }]
        });

        const dataAgeRangeSegmentation: TableDataType[] = [
            {
                key: '1',
                name: '20-30',
                angry: 12,
                disgusted: 0,
                happy: 40,
                sad: 2,
                surprised: 22,
                fearful: 12,
                signal: 24,
                expression: 12,
                eye_impact: 40
            },
            {
                key: '2',
                name: '30-40',
                angry: 28,
                disgusted: 25,
                happy: 20,
                sad: 15,
                surprised: 0,
                fearful: 12,
                signal: 12,
                expression: 53,
                eye_impact: 20
            },
            {
                key: '3',
                name: '40-50',
                angry: 1,
                disgusted: 2,
                happy: 75,
                sad: 3,
                surprised: 8,
                fearful: 12,
                signal: 11,
                expression: 3,
                eye_impact: 75
            },
            {
                key: '4',
                name: '50 up',
                angry: 12,
                disgusted: 12,
                happy: 12,
                sad: 22,
                surprised: 12,
                fearful: 12,
                signal: 12,
                expression: 12,
                eye_impact: 12
            }
        ];

        // Eye catch object and expression
        const columnsEyeCatchObjectAndExpression = computed<TableColumnType[]>(() => {
            const sorted = sortedEyeCatchObjectAndExpression.value || {};
            return [
                {
                    name: 'Object',
                    dataIndex: 'name',
                    key: 'name',
                    width: '13%', // 20
                },
                {
                    title: 'Showtime',
                    dataIndex: 'showtime',
                    key: 'showtime',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.showtime - b.showtime,
                    sortOrder: sorted.columnKey === 'showtime' && sorted.order,
                    // width: '150',
                },
                {
                    title: 'Angry',
                    dataIndex: 'angry',
                    key: 'angry',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.angry - b.angry,
                    sortOrder: sorted.columnKey === 'angry' && sorted.order,
                },
                {
                    title: 'Disgusted',
                    dataIndex: 'disgusted',
                    key: 'disgusted',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.disgusted - b.disgusted,
                    sortOrder: sorted.columnKey === 'disgusted' && sorted.order,
                },
                {
                    title: 'Happy',
                    dataIndex: 'happy',
                    key: 'happy',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.happy - b.happy,
                    sortOrder: sorted.columnKey === 'happy' && sorted.order,
                },
                {
                    title: 'Sad',
                    dataIndex: 'sad',
                    key: 'sad',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.sad - b.sad,
                    sortOrder: sorted.columnKey === 'sad' && sorted.order,
                },
                {
                    title: 'Surprised',
                    dataIndex: 'surprised',
                    key: 'surprised',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.surprised - b.surprised,
                    sortOrder: sorted.columnKey === 'surprised' && sorted.order,
                },
                {
                    title: 'Fearful',
                    dataIndex: 'fearful',
                    key: 'fearful',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.fearful - b.fearful,
                    sortOrder: sorted.columnKey === 'fearful' && sorted.order,
                },
                {
                    title: 'Emotion Intensity',
                    dataIndex: 'expression',
                    key: 'expression',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.expression - b.expression,
                    sortOrder: sorted.columnKey === 'expression' && sorted.order,
                },
                {
                    title: 'Eye Catching',
                    dataIndex: 'signal',
                    key: 'signal',
                    width: '11%',
                    sorter: (a: TableDataType, b: TableDataType) => a.signal - b.signal,
                    sortOrder: sorted.columnKey === 'signal' && sorted.order,
                },
                {
                    title: 'Visual Impact',
                    dataIndex: 'eye_impact',
                    key: 'eye_impact',
                    sorter: (a: TableDataType, b: TableDataType) => a.eye_impact - b.eye_impact,
                    sortOrder: sorted.columnKey === 'eye_impact' && sorted.order,
                },
                {
                    title: 'Attention Signals Score',
                    dataIndex: 'attentionsignalscore',
                    key: 'attentionsignalscore',
                    width: '11%',
                    sorter: (a: TableDataType, b: TableDataType) => a.attentionsignalscore - b.attentionsignalscore,
                    sortOrder: sorted.columnKey === 'attentionsignalscore' && sorted.order,
                }
            ]
        });

        const dataEyeCatchObjectAndExpression = ref<TableDataType[]>([]);

        const handleChangeAgeRangeSegmentation = (sorted: string) => {
            sortedAgeRangeSegmentation.value = sorted;
        };

        const handleChangeEyeCatchObjectAndExpression = (sorted: string) => {
            sortedEyeCatchObjectAndExpression.value = sorted;
        };

        // Expression & Signal
        const optionChart = ref<EChartsOption>({});

        provide("SummaryChart", optionChart);

        const expressionAverage = computed(() => {
            const { expression } = store.getters['advertise/REQUEST_EXPRESSION_DATA'];
            const expressionPercent = (expression * 100);
            return expression ? isInteger(expressionPercent.toFixed(1))
                ? `${expressionPercent.toFixed(0)}%`
                : `${expressionPercent.toFixed(1)}%`
                : 'N/A'
        });

        const fetch = async () => {

            vdoScenesData.value = await store.getters['advertise/VDO_SCENE'];

            const sceneSortings = [...vdoScenesData.value];

            sceneData.value = [];
            const itemExpression = sceneSortings.sort((a, b) =>
                (parseFloat(b.expression) - parseFloat(a.expression)));
            sceneData.value = itemExpression.slice(0, 3);

            eyeImpactData.value = [];
            const itemImpact = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)));
            eyeImpactData.value = itemImpact.slice(0, 3);

            dataEyeCatchObjectAndExpression.value = await store.getters['advertise/REQUEST_EYECATCH_OBJECTS'];

            if (sceneSortings.length > 0) {
                // get top attention signal score
                packagingData.sceneData = sceneSortings.sort((a, b) => (parseFloat(b.attentionsignalscore) - parseFloat(a.attentionsignalscore))).slice(0, 1);
            }
            else {
                packagingData.sceneData = []
            }

            // Package information
            const { overview, topDesignScoreByProduct } = store.getters['advertise/REQUEST_PACKAGING_DATA'];
            const { vdoHeight, vdoWidth } = store.getters['advertise/VDO_HEATMAP'];
            const defaultColors = store.getters['advertise/REQUEST_DEFAULT_COLORS'];
            const designColors = store.getters['advertise/REQUEST_DESIGN_COLORS'];
            const competitorColors = store.getters['advertise/REQUEST_COMPETITOR_COLORS'];

            if (overview) {

                const {
                    averageOutstandingScore,
                    highestOutstandingScore,
                    lowestOutstandingScore,
                    topOutstandingScoreProduct
                } = overview

                const legend: any[] = [];
                const series: any[] = [];

                const packageItems = topOutstandingScoreProduct.map(({
                    name, value, frame
                }: any, index: number) => {
                    // console.log({ index, highlight, frame, firstLookScore, longLookScore })

                    const { frame_image, crop } = frame
                    // console.log({ value })

                    const { framethumburl } = frame_image
                    // console.log({ frameurl })

                    // const { top, left, right, bottom, width, height } = crop
                    // console.log({ top, left, right, bottom, width, height })

                    // set legend and series properties
                    legend.push(name)
                    series.push({ value: percentConvert(value > 0 ? value : 0) ?? 0, name })

                    return {
                        name: name,
                        image: {
                            alt: name,
                            src: framethumburl,
                            width: 31, // % and px
                            height: 31, // % and px
                            padding: 0,
                            crop,
                            percentage: 0,
                            is_thumbnail: true
                        },
                        percent: percentConvert(value > 0 ? value : 0),
                        color: defaultColors[index]
                    }
                })

                // not equal to 100% add empty series

                Object.assign(packagingData, {
                    averageOutstandingScore,
                    highestOutstandingScore,
                    lowestOutstandingScore,
                    packageItems,
                    vdoWidth,
                    vdoHeight,
                })

                Object.assign(optionChart.value, {
                    tooltip: {
                        show: false,
                        trigger: 'item',
                        borderWidth: 1,
                        borderColor: 'rgba(193, 17, 21, 0.3)',
                        backgroundColor: '#2D3436',
                        borderRadius: 14,
                        padding: [5, 10],
                        textStyle: {
                            fontFamily: 'IBM Plex Light',
                            fontSize: 10,
                            color: '#FFFFFF'
                        },
                        formatter: '{b0}: {c0}%',
                    },
                    legend: {
                        show: false,
                        orient: "vertical",
                        left: "left",
                        data: legend
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: '85%',
                            avoidLabelOverlap: false,
                            color: defaultColors,
                            label: {
                                rotate: 0,
                                position: 'inside',
                                alignTo: 'none',
                                color: '#FFFFFF',
                                fontSize: 10,
                                fontFamily: 'IBM Plex Bold',
                                padding: 0,
                                align: '50',
                                verticalAlign: 'middle',
                                formatter: '{d}%'
                            },
                            emphasis: {
                                label: {
                                    // show: false,
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: series
                        }
                    ]
                });
            }


            if (topDesignScoreByProduct) {
                const TopDesignScore = topDesignScoreByProduct?.map(({
                    name,
                    mergedProductFrameDisplay,
                    importantElement,
                    outstandingScore,
                    designTestScore,
                    designTestScoreProductList,
                    designTestScoreDetail,
                    competitorTestScore,
                    competitorTestScroeProductList,
                    competitorTestScoreDetail
                }: SceneAnalysisType.PSAData$ProductDesignScore, index: number) => {

                    // console.log({ mergedProductFrameDisplay })

                    // Importance Element
                    const importanceElementData = importantElement?.map((item: any) => {
                        return {
                            name: item.name,
                            image: {
                                name: item.name,
                                alt: item.name,
                                src: item.frame?.frame_image?.framethumburl,
                                width: '45',
                                height: '45',
                                padding: '0',
                                crop: item.frame?.crop,
                                percentage: 0,
                                // resolution: "full",
                            },
                            showtime: `${SecondToMinute(item.showtime)}s`,
                            // eyecatch: formatNumber((item.eyecatch * 100)),
                            eyecatch: percentConvert(item.eyecatch > 0 ? item.eyecatch : 0)
                        }
                    })

                    // Design Test Score Detail Data
                    const designTestScoreProductListData = designTestScoreProductList?.map((design: any, index: number) => {
                        return {
                            name: design?.name,
                            image: {
                                alt: design?.name,
                                src: design?.frame?.frame_image?.framethumburl,
                                width: '31',
                                height: '31',
                                padding: "0",
                                crop: design?.frame?.crop,
                                // resolution: "full"
                            },
                            highlight: design?.highlight,
                            // percent: formatNumber((design?.value * 100)),
                            percent: percentConvert(design?.value > 0 ? design?.value : 0),
                            color: designColors[index],
                        }
                    })

                    // Competitor Test Scroe Product List Data
                    const competitorTestScroeProductListData = competitorTestScroeProductList?.map((competitor: any, index: number) => {
                        return {
                            name: competitor?.name,
                            image: {
                                alt: competitor?.name,
                                src: competitor?.frame?.frame_image?.framethumburl,
                                width: '31',
                                height: '31',
                                padding: "0",
                                crop: competitor?.frame?.crop,
                                // resolution: "full",
                            },
                            highlight: competitor?.highlight,
                            // percent: formatNumber((competitor?.value * 100)),
                            percent: percentConvert(competitor?.value > 0 ? competitor?.value : 0),
                            color: competitorColors[index],
                        }
                    })

                    // Design Test Scroe Detail
                    const designPackageList = designTestScoreDetail?.packageList?.map(({ name, value, frame }: any, index: number) => {

                        const { frame_image, crop } = frame;
                        const { framethumburl } = frame_image;

                        return {
                            name: name,
                            image: {
                                alt: name,
                                src: framethumburl,
                                width: '31', // % and px
                                height: '31', // % and px
                                padding: '0',
                                crop,
                                percentage: 0,
                            },
                            // percent: formatNumber((value * 100)),
                            percent: percentConvert(value > 0 ? value : 0),
                            color: designColors[index]
                        }
                    })

                    // Competitor Test Scroe Detail
                    const competitorPackageList = competitorTestScoreDetail?.packageList?.map(({ name, value, frame }: any, index: number) => {

                        const { frame_image, crop } = frame;
                        const { framethumburl } = frame_image;

                        return {
                            name: name,
                            image: {
                                alt: name,
                                src: framethumburl,
                                width: '31', // % and px
                                height: '31', // % and px
                                padding: '0',
                                crop,
                            },
                            // percent: formatNumber((value * 100)),
                            percent: percentConvert(value > 0 ? value : 0),
                            color: competitorColors[index]
                        }
                    })

                    type PhotoScene = {
                        time: string,
                        poster: string,
                        scene: string // thumb
                    }

                    type EyeList = {
                        left: number,
                        top: number,
                        width: number,
                        height: number,
                        percent: number
                    }

                    // scenes
                    type VideoData = {
                        EndTime: number,
                        HeatMapData: XSightType.AdStat$HeatMapData[][],
                        ObjectData: [],
                        Photolist: string[],//Large Image
                        StartTime: number,
                        eyeListData: EyeList[],
                        peakScenesObj: {
                            prev: PhotoScene[],
                            peak: Array<PhotoScene & { index: number }>,
                            next: PhotoScene[],
                            playingTime: number
                            playingTimeEyeImpact: 0
                            playingVdo: boolean
                            playingVdoEyeImpact: boolean,
                            totalSec: number
                        },
                        videoWidth: number,
                        videoHeight: number,
                    }

                    const designTestScoreDetail_mod = designTestScoreDetail.competeList.map(item => {
                        const data = { ...item } as any;

                        data.importantElement = item.importantElement.map(o => {
                            const image = {
                                alt: o.name,
                                src: o.frame.frame_image.framethumburl,
                                width: 136, // % and px
                                height: 77, // % and px
                                padding: '0',
                                crop: o.frame.crop
                            }
                            return {
                                ...o,
                                image
                            }
                        });

                        const videoPreviewFrames: SceneAnalysisType.PSAData$FrameData[] = item.videoPreviewFrames;
                        const peakScenes = videoPreviewFrames.map((d) => {
                            return {
                                poster: d.frame_image.frameurl,
                                scene: d.frame_image.frameurl,
                                time: d.time_index + ""
                            } as PhotoScene;
                        });

                        const videoData: VideoData = {
                            EndTime: videoPreviewFrames.length - 1,
                            eyeListData: videoPreviewFrames.map(d => {
                                return d.heatMap.eyeImpact;
                            }),
                            HeatMapData: videoPreviewFrames.map(d => d.heatMap.heatmapdata),
                            ObjectData: [],
                            peakScenesObj: {
                                prev: [],
                                peak: peakScenes.map((d, i) => {
                                    return {
                                        ...d,
                                        index: i
                                    }
                                }),
                                next: [],
                                playingTime: 0,
                                playingTimeEyeImpact: 0,
                                playingVdo: false,
                                playingVdoEyeImpact: false,
                                totalSec: peakScenes.length

                            },
                            Photolist: videoPreviewFrames.map(d => d.frame_image.frameurl),
                            StartTime: 0,
                            videoHeight: vdoHeight,
                            videoWidth: vdoWidth,
                        }

                        data.videoData = videoData;

                        return data;
                    })

                    // competitor
                    const importantElement_mod = competitorTestScoreDetail.importantElement.map(item => {
                        const image = {
                            alt: item.name,
                            src: item.frame.frame_image.framethumburl,
                            width: 136, // % and px
                            height: 77, // % and px
                            padding: '0',
                            crop: item.frame.crop
                        }

                        return {
                            ...item,
                            image
                        }
                    })

                    const videoPreviewFrames: SceneAnalysisType.PSAData$FrameData[] = competitorTestScoreDetail.videoPreviewFrames;
                    const peakScenes = videoPreviewFrames.map((d) => {
                        return {
                            poster: d.frame_image.frameurl,
                            scene: d.frame_image.frameurl,
                            time: d.time_index + ""
                        } as PhotoScene;
                    });

                    const videoData: VideoData = {
                        EndTime: videoPreviewFrames.length - 1,
                        eyeListData: videoPreviewFrames.map(d => {
                            return d.heatMap.eyeImpact;
                        }),
                        HeatMapData: videoPreviewFrames.map(d => d.heatMap.heatmapdata),
                        ObjectData: [],
                        peakScenesObj: {
                            prev: [],
                            peak: peakScenes.map((d, i) => {
                                return {
                                    ...d,
                                    index: i
                                }
                            }),
                            next: [],
                            playingTime: 0,
                            playingTimeEyeImpact: 0,
                            playingVdo: false,
                            playingVdoEyeImpact: false,
                            totalSec: peakScenes.length

                        },
                        Photolist: videoPreviewFrames.map(d => d.frame_image.frameurl),
                        StartTime: 0,
                        videoHeight: vdoHeight,
                        videoWidth: vdoWidth,
                    }

                    return {
                        id: index,
                        name: "Key Element",
                        image: mergedProductFrameDisplay?.frame_image?.frameurl,
                        isSwithHeatmap: false,
                        isSwithEyeContact: false,
                        eyeListData: mergedProductFrameDisplay.heatMap.eyeImpact,
                        HeatMapData: mergedProductFrameDisplay.heatMap.heatmapdata,
                        ObjectData: mergedProductFrameDisplay.objectCoordinated,
                        packageName: name,
                        importanceElement: importanceElementData,
                        outstading: {
                            name: "Outstanding",
                            // percent: formatNumber((outstandingScore * 100)),
                            percent: percentConvert(outstandingScore > 0 ? outstandingScore : 0),
                        },
                        designTest: {
                            // summary: formatNumber((designTestScore * 100)),
                            summary: percentConvert(designTestScore > 0 ? designTestScore : 0),
                            packages: designTestScoreProductListData,
                        },
                        competitorTest: {
                            // summary: formatNumber((competitorTestScore * 100)),
                            summary: percentConvert(competitorTestScore > 0 ? designTestScore : 0),
                            packages: competitorTestScroeProductListData,
                        },
                        designPackageList,
                        competitorPackageList,
                        designTestScoreDetail: {
                            competeList: designTestScoreDetail_mod
                        },
                        competitorTestScoreDetail: {
                            importantElement: importantElement_mod,
                            videoPreviewFrames: videoData,
                            packageList: competitorTestScoreDetail.packageList
                        }
                    };
                })

                Object.assign(packagingData, {
                    TopDesignScore
                })

                // console.log({ packagingData })

            }

            await store.dispatch('APP_LOADING', false);
        }

        const handlerModal = ({ type = 'design', list = [], detail = {} }: any) => {

            if (list.length > 0) {
                packageType.value = type;
                packageList.value = list;
                packageDetails.value = detail;
            }
            // console.log({ type, list, detail })
            isVisibleScene.value = !isVisibleScene.value;
        };

        const handlerHeatmap = ({ isChecked, idChecked }: any) => {
            const TopDesignScore = packagingData.TopDesignScore
            TopDesignScore?.map((o: any) => {
                if (o.id == idChecked)
                    o.isSwithHeatmap = isChecked
                return o
            })
            // console.log({ handlerHeatmap: TopDesignScore })
        }

        const HandlerEyeContact = ({ isChecked, idChecked }: any) => {
            const TopDesignScore = packagingData.TopDesignScore
            TopDesignScore?.map((o: any) => {
                if (o.id == idChecked)
                    o.isSwithEyeContact = isChecked
                return o
            })
            // console.log({ HandlerEyeContact: TopDesignScore })
        }

        watch(() => route.params.id, (id: any) => {
            if (!id) return;
            window.location.assign(`${window.location.origin}/package-information/${id}`);
        });

        watch(() => store.getters['advertise/VDO_SCENE'], async (value: any) => {

            const sceneSortings = [...value];

            sceneData.value = [];
            const itemExpression = sceneSortings.sort((a, b) =>
                (parseFloat(b.expression) - parseFloat(a.expression)));
            sceneData.value = itemExpression.slice(0, 3);

            eyeImpactData.value = [];
            const itemImpact = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)));
            eyeImpactData.value = itemImpact.slice(0, 3);

            dataEyeCatchObjectAndExpression.value = await store.getters['advertise/REQUEST_EYECATCH_OBJECTS'];

            // filters update
            await fetch();
        });

        onBeforeMount(async () => {

            // console.log('onbeforeMounted')
            const payload = { adid: route.params.id };
            const sceneListData = store.getters['advertise/VDO_SCENE'];
            const { back, forward }: any = router.options.history.state;

            if (sceneListData.length == 0 || back == '/') {  // หรือมาจากหน้า dashboard 
                log({ back, forward, sceneListData: sceneListData.length })
                await store.dispatch('APP_LOADING', true);
                await store.dispatch('advertise/VDO_FRAME', payload);
                await store.dispatch('advertise/REQUEST_ADS_STAT', payload);
            } else {
                SetVDO.value = true
            }

            await fetch();
        })

        onMounted(async () => {
            // console.log('onMounted')
            window.scrollTo(0, 0);
        });

        return {
            sceneData,
            optionChart,
            eyeImpactData,
            signalAverage,
            isVisibleScene,
            handlerModal,
            expressionAverage,
            dataAgeRangeSegmentation,
            columnsAgeRangeSegmentation,
            dataEyeCatchObjectAndExpression,
            handleChangeAgeRangeSegmentation,
            columnsEyeCatchObjectAndExpression,
            handleChangeEyeCatchObjectAndExpression,
            SetVDO,
            packagingData,
            packageType,
            packageList,
            packageDetails,
            handlerHeatmap,
            HandlerEyeContact,
        };
    },

});