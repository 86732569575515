/* eslint-disable @typescript-eslint/no-unused-vars */
import { IContext } from "@/interfaces/advertise.interface";
import { AnyObjectTypes, PayloadTypes } from "@/interfaces/brand.interface";

// Service
import BrandService from "@/services/brand.service";

const BrandAPI = new BrandService();

export const types = {
    SHOW_MODAL: "SHOW_MODAL",
    UPLOAD_FILE: "UPLOAD_FILE",
    UPLOADED_FILE: "UPLOADED_FILE",
    CREATE_TASK: 'CREATE_TASK',
    PRELOAD_FILE: "PRELOAD_FILE",
    UPLOAD_MEDIA: 'UPLOAD_MEDIA',
    CREATE_VIDEO: 'CREATE_VIDEO',
    UPDATE_VIDEO: 'UPDATE_VIDEO',
    CREATE_VIEWER: 'CREATE_VIEWER',
    GET_VDO_LINK: "GET_VDO_LINK",
    GET_UPLOADFILE: "GET_UPLOADFILE",
    GET_VDO_POSTER: "GET_VDO_POSTER",
    GET_VDO_PREVIEW: "GET_VDO_PREVIEW",
    GET_VDO_DATA: "GET_VDO_DATA",
    GET_TESTER_DATA: "GET_TESTER_DATA",
    GET_DOWNLOAD_FILE: "GET_DOWNLOAD_FILE",
    UPDATE_VDO_LINK: "UPDATE_VDO_LINK",
    UPDATE_TESTER_DATA: "UPDATE_TESTER_DATA",
};

export default {
    namespaced: true,
    state: {
        data: {},
        tester: [],
        isModal: false,
        isVdoLink: false,
        isPreload: false,
        previewVdo: null,
        fileUploaded: null,
        isFileUploaded: false,
        fileUploadedName: null,
        fileDownloadUrl: null
    },
    getters: {
        [types.GET_VDO_DATA]: (state: AnyObjectTypes) => state.data,
        [types.SHOW_MODAL]: (state: AnyObjectTypes) => state.isModal,
        [types.PRELOAD_FILE]: (state: AnyObjectTypes) => state.isPreload,
        [types.GET_TESTER_DATA]: (state: AnyObjectTypes) => state.tester,
        [types.GET_VDO_LINK]: (state: AnyObjectTypes) => state.isVdoLink,
        [types.GET_VDO_PREVIEW]: (state: AnyObjectTypes) => state.previewVdo,
        [types.UPLOADED_FILE]: (state: AnyObjectTypes) => state.fileUploaded,
        [types.UPLOAD_FILE]: (state: AnyObjectTypes) => state.fileUploadedName,
        [types.GET_UPLOADFILE]: (state: AnyObjectTypes) => state.isFileUploaded,
        [types.GET_DOWNLOAD_FILE]: (state: AnyObjectTypes) => state.fileDownloadUrl,
    },
    mutations: {
        [types.UPLOAD_FILE](state: AnyObjectTypes, payload: PayloadTypes): void {

            state.isFileUploaded = false;
            state.previewVdo = null;
            state.isVdoLink = false;
            state.fileUploaded = null;
            state.fileUploadedName = null;

            const { upload, preview, update } = payload;

            // file upload only
            if (typeof (payload.upload) === 'object') {
                state.isFileUploaded = true;
                state.isVdoLink = false;
                state.fileUploaded = payload;
                state.fileUploadedName = upload.name;
                state.previewVdo = preview;
            } else { // youtube and mp4
                if (update) {
                    // update youtube link
                    if (upload.toLowerCase().includes('youtube')) {
                        state.isFileUploaded = false;
                        state.isVdoLink = true
                    } else { // update mp4
                        state.isFileUploaded = true;
                        state.isVdoLink = false;
                    }
                    state.fileUploadedName = upload;
                    state.previewVdo = preview;
                } else {
                    // create case
                    state.isFileUploaded = false;
                    state.isVdoLink = true
                    state.fileUploadedName = upload;
                    state.previewVdo = preview;
                }
            }
        },
        [types.PRELOAD_FILE](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.isPreload = payload;
        },
        [types.SHOW_MODAL](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.isModal = payload;
        },
        [types.GET_VDO_DATA](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.data = payload;
        },
        [types.GET_TESTER_DATA](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.tester = payload;
        },
        [types.GET_VDO_PREVIEW](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.previewVdo = payload;
        },
        [types.GET_DOWNLOAD_FILE](state: AnyObjectTypes, payload: PayloadTypes): void {
            state.fileDownloadUrl = payload;
        }
    },
    actions: {
        async [types.CREATE_VIDEO]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.createVdo(payload);
            if (response) {
                commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.UPDATE_VIDEO]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            commit("APP_LOADING", true, { root: true });
            const { id, update } = payload;
            const response = await BrandAPI.updateVdoById(id, update);
            if (response) {
                commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.CREATE_VIEWER]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            // commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.createViewer(payload);
            if (response) {
                // commit("APP_LOADING", false, { root: true });
            }
        },
        async [types.GET_VDO_POSTER]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.createPoster(payload);
            if (response) {
                commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.CREATE_TASK]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            // commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.runQueueTask(payload.url, payload.task);
            if (response) {
                // commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.UPLOAD_MEDIA]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            // commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.upload(payload);
            if (response) {
                // commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.GET_VDO_DATA]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            const response = await BrandAPI.getVdoById(payload);
            if (response) {
                commit('GET_VDO_DATA', response);
                return response;
            }
        },
        async [types.GET_TESTER_DATA]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            const response = await BrandAPI.getTesterById(payload);
            if (response) {
                const viewers: any = response.filter((item: any) => !item.inactive);
                commit('GET_TESTER_DATA', viewers);
                return viewers;
            }
        },
        async [types.UPDATE_TESTER_DATA]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.updateTesterById(payload);
            if (response) {
                commit("APP_LOADING", false, { root: true });
                return response;
            }
        },
        async [types.UPDATE_VDO_LINK]({ commit }: IContext, payload: PayloadTypes): Promise<void> {
            commit("APP_LOADING", true, { root: true });
            const response = await BrandAPI.getMp4LinkById(payload);
            if (response) {
                return response;
            }
        },
    },
};