/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, computed, ref, reactive, onMounted, watch, onBeforeMount, provide } from "vue";
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';

// Helpers
import { log, isInteger } from "@/helpers";

// Components
import VideoMain from "@/components/video-main/video-main.vue";
import {
    ExpressionAndSignal,
    DataTableContent
} from "@/components";

// Interfaces and Types
import type { TableColumnType } from "ant-design-vue";
import { TableDataType, RefTypes } from "@/interfaces/component.interface";
// import { IScenedata } from "@/interfaces/advertise.interface";

// Echart Package Import and Use
import { use, ComposeOption } from "echarts/core";
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    GridComponent,
    GridComponentOption,
    MarkAreaComponent,
    MarkAreaComponentOption
} from 'echarts/components';
import {
    PieChart,
    PieSeriesOption,
    LineChart,
    LineSeriesOption
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

use([
    PieChart,
    LineChart,
    LabelLayout,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    TooltipComponent,
    UniversalTransition,
    MarkAreaComponent
]);

type EChartsOption = ComposeOption<
    TooltipComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PieSeriesOption
    | LineSeriesOption
    | MarkAreaComponentOption
>;

import SceneLists from "@/components/Scene-lists/Scene-lists.vue";
// import { EyeimpactPoint } from "@/interfaces/dashboard.interface";

import { useStore } from 'vuex';
import { key } from "@/store";

export default defineComponent({
    name: "VdoInformationView",
    components: {
        VideoMain,
        ExpressionAndSignal,
        DataTableContent,
        SceneLists
    },
    setup() {

        const route = useRoute();
        const router = useRouter();
        const store = useStore(key);
        const sceneData: RefTypes = ref(null);
        const eyeImpactData: RefTypes = ref(null);
        const isVisibleScene = ref<boolean>(false);
        const sortedAgeRangeSegmentation: RefTypes = ref();
        const sortedEyeCatchObjectAndExpression: RefTypes = ref();
        const signalAverage: RefTypes = ref(0);
        const vdoScenesData: RefTypes = ref(null);
        const SetVDO = ref(false);
        const topAttentionSignalSceneData: RefTypes = reactive({
            head: 'Top Attention Signal Scene',
            hints: `
                <ul>
                    <li>
                    ระบบของ XSight จะใช้การตัดแบ่งวิดีออกเป็น Scenes
                    แบบอัตโนมัติ โดยใช้การรวมซีนที่ต่ำกว่า 4 วินาทีเข้าด้วย
                    กันเป็นหนึ่งซีน
                    </li>
                    <li>
                    Attention Signals Score  นั้นจะคำนวณจากการนำค่า
                    สูงสุดของ Emotion Intensity และ Visual Impact 
                    ของซีนนั้นมาเฉลี่ยกัน 
                    </li>
                    <li>
                    นอกจากนี้คุณยังสามารถกดที่ปุ่ม Show More เพื่อดู 
                    Scenes อื่นๆ ทั้งหมดในวิดีโอนี้ และสามารถเลือกการ
                    จัดเรียงได้ 3 รูปแบบ คือ Attention Signal, 
                    Emotion Intensity และ Visual Impact
                    </li>
                </ul>
            `,
            signalScores: {},
            eyeCatchObjects: [],
            graphSignal: [],
            emotionData: [],
            sceneData: [],
            sorttype: 'expression'
        })

        const labelLineData = ref([
            // 'Angry',
            // 'Disgusted',
            // 'Happy',
            // 'Sad',
            // 'Surprised',
            'Signal',
            'Visual Impact',
        ]);

        // Age range segmentation
        const columnsAgeRangeSegmentation = computed<TableColumnType[]>(() => {
            const sorted = sortedAgeRangeSegmentation.value || {};
            return [{
                name: 'Age Range',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
            },
            {
                title: 'Angry',
                dataIndex: 'angry',
                key: 'angry',
                sorter: (a: TableDataType, b: TableDataType) => a.angry - b.angry,
                sortOrder: sorted.columnKey === 'angry' && sorted.order,
            },
            {
                title: 'Disgusted',
                dataIndex: 'disgusted',
                key: 'disgusted',
                sorter: (a: TableDataType, b: TableDataType) => a.disgusted - b.disgusted,
                sortOrder: sorted.columnKey === 'disgusted' && sorted.order,
            },

            {
                title: 'Happy',
                dataIndex: 'happy',
                key: 'happy',
                sorter: (a: TableDataType, b: TableDataType) => a.happy - b.happy,
                sortOrder: sorted.columnKey === 'happy' && sorted.order,
            },
            {
                title: 'Sad',
                dataIndex: 'sad',
                key: 'sad',
                sorter: (a: TableDataType, b: TableDataType) => a.sad - b.sad,
                sortOrder: sorted.columnKey === 'sad' && sorted.order,
            },
            {
                title: 'Surprised',
                dataIndex: 'surprised',
                key: 'surprised',
                sorter: (a: TableDataType, b: TableDataType) => a.surprised - b.surprised,
                sortOrder: sorted.columnKey === 'surprised' && sorted.order,
            },
            {
                title: 'Fearful',
                dataIndex: 'fearful',
                key: 'fearful',
                sorter: (a: TableDataType, b: TableDataType) => a.fearful - b.fearful,
                sortOrder: sorted.columnKey === 'fearful' && sorted.order,
            },
            {
                title: 'Signal',
                dataIndex: 'signal',
                key: 'signal',
                sorter: (a: TableDataType, b: TableDataType) => a.signal - b.signal,
                sortOrder: sorted.columnKey === 'signal' && sorted.order,
            },
            {
                title: 'Emotion Intensity',
                dataIndex: 'expression',
                key: 'expression',
                sorter: (a: TableDataType, b: TableDataType) => a.expression - b.expression,
                sortOrder: sorted.columnKey === 'expression' && sorted.order,
            },
            {
                title: 'Visual Impact',
                dataIndex: 'eye_impact',
                key: 'eye_impact',
                sorter: (a: TableDataType, b: TableDataType) => a.eye_impact - b.eye_impact,
                sortOrder: sorted.columnKey === 'eye_impact' && sorted.order,
            }]
        });

        const dataAgeRangeSegmentation: TableDataType[] = [
            {
                key: '1',
                name: '20-30',
                angry: 12,
                disgusted: 0,
                happy: 40,
                sad: 2,
                surprised: 22,
                fearful: 12,
                signal: 24,
                expression: 12,
                eye_impact: 40
            },
            {
                key: '2',
                name: '30-40',
                angry: 28,
                disgusted: 25,
                happy: 20,
                sad: 15,
                surprised: 0,
                fearful: 12,
                signal: 12,
                expression: 53,
                eye_impact: 20
            },
            {
                key: '3',
                name: '40-50',
                angry: 1,
                disgusted: 2,
                happy: 75,
                sad: 3,
                surprised: 8,
                fearful: 12,
                signal: 11,
                expression: 3,
                eye_impact: 75
            },
            {
                key: '4',
                name: '50 up',
                angry: 12,
                disgusted: 12,
                happy: 12,
                sad: 22,
                surprised: 12,
                fearful: 12,
                signal: 12,
                expression: 12,
                eye_impact: 12
            }
        ];

        // Eye catch object and expression
        const columnsEyeCatchObjectAndExpression = computed<TableColumnType[]>(() => {
            const sorted = sortedEyeCatchObjectAndExpression.value || {};
            return [
                {
                    name: 'Object',
                    dataIndex: 'name',
                    key: 'name',
                    width: '13%', // 20%
                },
                {
                    title: 'Showtime',
                    dataIndex: 'showtime',
                    key: 'showtime',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.showtime - b.showtime,
                    sortOrder: sorted.columnKey === 'showtime' && sorted.order,
                    // width: '150',
                },
                {
                    title: 'Angry',
                    dataIndex: 'angry',
                    key: 'angry',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.angry - b.angry,
                    sortOrder: sorted.columnKey === 'angry' && sorted.order,
                },
                {
                    title: 'Disgusted',
                    dataIndex: 'disgusted',
                    key: 'disgusted',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.disgusted - b.disgusted,
                    sortOrder: sorted.columnKey === 'disgusted' && sorted.order,
                },
                {
                    title: 'Happy',
                    dataIndex: 'happy',
                    key: 'happy',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.happy - b.happy,
                    sortOrder: sorted.columnKey === 'happy' && sorted.order,
                },
                {
                    title: 'Sad',
                    dataIndex: 'sad',
                    key: 'sad',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.sad - b.sad,
                    sortOrder: sorted.columnKey === 'sad' && sorted.order,
                },
                {
                    title: 'Surprised',
                    dataIndex: 'surprised',
                    key: 'surprised',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.surprised - b.surprised,
                    sortOrder: sorted.columnKey === 'surprised' && sorted.order,
                },
                {
                    title: 'Fearful',
                    dataIndex: 'fearful',
                    key: 'fearful',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.fearful - b.fearful,
                    sortOrder: sorted.columnKey === 'fearful' && sorted.order,
                },
                {
                    title: 'Emotion Intensity',
                    dataIndex: 'expression',
                    key: 'expression',
                    width: '8%',
                    sorter: (a: TableDataType, b: TableDataType) => a.expression - b.expression,
                    sortOrder: sorted.columnKey === 'expression' && sorted.order,
                },
                {
                    title: 'Eye Catch',
                    dataIndex: 'signal',
                    key: 'signal',
                    width: '11%',
                    sorter: (a: TableDataType, b: TableDataType) => a.signal - b.signal,
                    sortOrder: sorted.columnKey === 'signal' && sorted.order,
                },
                {
                    title: 'Visual Impact',
                    dataIndex: 'eye_impact',
                    key: 'eye_impact',
                    sorter: (a: TableDataType, b: TableDataType) => a.eye_impact - b.eye_impact,
                    sortOrder: sorted.columnKey === 'eye_impact' && sorted.order,
                },
                {
                    title: 'Attention Signals Score',
                    dataIndex: 'attentionsignalscore',
                    key: 'attentionsignalscore',
                    width: '11%',
                    sorter: (a: TableDataType, b: TableDataType) => a.attentionsignalscore - b.attentionsignalscore,
                    sortOrder: sorted.columnKey === 'attentionsignalscore' && sorted.order,
                }
            ]
        });

        const dataEyeCatchObjectAndExpression = ref<TableDataType[]>([]);

        const handleChangeAgeRangeSegmentation = (sorted: string) => {
            sortedAgeRangeSegmentation.value = sorted;
        };

        const handleChangeEyeCatchObjectAndExpression = (sorted: string) => {
            sortedEyeCatchObjectAndExpression.value = sorted;
        };

        // Expression & Signal
        const optionDonut = ref<EChartsOption>({});
        provide("SummaryDonutGraph", optionDonut);
        const optionLine = ref<EChartsOption>({});

        const optionDonutData = computed(() => {

            const {
                expressionangry,
                expressiondisgusted,
                expressionfearful,
                expressionhappy,
                expressionsad,
                expressionsurprised,
                expressionneutral
            } = store.getters['advertise/REQUEST_EXPRESSION_DATA'];

            // log(store.getters['advertise/REQUEST_EXPRESSION_DATA'])

            const dataAngry = (expressionangry * 100).toFixed(2) ?? 0;
            const dataDisgusted = (expressiondisgusted * 100).toFixed(2);
            const dataFearful = (expressionfearful * 100).toFixed(2);
            const dataHappy = (expressionhappy * 100).toFixed(2);
            const dataSad = (expressionsad * 100).toFixed(2);
            const dataSurprised = (expressionsurprised * 100).toFixed(2);
            const dataNeutral = (expressionneutral * 100).toFixed(2);

            Object.assign(optionDonut.value, {
                tooltip: {
                    show: false,
                    trigger: 'item',
                    borderWidth: 1,
                    borderColor: 'rgba(193, 17, 21, 0.3)',
                    backgroundColor: '#2D3436',
                    borderRadius: 14,
                    padding: [5, 10],
                    textStyle: {
                        fontFamily: 'IBM Plex Light',
                        fontSize: 10,
                        color: '#FFFFFF'

                    },
                    formatter: '{b0}: {c0}%',
                },
                legend: {
                    show: false,
                    orient: "vertical",
                    left: "left",
                    data: [
                        'Angry',
                        'Disgusted',
                        'Happy',
                        'Sad',
                        'Surprised',
                        'Fearful',
                        'Neutral'
                    ]
                },

                series: [
                    {
                        type: 'pie',
                        radius: ['70%', '95%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 3
                        },
                        color: ["#D63031", "#00B894", "#FD79A8", "#2970FD", "#FDCB6E", "#4B99FF", '#CCC'],
                        label: {
                            rotate: 0,
                            position: 'inside',
                            alignTo: 'none',
                            color: '#FFFFFF',
                            fontSize: 10,
                            fontFamily: 'IBM Plex Bold',
                            padding: 0,
                            align: 'center',
                            verticalAlign: 'middle',
                            formatter: '{d}%'
                        },
                        emphasis: {
                            label: {
                                // show: false,
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: dataAngry, name: 'Angry' },
                            { value: dataDisgusted, name: 'Disgusted' },
                            { value: dataHappy, name: 'Happy' },
                            { value: dataSad, name: 'Sad' },
                            { value: dataSurprised, name: 'Surprised' },
                            { value: dataFearful, name: 'Fearful' },
                            // { value: 20, name: 'Neutral' },
                            { value: dataNeutral, name: 'Neutral' }
                        ]
                    }
                ]
            });

            return optionDonut.value;
        });

        const optionLineData = computed(() => {

            const option = store.getters['advertise/VDO_EMOTION'];
            // let lineData: any = []
            const signal = option.filter((o: any) => o.name == 'signal');
            const visualimpact = option.filter((o: any) => o.name == 'eye impact');
            // lineData = [...lineData, signal[0]?.data, visualimpact[0]?.data]
            // console.log({ signal, visualimpact })

            Object.assign(optionLine.value, {
                color: ['#6C5CE7', '#FAAD14'],
                grid: {
                    // show: false,
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '0%',
                    containLabel: false,
                    backgroundColor: 'transparent',
                },
                legend: {
                    show: false,
                    padding: 0
                },
                aria: {
                    enabled: false,
                },
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        label: {
                            width: 10,
                            backgroundColor: '#6C5CE7'
                        },
                        lineStyle: {
                            color: '#00CEC9',
                            width: 3,
                            type: 'dotted',
                        }
                    },
                    borderWidth: 1,
                    borderColor: 'rgba(193, 17, 21, 0.3)',
                    backgroundColor: '#2D3436',
                    borderRadius: 14,
                    padding: [5, 10],
                    textStyle: {
                        fontFamily: 'IBM Plex Light',
                        fontSize: 10,
                        color: '#FFFFFF'

                    },
                    // formatter: '{b0} {c0}%',
                    formatter: (params: any) => {
                        if (params[0].color) {

                            // const TxtTooltip = `
                            // <span style="color:${params[0].color};">Signal</span> :  ${(params[0].data * 100).toFixed(2)}%`;

                            const TxtTooltip = `
                            <span style="color:var(--color-white); line-height:var(--font-xs);">Emotion<br/> 
Intensity</span>  ${(params[0].data * 100).toFixed(2)}%`;

                            return TxtTooltip;
                        } else {
                            return "";
                        }
                    },
                },
                xAxis: {
                    show: true,
                    type: 'category',
                    boundaryGap: false,
                    data: signal[0].data,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        // show: false
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            // opacity: 0.1,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            opacity: 0.1,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: signal[0]?.data,
                        color: "#6C5CE7",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 2
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: visualimpact[0]?.data,
                        color: "#FAAD14",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 2,
                            color: '#FAAD14',
                            type: [2, 3],
                            cap: 'round',
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            });

            return optionLine.value;
        });

        const expressionAverage = computed(() => {
            const { expression } = store.getters['advertise/REQUEST_EXPRESSION_DATA'];
            const expressionPercent = (expression * 100);
            return expression ? isInteger(expressionPercent.toFixed(1))
                ? `${expressionPercent.toFixed(0)}%`
                : `${expressionPercent.toFixed(1)}%`
                : 'N/A'
        });

        const fetch = async () => {

            vdoScenesData.value = await store.getters['advertise/VDO_SCENE'];
            const option = store.getters['advertise/VDO_EMOTION'];

            const sceneSortings = [...vdoScenesData.value];

            sceneData.value = [];
            const itemExpression = sceneSortings.sort((a, b) =>
                (parseFloat(b.expression) - parseFloat(a.expression)));
            sceneData.value = itemExpression.slice(0, 3);

            eyeImpactData.value = [];
            const itemImpact = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)));
            eyeImpactData.value = itemImpact.slice(0, 3);

            dataEyeCatchObjectAndExpression.value = await store.getters['advertise/REQUEST_EYECATCH_OBJECTS'];

            // top attention signal scene
            const signal = option.filter((o: any) => o.name == 'signal');
            const visualimpact = option.filter((o: any) => o.name == 'eye impact');
            const label = signal[0]?.data.map((v: any, index: number) => {
                return index
            })

            if (sceneSortings.length > 0) {
                // get top attention signal score
                topAttentionSignalSceneData.sceneData = sceneSortings.sort((a, b) => (parseFloat(b.attentionsignalscore) - parseFloat(a.attentionsignalscore))).slice(0, 1);
                const { StartTime, EndTime } = topAttentionSignalSceneData.sceneData[0];
                Object.assign(optionLine.value, {
                    color: ['#6C5CE7', '#FAAD14'],
                    grid: {
                        // show: false,
                        left: '2%',
                        right: '2%',
                        bottom: '2%',
                        top: '0%',
                        containLabel: false,
                        backgroundColor: 'transparent',
                    },
                    legend: {
                        show: false,
                        padding: 0
                    },
                    aria: {
                        enabled: false,
                    },
                    tooltip: {
                        show: false,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'line',
                            label: {
                                width: 10,
                                backgroundColor: '#6C5CE7'
                            },
                            lineStyle: {
                                color: '#00CEC9',
                                width: 3,
                                type: 'dotted',
                            }
                        },
                        borderWidth: 1,
                        borderColor: 'rgba(193, 17, 21, 0.3)',
                        backgroundColor: '#2D3436',
                        borderRadius: 14,
                        padding: [5, 10],
                        textStyle: {
                            fontFamily: 'IBM Plex Light',
                            fontSize: 10,
                            color: '#FFFFFF'

                        },
                        // formatter: '{b0} {c0}%',
                        formatter: (params: any) => {
                            if (params[0].color) {

                                // const TxtTooltip = `
                                // <span style="color:${params[0].color};">Signal</span> :  ${(params[0].data * 100).toFixed(2)}%`;

                                const TxtTooltip = `
                            <span style="color:var(--color-white); line-height:var(--font-xs);">Emotion<br/> 
Intensity</span>  ${(params[0].data * 100).toFixed(2)}%`;

                                return TxtTooltip;
                            } else {
                                return "";
                            }
                        },
                    },
                    xAxis: {
                        show: true,
                        type: 'category',
                        boundaryGap: false,
                        data: label,
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            length: 2,
                            show: true,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#636E72", //e3e3e3
                                width: 1,
                                // opacity: 0.2,
                            }
                        },
                        splitLine: {
                            show: false
                        },
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '100%'],
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            length: 2,
                            show: true,
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#636E72", //e3e3e3
                                width: 1,
                                opacity: 0.1,
                            },
                        },
                        splitLine: {
                            show: false
                        },
                    },
                    series: [
                        {
                            data: signal[0]?.data,
                            color: "#6C5CE7",
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            lineStyle: {
                                width: 1,
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            silent: true,
                            markArea: {
                                itemStyle: {
                                    color: '#DFE6E9'
                                },
                                data: [
                                    [
                                        {
                                            name: 'Signal',
                                            xAxis: StartTime
                                        },
                                        {
                                            xAxis: EndTime
                                        }
                                    ]
                                ]
                            },
                            // zlevel: 0,
                            // z: 0,
                        },
                        {
                            data: visualimpact[0]?.data,
                            color: "#FAAD14",
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            silent: true,
                            lineStyle: {
                                width: 1,
                                color: '#FAAD14',
                                type: [2, 3],
                                cap: 'round',
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            // markArea: {
                            //     itemStyle: {
                            //         color: '#DFE6E9',
                            //     },
                            //     data: [
                            //         [
                            //             {
                            //                 name: 'Visual Impact',
                            //                 xAxis: StartTime
                            //             },
                            //             {
                            //                 xAxis: EndTime
                            //             }
                            //         ]
                            //     ]
                            // },
                            // zlevel: 0,
                            // z: 0,
                        }
                    ]
                });
            }
            else {
                topAttentionSignalSceneData.sceneData = []
            }

            await store.dispatch('APP_LOADING', false);
        }

        const handleModalScene = (v: boolean) => {
            isVisibleScene.value = v;
        };

        const clickSceneHandler = () => {
            isVisibleScene.value = !isVisibleScene.value;
        };

        watch(optionLine.value, (options: any) => {
            let average = 0;
            if (options.series) {
                options.series.filter(((o: any) => {
                    const signalAvg = _.mean(o.data);
                    average = (signalAvg * 100);
                }));
                signalAverage.value = average ? isInteger(average.toFixed(1))
                    ? `${average.toFixed(0)}%`
                    : `${average.toFixed(1)}%`
                    : 'N/A';
            }
        });

        watch(() => route.params.id, (id: any) => {
            if (!id) return;
            window.location.assign(`${window.location.origin}/vdo-information/${id}`);
        });

        watch(() => store.getters['advertise/VDO_SCENE'], async (value: any) => {

            const sceneSortings = [...value];

            sceneData.value = [];
            const itemExpression = sceneSortings.sort((a, b) =>
                (parseFloat(b.expression) - parseFloat(a.expression)));
            sceneData.value = itemExpression.slice(0, 3);

            eyeImpactData.value = [];
            const itemImpact = sceneSortings.sort((a, b) => (parseFloat(b.eyeimpact) - parseFloat(a.eyeimpact)));
            eyeImpactData.value = itemImpact.slice(0, 3);

            dataEyeCatchObjectAndExpression.value = await store.getters['advertise/REQUEST_EYECATCH_OBJECTS'];

            // filters update
            await fetch();
        });

        onBeforeMount(async () => {

            // console.log('onbeforeMounted')
            const payload = { adid: route.params.id };
            const sceneListData = store.getters['advertise/VDO_SCENE'];
            const { back, forward }: any = router.options.history.state;

            if (sceneListData.length == 0 || back == '/') {  // หรือมาจากหน้า dashboard 
                log({ back, forward, sceneListData: sceneListData.length })
                await store.dispatch('APP_LOADING', true);
                await store.dispatch('advertise/VDO_FRAME', payload);
                await store.dispatch('advertise/REQUEST_ADS_STAT', payload);
            } else {
                SetVDO.value = true
            }

            await fetch();
        })

        onMounted(async () => {
            // console.log('onMounted')
            window.scrollTo(0, 0);
        });

        return {
            SetVDO,
            sceneData,
            optionLine,
            optionDonut,
            labelLineData,
            eyeImpactData,
            signalAverage,
            optionLineData,
            isVisibleScene,
            optionDonutData,
            handleModalScene,
            expressionAverage,
            clickSceneHandler,
            dataAgeRangeSegmentation,
            topAttentionSignalSceneData,
            columnsAgeRangeSegmentation,
            dataEyeCatchObjectAndExpression,
            handleChangeAgeRangeSegmentation,
            columnsEyeCatchObjectAndExpression,
            handleChangeEyeCatchObjectAndExpression,
        };
    },

});