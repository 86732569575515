import { defineComponent, toRef, ref, watch, reactive, toRefs } from "vue";
import { useStore } from 'vuex';
import { key } from "@/store";
import { useRouter } from "vue-router";

// Lottie Component
import { LoadingPlayback } from "@/components/LottesAnimate";

// Lottie Json
import LottiePlaybackJson from "@/assets/lottie/loading.json";

export default defineComponent({
    name: "ModalUploaded",
    components: {
        LoadingPlayback
    },
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isUpdate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["modal:update"],
    setup(prop, { emit }) {

        const store = useStore(key);
        const router = useRouter();
        const visible = ref<boolean>(false);
        const isProgress = toRef(prop, 'isUpdate');
        const modalVisible = toRef(prop, 'isVisible');
        const state = reactive({
            updateProgress: [
                {
                    title: `Uploading item to XSight`,
                    description: "",
                    status: false
                },
                {
                    title: `Upload complete!`,
                    description: `ระบบจะใช้เวลาประมวลผลประมาณ 5-15 นาที`,
                    status: false
                }
            ]
        });

        const handleClose = () => {
            state.updateProgress[0].status = false;
            visible.value = false;
            emit("modal:update", visible.value);
        }

        const handleAddInfo = () => {

            store.commit('APP_LOADING', true);

            setTimeout(() => {
                // handleClose();
                state.updateProgress[0].status = false;
                visible.value = !visible.value;
                store.commit('APP_LOADING', false);
                router.push('/');
            }, 1000)
        }

        const handleAddVdo = () => {
            handleClose();
        }

        watch(() => modalVisible.value, (newVisible) => {
            if (!newVisible) return
            visible.value = newVisible;
            // setTimeout(() => {
            //     state.updateProgress[0].status = true;
            // }, 1000)
        });

        watch(() => isProgress.value, (newProgress) => {
            if (!newProgress) return
            state.updateProgress[0].status = newProgress;
        });

        return {
            visible,
            isProgress,
            handleClose,
            handleAddVdo,
            handleAddInfo,
            ...toRefs(state),
            LottiePlaybackJson,
        }
    }
});
