import { defineComponent } from "vue";

export default defineComponent({
    name: "BaseTitle",
    props: {
        name: {
            type: String,
            required: true
        }
    }
});
