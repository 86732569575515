import { IUser } from '@/interfaces/authen.interface'

export const types = {
    MUTATION: 'mutation',
    GET_USER_INFO: 'GET_USER_INFO',
};

import {
    IContext,
    IMutations,
    IAdsDetailPayloadTypes
} from "@/interfaces/advertise.interface";

import AdvertiseService from "@/services/authen.service";

const authService = new AdvertiseService();

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,
        user: [] as IUser[],
    },
    getters: {
        [types.GET_USER_INFO]: (state: IMutations) => state.user,
    },
    mutations: {
        [types.GET_USER_INFO](state: IMutations, payload: string): void {
            state.user = payload
        },
    },
    actions: {
        async [types.GET_USER_INFO]({ commit }: IContext, payload: IAdsDetailPayloadTypes) {

            const response: any = await authService.getInfo(payload);

            if (response && response?.data?.result == 'complete') {
                commit("GET_USER_INFO", response?.data?.profileinfo);
                return response?.data?.profileinfo;
            }
        },
    },
};