import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-308f7574"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "name" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: "icon",
      src: _ctx.data.icon,
      alt: "icon"
    }, null, 8, _hoisted_1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.name), 1),
    _createVNode(_component_a_popover, {
      placement: "rightTop",
      overlayClassName: "top-scenes"
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.data.popup.head), 1)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", {
          innerHTML: _ctx.data.popup.body
        }, null, 8, _hoisted_3)
      ]),
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "info",
          src: _ctx.data.popup.icon,
          alt: "info"
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    })
  ], 64))
}