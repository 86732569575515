/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Import the functions you need from the SDKs you need
import { FirebaseOptions, FirebaseApp, initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import { getFirestore } from "firebase/firestore";
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    // onAuthStateChanged,
    // signOut
} from "firebase/auth";

// API and Interfaces
import AuthenService from "../services/authen.service"
import { AuthenFirebaseTypes, AuthenAPITypes } from "@/interfaces/authen.interface";

import { log } from '@/helpers'
import { store } from '../store'

// Your web app's Firebase configuration
const firebaseConfig: FirebaseOptions = {
    apiKey: `${process.env.VUE_APP_API_KEY}`,
    databaseURL: `${process.env.VUE_APP_DATABASE_URL}`,
    authDomain: `${process.env.VUE_APP_AUTH_DOMAIN}`,
    projectId: `${process.env.VUE_APP_PROJECT_ID}`,
    storageBucket: `${process.env.VUE_APP_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.VUE_APP_MESSAGING_SENDER_ID}`,
    appId: `${process.env.VUE_APP_APP_ID}`,
    measurementId: `${process.env.VUE_APP_MEASUREMENT_ID}`
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const initialize = () => {

    // UI config
    const uiConfig: object = {
        callbacks: {
            signInSuccessWithAuthResult: async (authResult: AuthenFirebaseTypes, redirectUrl?: string) => {
                const token = authResult.credential?.idToken;
                // const user = authResult.user;
                // const credential = authResult.credential;
                // const isNewUser = authResult.additionalUserInfo.isNewUser;
                // const providerId = authResult.additionalUserInfo.providerId;
                // const operationType = authResult.operationType;

                // Do something with the returned AuthResult.
                // Return type determines whether we continue the redirect
                // automatically or whether we leave that to developer to handle.
                log({ redirectUrl })

                const authService = AuthenService.getInstance(token);

                // payload to get app token
                const data: AuthenAPITypes = { gtoken: token };

                // get app token
                const { result, profileinfo } = await authService.signIn(data);

                // signin completed
                if (result && result === 'complete') {
                    const { token } = profileinfo;
                    localStorage.setItem('hashToken', token);
                    await store.dispatch("GET_TOKEN_WITH_GOOGLE_SIGNIN", profileinfo);
                    const firebaseui = document.getElementById('firebaseui-auth-container') as HTMLDivElement | null;
                    if (firebaseui != null) firebaseui.style.display = "none";
                    store.commit("APP_LOADING", true);
                    window.location.assign(`${process.env.VUE_APP_SIGNIN_SUCCESS_URI}`);
                    return true;
                }
            },
            signInFailure: (error: string) => {
                // Some unrecoverable error occurred during sign-in.
                // Return a promise when error handling is completed and FirebaseUI
                // will reset, clearing any UI. This commonly occurs for error code
                // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
                // occurs. Check below for more details on this.
                return error;
            },
            uiShown: () => {
                // The widget is rendered.
                // Hide the loader.
                const box = document.getElementById('loader') as HTMLDivElement | null;
                if (box != null) box.style.display = "none";
            }
        },
        // credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        // Query parameter name for mode.
        // queryParameterForWidgetMode: "mode",
        // Query parameter name for sign in success url.
        // queryParameterForSignInSuccessUrl: "signInSuccessUrl",
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: "popup",
        // signInSuccessUrl: `${process.env.VUE_APP_SIGNIN_SUCCESS_URI}`,
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        immediateFederatedRedirect: false,
    };

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);

    // Is there an email link sign-in?
    if (ui.isPendingRedirect()) {
        ui.start('#firebaseui-auth-container', uiConfig);
    }

}

const signInPopup = () => {
    signInWithPopup(auth, provider)
        .then(async (authResult: any) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential: any = GoogleAuthProvider.credentialFromResult(authResult);
            const token = credential?.idToken;

            const authService = AuthenService.getInstance(token);

            // payload to get app token
            const data: AuthenAPITypes = { gtoken: token };

            // get app token
            const { result, profileinfo } = await authService.signIn(data);

            // signin completed
            if (result && result === 'complete') {
                const { token } = profileinfo;
                localStorage.setItem('hashToken', token);
                await store.dispatch("GET_TOKEN_WITH_GOOGLE_SIGNIN", profileinfo);

                store.commit("APP_LOADING", true);
                window.location.assign(`${process.env.VUE_APP_SIGNIN_SUCCESS_URI}`);
                return true;
            }
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            // console.log({ errorCode })
            window.location.assign(`/login?error=${errorCode}`);
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
        });
}

const createUserWithEmail: (email: string, password: string) => Promise<object | string | void> = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const msg = userCredential.user;
            // console.log({ msg })
            return msg
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            let msg: string | "" = "";

            // console.log({ errorCode })
            // console.log({ errorMessage })

            switch (errorCode) {
                case "auth/invalid-email":
                    msg = "Invalid email";
                    break;
                case "auth/email-already-in-use":
                    msg = "Email already in use";
                    break;
                case "auth/wrong-password":
                    msg = "Incorrect password";
                    break;
                default:
                    msg = "Email or password was incorrect";
                    break;
            }

            return msg
        });
}

const signInWithEmail: (email: string, password: string) => Promise<object | string | void> = (email: string, password: string) => {

    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // console.log({ user })
            return user
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            let msg: string | "" = "";

            // console.log({ errorCode })
            // console.log({ errorMessage })

            switch (errorCode) {
                case "auth/invalid-email":
                    msg = "Invalid email";
                    break;
                case "auth/user-not-found":
                    msg = "No account with that email was found";
                    break;
                case "auth/wrong-password":
                    msg = "Incorrect password";
                    break;
                default:
                    msg = "Email or password was incorrect";
                    break;
            }

            return msg
        });
}

export {
    db,
    auth,
    provider,
    initialize,
    signInPopup,
    signInWithEmail,
    createUserWithEmail,
}