import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00df5cca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ads-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseMessage = _resolveComponent("BaseMessage")!
  const _component_AdsDetailsForm = _resolveComponent("AdsDetailsForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseMessage, {
      "is-visible": _ctx.isVisible,
      message: _ctx.message,
      type: _ctx.type
    }, null, 8, ["is-visible", "message", "type"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AdsDetailsForm, {
        "onResult:update": _ctx.handleResutUpdate,
        "onAction:copylink": _ctx.handleCopyLink,
        "onAction:deleteViewer": _ctx.handleDeleteViewer
      }, null, 8, ["onResult:update", "onAction:copylink", "onAction:deleteViewer"])
    ])
  ], 64))
}