/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRefs, toRef, reactive, computed, watch, onMounted, inject } from "vue";
import type { SelectProps } from "ant-design-vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, numeric, maxLength } from "@vuelidate/validators";
import { log } from "@/helpers"

export default defineComponent({
    name: "CreateTesterForm",
    props: {
        currentPage: {
            type: Number,
            required: false,
        },
        currentPageSize: {
            type: Number,
            required: false,
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        dataTesterEdit: {
            type: Array,
            required: false
        },
    },
    emits: ["action:submit-tester", "action:tester-items", "action:update-tester", "action:copylink", "action:delete-viewer", "action:purge"],
    setup(prop, { emit }) {

        const genderDataOptions = ref<SelectProps['options']>([
            // {
            //     value: null,
            //     label: "ระบุเพศ",
            //     disabled: true,
            // },
            {
                value: "Male",
                label: "ชาย",
            },
            {
                value: "Female",
                label: "หญิง",
            }
        ]);
        const gender: any = ref("");
        const currentOffset: any = ref(0);
        const formNumber: any = ref(1);
        const roleUser: any = inject('authUser');
        const testerData: any = ref([]);
        const isUpdateProp = toRef(prop, "isUpdate");
        const isVisibleProp = toRef(prop, "isVisible");
        const propTesterEdit = toRef(prop, "dataTesterEdit");

        const state = reactive({
            gender,
            options: genderDataOptions
        });

        const forms: any = reactive({
            tester: [
                {
                    id: formNumber.value,
                    inactive: false,
                    viewerId: null,
                    firstName: null,
                    lastName: null,
                    companyName: null,
                    email: null,
                    age: null,
                    gender: null
                }
            ]
        });

        const rules: any = computed(() => ({
            tester: {
                $each: helpers.forEach({
                    id: {},
                    inactive: {},
                    viewerId: {},
                    firstName: { required },
                    lastName: {},
                    companyName: { required, maxLength: maxLength(100) },
                    email: { required, email },
                    age: { required, numeric },
                    gender: { required },
                }),
            }
        }));

        const v$: any = useVuelidate(rules, forms);

        const handleRemoveTester = (viewer: any): void => {
            if (!viewer.viewerId) {
                // delete when add new tester
                const index = forms.tester.findIndex((item: any) => item.id === viewer.id);
                if (index > -1) {
                    forms.tester.splice(index, 1);
                    const newPage: any = prop.currentPage;
                    const newPageSize: any = prop.currentPageSize;
                    updateTesterPerPage(newPage, newPageSize);
                }
            } else {
                // delete in db
                const removePayload = { id: viewer.viewerId, name: viewer.firstName };
                emit("action:delete-viewer", removePayload);
            }
        }

        const handleAddTester = (): void => {
            formNumber.value = forms.tester.length;
            forms.tester.push({
                id: ++formNumber.value,
                inactive: false,
                viewerId: null,
                firstName: null,
                lastName: null,
                companyName: null,
                email: null,
                age: null,
                gender: null
            });
        }

        const updateTesterPerPage = (page: number, pageSize: number): void => {
            const allTester: any = forms.tester;

            // offset item per page
            currentOffset.value = pageSize * (page - 1);

            testerData.value = allTester.slice(
                currentOffset.value,
                pageSize * page
            );

            emit("action:tester-items", testerData.value);

        }

        const handleUpdate = async (): Promise<void> => {

            const testData: any = [];

            if (!propTesterEdit.value) return;

            formNumber.value = 0;

            if (propTesterEdit.value.length > 0) {
                propTesterEdit.value.map(async (o: any) => {
                    // console.log({ id: o._id, profile: o.profile });
                    const { name, email, company, age, gender } = o.profile;

                    testData.push({
                        id: ++formNumber.value,
                        inactive: o.inactive,
                        viewerId: o._id,
                        firstName: name.trim().toString(),
                        lastName: null,
                        companyName: company.trim().toString(),
                        email: email.trim().toString(),
                        age: parseInt(age),
                        gender: gender.toString(),
                    });
                    return testData;
                });
            }

            if (testData.length > 0) {
                forms.tester = testData;
                log({ testData: forms.tester })
            }

        }

        const handleCopyLink = (viewer: any): void => {
            emit("action:copylink", viewer);
        };

        const handlePurgeTester = (viewer: any): void => {
            const purgePayload = { id: viewer.viewerId, name: viewer.firstName };
            emit("action:purge", purgePayload);
        }

        watch(() => ([prop.currentPage, prop.currentPageSize]), ([page, pageSize]) => {
            const newPage: any = page;
            const newPageSize: any = pageSize;
            updateTesterPerPage(newPage, newPageSize);
        });

        watch(() => ([isUpdateProp.value, isVisibleProp.value]), ([visible, update]) => {
            if (visible && update) {
                formNumber.value = 1;
                Object.assign(forms, {
                    tester: [
                        {
                            id: formNumber.value,
                            inactive: false,
                            viewerId: null,
                            firstName: null,
                            lastName: null,
                            companyName: null,
                            email: null,
                            age: null,
                            gender: null
                        }
                    ]
                });
            }
        });

        watch(v$, (v) => {

            const newPage: any = prop.currentPage;
            const newPageSize: any = prop.currentPageSize;
            updateTesterPerPage(newPage, newPageSize);

            const data = {
                invalid: v.$invalid,
                tester: v.tester.$model
            }

            log("data", data);

            emit("action:submit-tester", data);
        });

        watch(() => (propTesterEdit.value), (update) => {
            if (!update) return;

            if (update.length > 0) {
                handleUpdate();
            }
            else {
                log({ propTesterEdit: update.length });
                formNumber.value = 1;
                Object.assign(forms, {
                    tester: [
                        {
                            id: formNumber.value,
                            inactive: false,
                            viewerId: null,
                            firstName: null,
                            lastName: null,
                            companyName: null,
                            email: null,
                            age: null,
                            gender: null
                        }
                    ]
                });
            }

        });

        onMounted(() => testerData.value = forms.tester);

        return {
            v$,
            forms,
            roleUser,
            testerData,
            propTesterEdit,
            handleCopyLink,
            handleAddTester,
            ...toRefs(state),
            handleRemoveTester,
            handlePurgeTester
        };
    },
});
