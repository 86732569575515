import { defineComponent } from "vue";

export default defineComponent({
    name: "BasePopup",
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {
                const data = {
                    head: 'Important Elements',
                    body: [
                        `XSight ใช้ AI ในการมองหาวัตถุที่อยู่ในวิดีโอเพื่อ
                      ทำการนำมาวิเคราะห์แยกตามประเภทของ Object และ Scenes`,
                        `คุณสามารถคลิกที่แต่ละ Object เพื่อดูรายละเอียดและ ตำแหน่งของ Object
                      นั้นๆ ใน Scenes ต่างๆ ได้`
                    ],
                    icon: require('@/assets/images/icons/icon-plus.png'),
                }
                return data;
            }
        }
    }
});
