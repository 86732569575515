import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2993a5b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "package-graph-legend" }
const _hoisted_2 = { class: "legend-groups" }
const _hoisted_3 = { class: "legend-group" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DashboardConfig.filter(
                        (item) => item.name == 'eye impact'
                    ), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "legend-bt",
            key: item.name
          }, [
            _createElementVNode("div", {
              class: "legend-bt-content",
              onClick: _withModifiers(($event: any) => (_ctx.ToggleSerie(item)), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["legend-ico", [{ active: item.Isshow }, item.name]])
              }, null, 2),
              _createTextVNode(" Visual Impact ")
            ], 8, _hoisted_4)
          ]))
        }), 128))
      ])
    ])
  ]))
}