/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, watch, reactive, toRefs, toRef, computed } from "vue";
import { useStore } from 'vuex';
import { key } from "@/store";
import { useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";
// import { url, helpers } from "@vuelidate/validators";
import { getVideoID, imgPreview, vdoLinkValid } from "@/helpers";

import { AnyObjectTypes, StateUploadTypes } from "@/interfaces/brand.interface";

export default defineComponent({
    name: "CreateProjectUpload",
    props: {
        indicator: {
            type: Object
        },
        isVdoLink: {
            type: Boolean
        },
        isUploadFile: {
            type: Boolean
        },
        isPreload: {
            type: Boolean
        },
        previewVdo: {
            type: String
        },
        fileInfo: {
            type: String
        },
        dataEdit: {
            type: Object
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
        isVisible: {
            type: Boolean,
            default: false
        },
    },
    emits: ["action:upload", "action:download", "action:copylink"],
    setup(prop, { emit }) {

        const store = useStore(key);
        const router = useRouter();
        const uploadFile: AnyObjectTypes = ref(null);
        const maxSizeFileUpload = ref(500); // 500mb
        const fileUploadTypes = ref(['.MP4', '.MPEG4']);
        const uploadStatus = ref<boolean>(true);
        const previewImage = ref<any>('');
        const propdataEdit = toRef(prop, "dataEdit");
        const isUpdateProp = toRef(prop, "isUpdate");
        const isVisibleProp = toRef(prop, "isVisible");

        // state
        const state: StateUploadTypes = reactive({
            vdoUploadTemp: "",
            vdoLink: "",
            errorMessage: {
                type: `ระบบไม่รองรับไฟล์ที่คุณอัปโหลดเข้ามา ไฟล์ที่ระบบรองรับคือ ${fileUploadTypes.value.join(' / ')}`,
                size: `ระบบไม่รองรับไฟล์ที่มี่ขนาดไฟล์เกิน ${maxSizeFileUpload.value} MB`,
            },
            alertMessage: ""
        });

        const rules = computed(() => ({
            vdoUploadTemp: {},
            vdoLink: { urlx: vdoLinkValid },
        }));

        const v$ = useVuelidate(rules, state);

        const handleBrowse = (evt: AnyObjectTypes) => {
            evt.preventDefault();
            uploadFile.value.click();
        };

        const handleRemoveFile = () => {
            state.vdoUploadTemp = "";
            state.vdoLink = "";
            store.commit("brands/UPLOAD_FILE", "");
            emit("action:upload", true);
        };

        const handleChange = (evt: AnyObjectTypes) => {
            if (!evt.target.files) return;
            onChangeUploadFile(evt);
            evt.target.value = "";
        };

        const onChangeUploadFile = (evt: AnyObjectTypes) => {
            evt.preventDefault();

            const files = evt.target.files;
            const size = files[0].size / 1024 / 1024;
            const type = files[0].name.toString().toLowerCase().split('.').pop();

            if (size > maxSizeFileUpload.value) { // max size file upload
                uploadStatus.value = !uploadStatus.value;
                state.alertMessage = state.errorMessage.size;
                emit("action:upload", true);
                return;
            } else if (!fileUploadTypes.value.some((o: any) => o.toLowerCase().includes(type))) { // wrong file type
                uploadStatus.value = !uploadStatus.value;
                state.alertMessage = state.errorMessage.type;
                emit("action:upload", true);
                return;
            }

            processFile(files[0]);
        };

        const processFile = (file: any) => {
            state.vdoUploadTemp = file;
            handleUpload();
        }

        const handleUpload = async () => {

            // app preload
            store.commit("APP_LOADING", true);

            // upload preload
            store.commit('brands/PRELOAD_FILE', true);

            let fileData: any = "";
            const timeout = 500;
            const ytdl: any = getVideoID(state.vdoLink);

            // get vdo thumbnail by file type
            if (typeof (state.vdoUploadTemp) === 'object') {
                // File select
                fileData = state.vdoUploadTemp;
                previewImage.value = URL.createObjectURL(fileData);
            } else if (ytdl.status) {
                // Youtube 
                const ytid: any = getVideoID(state.vdoLink);
                fileData = state.vdoLink;
                previewImage.value = ytid.status ? `https://img.youtube.com/vi/${ytid.id}/0.jpg` : '';
            } else {
                // MP4
                fileData = state.vdoLink;
                const poster = await store.dispatch('brands/GET_VDO_POSTER', { url: state.vdoLink });
                previewImage.value = imgPreview(poster);
            }

            const payload = { upload: fileData, preview: previewImage.value };
            store.commit("brands/UPLOAD_FILE", payload);
            store.commit("APP_LOADING", false);
            emit("action:upload", false);

            setTimeout(() => {
                router.push('/create-ads');
            }, timeout);

        }

        const handleDownloadFile = () => {
            store.commit("APP_LOADING", true);
            emit("action:download");
        }

        const handleCopyLink = () => {
            emit("action:copylink");
        };

        // watch vdoLink
        watch(() => state.vdoLink, (ytlink) => {
            if (ytlink.length == 0) return;
            const data = vdoLinkValid(ytlink);
            if (data) handleUpload();
        });

        // watch uploadStatus
        watch(() => uploadStatus.value, (upload) => {
            if (!upload) {
                setTimeout(() => {
                    uploadStatus.value = true;
                }, 8000)
            }
        });

        // watch dataEdit
        watch(() => propdataEdit.value, (update) => {
            // update ads
            if (update) {
                emit("action:upload", false);
            }
        });

        watch(() => ([isUpdateProp.value, isVisibleProp.value]), ([visible, update]) => {
            if (visible && update) {
                Object.assign(state, {
                    vdoUploadTemp: "",
                    vdoLink: "",
                });
            }
        });

        // watch validate
        watch(v$, (v) => {
            emit("action:upload", v.$invalid);
        });

        return {
            v$,
            uploadFile,
            propdataEdit,
            uploadStatus,
            handleUpload,
            handleBrowse,
            handleChange,
            previewImage,
            handleCopyLink,
            ...toRefs(state),
            handleRemoveFile,
            maxSizeFileUpload,
            handleDownloadFile
        }
    }
});
