const baseHostUrl = process.env.baseURL
const backendHostUrl = process.env.VUE_APP_AUTHEN_API
const backendDataUrl = process.env.VUE_APP_DATA_SERVICES_API
const backendGCPUrl = process.env.VUE_APP_BASE_API
const backendHeader = process.env.VUE_APP_TOKEN_HEADER_KEY
const backendGCPVdo = process.env.VUE_APP_VIDEO_API

export {
    baseHostUrl,
    backendHostUrl,
    backendDataUrl,
    backendGCPUrl,
    backendHeader,
    backendGCPVdo
};