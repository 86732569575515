import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/interface/arrow.png'


const _withScopeId = n => (_pushScopeId("data-v-3b0f68ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "top-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_logo = _resolveComponent("base-logo")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_list = _resolveComponent("base-list")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_openBlock(), _createBlock(_component_a_layout_sider, {
    class: _normalizeClass(["main-sidebar", { collapse: _ctx.isSidebarExpand }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["sidebar-content", { collapse: _ctx.isSidebarExpand }])
      }, [
        _createVNode(_component_base_logo, {
          data: _ctx.logoData,
          "is-expand": _ctx.isSidebarExpand
        }, null, 8, ["data", "is-expand"]),
        (_ctx.roleUser && _ctx.isCreateProject)
          ? (_openBlock(), _createBlock(_component_base_button, {
              key: 0,
              data: _ctx.buttonData,
              "is-expand": _ctx.isSidebarExpand,
              onClick: _withModifiers(_ctx.handleModal, ["prevent"])
            }, null, 8, ["data", "is-expand", "onClick"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1)),
        _createVNode(_component_base_list, {
          data: _ctx.sidebarMenu,
          "is-expand": _ctx.isSidebarExpand
        }, null, 8, ["data", "is-expand"]),
        _createVNode(_component_base_list, {
          data: _ctx.settingData,
          "is-expand": _ctx.isSidebarExpand,
          align: "bottom"
        }, null, 8, ["data", "is-expand"]),
        _createElementVNode("div", {
          class: _normalizeClass(["expand", { collapse: _ctx.isSidebarExpand }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSidebarExpand && _ctx.handleSidebarExpand(...args)), ["prevent"]))
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: _normalizeClass({ collapse: _ctx.isSidebarExpand })
          }, null, 2)
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}