/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRef, watch, inject, computed } from "vue";
import VChart from "vue-echarts";
import { log, isInteger, percentConvert, chunkArray } from "@/helpers";
// import { ElementCropImageData } from "@/interfaces/component.interface"

export default defineComponent({
  name: "wiget-package",
  props: ["sceneData", "dataPackages", "dataChart"],
  components: {
    VChart
  },
  setup(prop) {
    const activeCurrentTime = ref(true);
    const ViewerListPaging = ref(<any[]>[]);
    const ScenePerPage = ref<number>(9);
    const propsSceneData = toRef(prop, "sceneData");
    const page: any = ref(1);
    const dataViewers: any = ref({
      current: 1,
      data: [],
      total: 0,
      pageSize: 0,
    });

    const SummaryChart: any = inject("SummaryChart");
    const SummaryData: any = inject("AdsInfo");

    const SummaryDataAttentionSignals: any = computed(() =>
      SummaryData.value.dataExpression.find(
        (expression: any) => expression.title == "Attention Signals Score"
      )
    );

    const SummaryDataEmotionIntensity: any = computed(() =>
      SummaryData.value.dataExpression.find(
        (expression: any) => expression.title == "Emotion Intensity"
      )
    );

    const SummaryDataVisualImpact: any = computed(() =>
      SummaryData.value.dataExpression.find(
        (expression: any) => expression.title == "Visual Impact"
      )
    );

    const ShowCurrentScene = ref<boolean>(false);

    // const dataPackages = ref<Array<ElementCropImageData>>([
    //   {
    //     name: "Package A",
    //     alt: "Package A",
    //     src: require("@/assets/images/package/image-est.png"),
    //     width: '100%', // % and px
    //     height: 'auto', // % and px
    //     padding: '0',
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 28.7,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Coca Cola",
    //     alt: "Coca Cola",
    //     src: require("@/assets/images/package/image-coca-cola.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 20.3,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Pepsi",
    //     alt: "Pepsi",
    //     src: require("@/assets/images/package/image-pepsi.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 11.2,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Schweppes",
    //     alt: "Schweppes",
    //     src: require("@/assets/images/package/image-schweppes.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 10.1,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Package C",
    //     alt: "Package C",
    //     src: require("@/assets/images/package/image-est-big-02.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 8.6,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Fanta",
    //     alt: "Fanta",
    //     src: require("@/assets/images/package/image-fanta.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 7.1,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Package B",
    //     alt: "Package B",
    //     src: require("@/assets/images/package/image-est-bottle-01.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 7.02,
    //     resolution: 'thumbnails'
    //   },
    //   {
    //     name: "Sprite",
    //     alt: "Sprite",
    //     src: require("@/assets/images/package/image-sprite.png"),
    //     crop: { top: 'center', left: 'center', width: '31px', height: '31px' },
    //     percentage: 6.98,
    //     resolution: 'thumbnails'
    //   },
    // ]);

    const handleTabClick = (NewState = null) => {
      if (propsSceneData.value.CurrentTime) {
        if (NewState) {
          activeCurrentTime.value = false;
          ShowCurrentScene.value = NewState;
        } else {
          ShowCurrentScene.value = !ShowCurrentScene.value;
        }
      } else {
        ShowCurrentScene.value = false;
      }
    };

    watch(
      () => propsSceneData.value,
      (scene) => {
        const { Participants } = scene;

        if (Participants) {
          // log({ Participants: Participants })
          if (Participants.length < ScenePerPage.value) {
            page.value = 1;
          }
          // participants
          const dataParticipants = chunkArray(Participants, ScenePerPage.value);
          ViewerListPaging.value = Participants;
          Object.assign(dataViewers.value, {
            current: 1,
            data: dataParticipants,
            total: Participants.length,
            pageSize: dataParticipants.length,
          });
        } else {
          const dataParticipants = [];
          page.value = 1;
          ViewerListPaging.value = [];
          Object.assign(dataViewers.value, {
            current: 1,
            data: ViewerListPaging.value,
            total: dataParticipants.length,
            pageSize: dataParticipants.length,
          });
        }
        // log({ ViewerListPaging: ViewerListPaging.value })
        // log({ dataViewers: dataViewers.value })
      }
    );

    return {
      page,
      dataViewers,
      propsSceneData,
      percentConvert,
      ViewerListPaging,
      ShowCurrentScene,
      activeCurrentTime,
      handleTabClick,
      SummaryChart,
      SummaryData,
      SummaryDataAttentionSignals,
      SummaryDataEmotionIntensity,
      SummaryDataVisualImpact,
      // dataPackages,
    };
  },
});
