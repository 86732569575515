import { defineComponent, ref, toRef, computed, watch } from "vue";

export default defineComponent({
    name: "ParticipantsComponent",
    props: {
        data: { type: Object },
        isLoad: { type: Boolean },
    },
    emits: ["action:toggle"],
    setup(props, { emit }) {
        const loading: any = ref(false);
        const propsParticipant: any = toRef(props, 'data');
        const propsLoad: any = toRef(props, 'isLoad');

        const handleSelecteParticipant = (id: number) => {
            emit("action:toggle", id);
        }

        watch(() => propsLoad.value, (v) => {
            // console.log({ propsLoad: v })
            loading.value = v;
        })

        const prograss = computed(() => {
            // console.log({ propsParticipant: propsLoad.value })
            const x: any = propsParticipant.value.length > 0 ? true : false;
            return x;
        });

        return {
            loading,
            prograss,
            propsLoad,
            propsParticipant,
            handleSelecteParticipant
        }
    }
})