/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, onMounted, watch, toRef } from "vue";

import { HeatMapPoint } from "@/interfaces/dashboard.interface";
import { iHeatmapdata } from "@/interfaces/advertise.interface";
import SceneHeatmap from "@/components/Scene-heatmap/Scene-heatmap.vue";

export default defineComponent({
    name: "Image-animate",
    props: {
        imageList: {
            type: Array,
            required: true,
        },
        eyeListData: {
            type: Array,
            required: false,
        },
        eyeImpactShow: {
            type: Boolean
        },
        objectCoordinate: {
            type: Array
        },
        frameSmall: {
            type: Boolean,
            required: false,
            default: false
        },
        HeatMapData: {
            type: Array,
            required: false,
        },
        vdoWidth: {
            type: Number,
            default: 1920,
        },
        vdoHeight: {
            type: Number,
            default: 1080,
        },
    },
    components: {
        SceneHeatmap,
    },
    setup(prop) {

        const timer = ref<number>();
        const showIndex = ref<number>(0);
        const isHover = ref<boolean>(false);
        const coordinateStyle = ref<any>(null);
        const objectFilter = ref<any | null>();
        const showImage = ref<any | null>(null);
        const showFrame = ref<any | null>(null);
        const imageListProp = toRef(prop, 'imageList');
        const eyeListDataProp = toRef(prop, 'eyeListData');
        const eyeImpactShowProp = toRef(prop, 'eyeImpactShow');
        const objectCoordinateProp = toRef(prop, 'objectCoordinate');
        const HeatMapDataProp = toRef(prop, "HeatMapData");

        const HeatMapList = ref<HeatMapPoint[]>();

        const handleImageChange = (nextImage = false) => {

            HeatMapList.value = [];

            if (nextImage == true) {
                ++showIndex.value;
            }

            if (showIndex.value > imageListProp.value.length) {
                showIndex.value = 0;
            }

            if (typeof imageListProp.value[showIndex.value] !== 'undefined') {

                showImage.value = imageListProp.value[showIndex.value];

                if (eyeListDataProp.value && eyeImpactShowProp.value) {
                    // console.log({ length: imageListProp.value.length, showIndex: showIndex.value, imageList: imageListProp.value[showIndex.value] });

                    showFrame.value = eyeListDataProp.value[showIndex.value];

                    coordinateStyle.value = {
                        left: `${showFrame.value.left}%`,
                        top: `${showFrame.value.top}%`,
                        width: `${showFrame.value.width}%`,
                        height: `${showFrame.value.height}%`,
                    };

                    if (objectCoordinateProp.value) {
                        objectFilter.value = objectCoordinateProp.value[showIndex.value];
                    } else {
                        objectFilter.value = null;
                    }

                    if (HeatMapDataProp.value) {
                        const HeatMapArray = <iHeatmapdata[]>(
                            HeatMapDataProp.value[showIndex.value]
                        );
                        HeatMapList.value = [];


                        const CompilePercent = 1000 / prop.vdoWidth;

                        HeatMapArray.forEach((point) => {
                            const PointData = {
                                x: Math.ceil(
                                    ((point.left * prop.vdoWidth) / 100) * CompilePercent
                                ),
                                y: Math.ceil(
                                    ((point.top * prop.vdoHeight) / 100) * CompilePercent
                                ),
                                value: point.value * 70,
                            };
                            HeatMapList.value?.push(PointData);
                        });
                    }

                }

            }

        }

        watch(isHover, (newValue) => {

            if (newValue) {
                timer.value = setInterval(() => {
                    handleImageChange(true);
                }, 300);
            } else {
                showIndex.value = 0;
                clearInterval(timer.value);
            }

        });

        watch(() => showIndex.value, () => {
            handleImageChange();
        });

        watch(() => eyeImpactShowProp.value, () => {
            handleImageChange();
        });

        watch(() => objectCoordinateProp.value, () => {
            handleImageChange();
        });

        onMounted(() => {
            handleImageChange();
        });

        return {
            isHover,
            showImage,
            showFrame,
            objectFilter,
            coordinateStyle,
            eyeImpactShowProp,
            HeatMapList
        }
    }
});
