import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7da23e50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.propsList)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(["sidebar-list", { bottom: _ctx.propsAlign, collapse: _ctx.propsIsExpand }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propsList, ({ name, icon, path, isEnable }, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["list-item", {
				bottom: _ctx.propsAlign,
				collapse: _ctx.propsIsExpand,
				detail: _ctx.propsClassName,
			}]),
            key: index
          }, [
            (path?.toLowerCase() === '/')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "",
                  class: _normalizeClass({
					active:
						!name.toLowerCase().includes('back') &&
						!_ctx.$route.fullPath?.toLowerCase().includes('update') &&
						!_ctx.$route.fullPath?.toLowerCase().includes('create'),
				}),
                  onClick: _ctx.backwardToHome
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["icon", icon])
                    }, null, 2),
                    (!_ctx.propsIsExpand)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(name), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              : isEnable
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: `${path}`,
                    class: _normalizeClass({
					active:
						!name.toLowerCase().includes('back') &&
						!name.toLowerCase().includes('logout') &&
						!_ctx.$route.fullPath?.toLowerCase().includes('update') &&
						!_ctx.$route.fullPath?.toLowerCase().includes('create'),
				}),
                    onClick: ($event: any) => (path?.toLowerCase().includes('logout') ? _ctx.handleLogout() : false)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["icon", icon])
                      }, null, 2),
                      (!_ctx.propsIsExpand)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(name), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["to", "class", "onClick"]))
                : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}