import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

// Helpers and Package
import { uuid } from "../helpers"
import { UAParser } from "ua-parser-js";

// Config API endpoint
import { baseHostUrl, backendHeader } from "@/config"

// Interface
import { LoggerTypes } from '../interfaces/logger.interface'
import ResponseData from "@/interfaces/response.interface";

export abstract class HTTPBaseService {
    protected instance: AxiosInstance;
    protected token?: string;
    protected readonly baseURL: string;

    public constructor(baseURL: string, token?: string) {
        this.baseURL = baseURL;
        this.instance = axios.create({
            baseURL,
            timeout: 100000,
            headers: {
                'content-Type': 'application/json; charset=utf-8',
                // 'authorization': `Bearer ${token}`
            }
        });

        if (token) {
            this.token = token;
        }

        this.initializeRequestInterceptor();
        this.initializeResponseInterceptor();
    }

    private initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this.handleRequest);
    };

    private initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use((response: ResponseData) => {
            // const { headers } = response.config
            // if (headers && headers.authorization) {
            //     const responseToken = (headers.authorization as string).split(' ')[1];
            //     this.token = responseToken;

            //     localStorage.setItem('hashToken', this.token);
            // }
            // console.log(response.data)
            return response;
        }, this.handleError);
    }

    private handleRequest = (config: AxiosRequestConfig) => {
        // console.log(config?.headers?.authorization);
        if (!config?.headers) {
            throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
        }

        const parser = new UAParser();
        // console.log(parser.getResult())

        const deviceAccess = parser.getBrowser()?.name
        const deviceModel = parser.getDevice()?.model;
        const deviceOS = parser.getOS()?.name;
        const deviceVersion = parser.getOS()?.version;
        const appToken = localStorage.getItem('hashToken');

        config.headers[`${backendHeader}`] = `${appToken}`;

        if (appToken) {
            const Mytoken = {
                token: appToken
            };

            config.data = {
                ...config.data,
                ...Mytoken
            };
        }

        // log data
        const logData: LoggerTypes = {
            access: deviceAccess,
            appversion: process.env.VUE_APP_VERSION,
            os: `${deviceOS} ${deviceVersion}`,
            device: deviceModel,
            deviceid: uuid()
        }

        // console.log(this.baseURL.includes('apiauthen'))
        if (this.baseURL.includes('apiauthen')) {
            config.data = {
                ...config.data,
                ...logData,
            };
        }

        return config;
    };

    private handleError = async (error: AxiosError) => {
        if (error.response?.status === 401) {
            window.location.href = `${baseHostUrl}/login`
        }
    }
}