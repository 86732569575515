/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref } from "vue";

import { log, SecondToMinute } from '@/helpers'


export default defineComponent({
    name: "video-sceneselect",
    emits: ["selectScene"],
    props: ['SceneList'],
    components: {
    },
    setup(props, { emit }) {

        const SceneListPaging = ref();
        const ScenePerPage = ref<number>(15)

        const chunkArray = (arr: any, size: number) => {
            return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
        };

        const selectScene = (SceneItem: any) => {
            emit('selectScene', SceneItem.StartTime)
        }

        onMounted(() => {
            SceneListPaging.value = chunkArray(props.SceneList, ScenePerPage.value)
            log(SceneListPaging.value);
        });

        return {
            selectScene,
            SecondToMinute,
            SceneListPaging,
        };
    },
});
