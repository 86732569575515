import { defineComponent, ref, computed } from "vue";
import { SecondToMinute, log, isInteger } from "@/helpers";
import _ from "lodash";

import VChart from "vue-echarts";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Scrollbar, Mousewheel } from "swiper";

export default defineComponent({
    name: "TopDesignScore",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        VChart,
        Swiper,
        SwiperSlide,
    },
    emits: ["action:linkto", "click:modal", 'switch:heatmap', 'switch:eyecontact'],
    setup(prop, { emit }) {
        const lineChart = ref();
        const allScenseData: any = computed(() => prop.data.TopDesignScore);
        const allScenseDataNum: any = computed(() => window.innerWidth > 1520 ? 4 : 3);
        const sorttype: any = computed(() => prop.data.sorttype.value);
        const mergeAllScenes: any = ref([]);
        const playScene: any = ref(null);
        const switchHeatmap = ref(false);
        const switchEyecontact = ref(false);
        const percentConvert = (percent: any) => {
            const number = parseFloat(percent);
            const numberConvert = isInteger(number.toFixed(1))
                ? number.toFixed(0)
                : number.toFixed(1);
            return parseFloat(numberConvert);
        };
        const max = ref(6);

        const PinPosition = (peakScenesObj: any) => {
            let PlayingTime = 0;
            let TotalWidth = 0;

            if (sorttype.value === "expression") {
                PlayingTime = peakScenesObj.playingTime;
            } else {
                PlayingTime = peakScenesObj.playingTimeEyeImpact;
            }

            const TotalPrev = peakScenesObj.prev.length;
            const TotalPeak = peakScenesObj.peak.length;
            const TotalNext = peakScenesObj.next.length;

            if (PlayingTime == 0) {
                TotalWidth = 0;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPrev) {
                // console.log(PlayingTime);
                // console.log(PlayingTime);
                // console.log("plus  IN TotalPrev");
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPrev) {
                // console.log("plus  ALL TotalPrev");
                TotalWidth += TotalPrev * 17.88488382564841;

                PlayingTime -= TotalPrev;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPeak) {
                TotalWidth += (PlayingTime * 28) / TotalPeak;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPeak) {
                TotalWidth += 28.46046469740634;
                PlayingTime -= TotalPeak;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalNext) {
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalNext) {
                TotalWidth += TotalNext * 17.88488382564841;
                PlayingTime -= TotalNext;
            }

            return {
                width: `${TotalWidth}%`,
            };
        };

        const getMaxEmotions = (object: any, current: any) => {
            const { expression, eyeimpact, eyeattention } = object;
            const allEmotions = [
                parseFloat(expression),
                parseFloat(eyeimpact),
                parseFloat(eyeattention),
            ];
            const numebr = parseFloat(current);
            const maxPercentage = _.maxBy(allEmotions);
            return maxPercentage === numebr ? true : false;
        };

        const progressTimeline = (
            index: number,
            time: number,
            totalSec: any,
            sceneObj: any
        ) => {
            setTimeout(() => {
                ++time;

                if (
                    time <= totalSec &&
                    allScenseData.value?.[index]?.peakScenesObj &&
                    (allScenseData.value?.[index]?.peakScenesObj?.playingVdo ||
                        allScenseData.value?.[index].peakScenesObj?.playingVdoEyeImpact)
                ) {
                    const percentage = Math.round((time * 100) / totalSec);
                    log({ time, totalSec, percentage });

                    sorttype.value === "expression"
                        ? (allScenseData.value[index].peakScenesObj.playingTime = time)
                        : (allScenseData.value[index].peakScenesObj.playingTimeEyeImpact =
                            time);

                    progressTimeline(index, time, totalSec, sceneObj);
                } else if (
                    allScenseData.value?.[index]?.peakScenesObj &&
                    (allScenseData.value?.[index]?.peakScenesObj?.playingVdo ||
                        allScenseData.value?.[index].peakScenesObj?.playingVdoEyeImpact)
                ) {
                    if (sorttype.value === "expression") {
                        allScenseData.value[index].peakScenesObj.playingVdo =
                            !allScenseData.value[index].peakScenesObj.playingVdo;
                    } else {
                        allScenseData.value[index].peakScenesObj.playingVdoEyeImpact =
                            !allScenseData.value[index].peakScenesObj.playingVdoEyeImpact;
                    }

                    sorttype.value === "expression"
                        ? (allScenseData.value[index].peakScenesObj.playingTime = 0)
                        : (allScenseData.value[index].peakScenesObj.playingTimeEyeImpact = 0);
                }
            }, 1000);
        };

        const handlePlay = (index: number, sceneObj: any) => {
            const { prev, peak, next, totalSec, playingTime, playingTimeEyeImpact } =
                sceneObj; // get scenes by 8 sec.

            const resetTime = 0;
            const time =
                sorttype.value === "expression"
                    ? parseInt(playingTime)
                    : parseInt(playingTimeEyeImpact);
            const totalTime = parseInt(totalSec) || 0;

            if (sorttype.value === "expression") {
                allScenseData.value[index].peakScenesObj.playingVdo =
                    !allScenseData.value[index].peakScenesObj.playingVdo;
            } else {
                allScenseData.value[index].peakScenesObj.playingVdoEyeImpact =
                    !allScenseData.value[index].peakScenesObj.playingVdoEyeImpact;
            }

            log("playingVdo", allScenseData.value[index].peakScenesObj.playingVdo);
            log(
                "playingVdoEyeImpact",
                allScenseData.value[index].peakScenesObj.playingVdoEyeImpact
            );

            if (
                allScenseData.value?.[index]?.peakScenesObj &&
                (allScenseData.value?.[index]?.peakScenesObj?.playingVdo ||
                    allScenseData.value?.[index].peakScenesObj?.playingVdoEyeImpact)
            ) {
                mergeAllScenes.value[index] = [...prev, ...peak, ...next];
                progressTimeline(index, time, totalTime, sceneObj);
            } else {
                // check pause and replay
                if (time == totalSec) {
                    log({ time, totalSec });
                    mergeAllScenes.value[index] = [];
                    sorttype.value === "expression"
                        ? (allScenseData.value[index].peakScenesObj.playingTime = resetTime)
                        : (allScenseData.value[index].peakScenesObj.playingTimeEyeImpact =
                            resetTime);
                } else {
                    sorttype.value === "expression"
                        ? (allScenseData.value[index].peakScenesObj.playingTime = time)
                        : (allScenseData.value[index].peakScenesObj.playingTimeEyeImpact =
                            time);
                }
            }
        };

        const switchHeatmapHandler = (id: any) => {
            switchHeatmap.value = !allScenseData.value?.[id]?.isSwithHeatmap;
            emit("switch:heatmap", { isChecked: switchHeatmap.value, idChecked: id });
            // console.log({ isChecked: switchHeatmap.value, idChecked: id })
        }

        const switchEyecontactHandler = (id: any) => {
            switchEyecontact.value = !allScenseData.value?.[id]?.isSwithEyeContact;
            emit("switch:eyecontact", { isChecked: switchEyecontact.value, idChecked: id });
            // console.log({ isChecked: switchEyecontact.value, idChecked: id })
        }

        const clickSceneHandler = (id: number, type: string, list: any, detail: any) => {
            const status = id ? true : false;
            // console.log({ list, detail })
            emit("click:modal", { status, type, list, detail });
        };

        const handlerExpand = (rang: any) => {
            // console.log("expand")
            if (max.value === rang.length) {
                max.value = 6
            } else {
                max.value = rang.length
            }
        }

        return {
            sorttype,
            lineChart,
            allScenseData,
            playScene,
            handlePlay,
            SecondToMinute,
            percentConvert,
            getMaxEmotions,
            mergeAllScenes,
            PinPosition,
            switchHeatmapHandler,
            switchEyecontactHandler,
            modules: [Pagination, Navigation, Scrollbar, Mousewheel],
            clickSceneHandler,
            handlerExpand,
            max,
            windows: window.innerWidth,
            allScenseDataNum
        };
    },
});
