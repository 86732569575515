import { defineComponent } from 'vue'

export default defineComponent({
    name: "OutstandingHead",
    props: {
        data: {
            type: Object,
            required: true
        }
    }
})