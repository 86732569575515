// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defineComponent, reactive, computed, toRefs, onMounted } from "vue";
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from "vue-router";

// Helpers
import { log } from '@/helpers';

// Interfaces
import { IUser, FormState } from "@/interfaces/login.interface";

// Firebase authen
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { initialize, signInPopup, createUserWithEmail, signInWithEmail } from "@/firebase";

export default defineComponent({
    name: "LoginForm",
    components: {
        UserOutlined,
        LockOutlined,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const state = reactive<IUser>({
            user: null,
            isLoggedIn: false,
            swithButton: false,
            buttonName: "Sign in",
            visible: false
        });

        const formState = reactive<FormState>({
            username: '',
            password: '',
            errMsg: ''
        });

        const onFinish = (values: string) => {
            log({ Success: values });
        };

        const onFinishFailed = (errorInfo: string) => {
            log({ Failed: errorInfo });
        };

        const disabled = computed(() => {
            return !(formState.username && formState.password);
        });

        const handleSwithLink = () => {
            state.swithButton = !state.swithButton;
            const swithButtonName = !state.swithButton ? 'Sign in' : 'Sign up';
            Object.assign(state, { buttonName: swithButtonName });
        }

        const handleSignIn = async () => {
            log('Sign in')
            const email = formState.username;
            const password = formState.password;
            const errorMsg = await signInWithEmail(email, password);
            log({ errorMsg: typeof errorMsg })

            if (typeof errorMsg !== 'object' && errorMsg) {
                state.visible = true;
                formState.errMsg = errorMsg;
            } else {
                state.visible = false;
                router.push('/')
            }
        }

        const handleSignUp = async () => {
            log('Sign up')
            const email = formState.username;
            const password = formState.password;
            const errorMsg = await createUserWithEmail(email, password);
            log({ errorMsg: typeof errorMsg })

            if (typeof errorMsg !== 'object' && errorMsg) {
                state.visible = true;
                formState.errMsg = errorMsg;
            } else {
                state.visible = false;
                router.push('/')
            }
        }

        onMounted(() => {
            log({ route: route.query.error })
            if (route.query.error) {
                state.visible = true;
            } else {
                state.visible = false;
            }
        });

        return {
            onFinish,
            disabled,
            formState,
            signInPopup,
            handleSignIn,
            handleSignUp,
            onFinishFailed,
            handleSwithLink,
            ...toRefs(state),
        };
    },
});
