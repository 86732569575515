import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4852b9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `${_ctx.propsImage.link ? _ctx.propsImage.link : ''}`
  }, {
    default: _withCtx(() => [
      (!_ctx.propsIsExpand)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.propsImage.image_expand,
            class: _normalizeClass(["logo", { collapse: _ctx.propsIsExpand }]),
            alt: "logo_xsight"
          }, null, 10, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.propsImage.image_collapse,
            class: "logo",
            alt: "logo_xsight"
          }, null, 8, _hoisted_2))
    ]),
    _: 1
  }, 8, ["to"]))
}