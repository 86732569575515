import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74c3cea6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "expression-box"
}
const _hoisted_2 = { class: "content-inner" }
const _hoisted_3 = { class: "content-chart" }
const _hoisted_4 = { class: "chart-expression" }
const _hoisted_5 = { class: "chart-average-inner" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "content-detail" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_ctx.option)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.average), 1),
                        (_ctx.isAverageTxt)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Average Emotion Intensity"))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_v_chart, {
                        class: "chart-donut",
                        option: _ctx.option,
                        ref: "donutChart"
                      }, null, 8, ["option"])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.option.series[0].data.filter(
								(o) => o.name.toLowerCase() !== 'signal'
							), ({ name, value }, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "item-box",
                        key: index
                      }, [
                        (typeof name === 'string')
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: 
									require(`@/assets/images/icons/icon-${name.toLowerCase()}.png`)
								,
                              alt: name
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(name), 1),
                        _createElementVNode("span", null, _toDisplayString(value) + " %", 1)
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}