import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, KeepAlive as _KeepAlive, Transition as _Transition, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_sidebar_detail = _resolveComponent("the-sidebar-detail")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "compare-layout" }, {
    default: _withCtx(() => [
      (_ctx.dataHeader)
        ? (_openBlock(), _createBlock(_component_the_header, {
            key: 0,
            data: _ctx.dataHeader
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          (_ctx.dataButton)
            ? (_openBlock(), _createBlock(_component_the_sidebar_detail, {
                key: 0,
                "data-logo": _ctx.dataLogo,
                "data-button": _ctx.dataButton
              }, null, 8, ["data-logo", "data-button"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_layout_content, { class: "compare-layout-content" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component, route }) => [
                  _createVNode(_Transition, {
                    name: "slide-fade",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_KeepAlive, null, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                              key: route.meta.usePathKey ? route.path : undefined
                            }))
                          ]),
                          fallback: _withCtx(() => [
                            _createTextVNode(" Loading... ")
                          ]),
                          _: 2
                        }, 1024))
                      ], 1024))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}