import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ffa74ce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "frame-layer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_SceneHeatmap = _resolveComponent("SceneHeatmap")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["imageThumb", { small: _ctx.frameSmall }])
  }, [
    _createVNode(_component_a_image, {
      preview: false,
      src: `${_ctx.playAnimateScenesProp?.scene ?? _ctx.showImage}`,
      fallback: `${require('@/assets/images/dashboard/image-default.png')}`
    }, null, 8, ["src", "fallback"]),
    (_ctx.eyeImpactShowProp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.coordinateStyle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "frame-inner",
                style: _normalizeStyle(_ctx.coordinateStyle)
              }, null, 4))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectFilter, ({ left, top, width, height }) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "frame-object",
              key: top,
              style: _normalizeStyle({
					left: `${left}%`,
					top: `${top}%`,
					width: `${width}%`,
					height: `${height}%`,
				})
            }, null, 4))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.HeatMapList)
      ? (_openBlock(), _createBlock(_component_SceneHeatmap, {
          key: 1,
          HeatMapList: _ctx.HeatMapList
        }, null, 8, ["HeatMapList"]))
      : _createCommentVNode("", true)
  ], 2))
}