import { defineComponent, onMounted,computed  } from "vue";

export default defineComponent({
    name: "video-eyecontact",
    props: {
        eyeData:{
            type:  Object,
            required: true,
        }
       
    }
    ,
    setup(prop) {

        const FrameStyle = computed(()=>{

            return {
                    left: `${prop.eyeData.left}%`,
                    top:`${prop.eyeData.top}%`,
                    width:`${prop.eyeData.width}%`,
                    height:`${prop.eyeData.height}%`,
                }
        })


        onMounted(() => {
            // console.log(prop.eyeData)
        })
      
        return { FrameStyle}
    }
});
