/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, watch, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { key } from "@/store";

// Components
import SceneLists from "@/components/Scene-lists/Scene-lists.vue";
import { RefTypes } from "@/interfaces/component.interface";
import { IScenedata } from "@/interfaces/advertise.interface";

export default defineComponent({
    name: "ScenesListView",
    components: {
        SceneLists
    },
    setup() {
        const route = useRoute();
        const store = useStore(key);
        const sceneData: RefTypes = ref([]);
        const eyeImpactShow: RefTypes = ref(false);
        const vdoScenesType = computed(() => store.getters["advertise/VDO_SCENE_TYPE"]);

        watch(() => store.getters['advertise/VDO_SCENE'], async (sceneListData: IScenedata[]) => {
            sceneData.value = sceneListData.filter((scene) => scene.isShow == true);
            await store.dispatch('APP_LOADING', false);
        });

        const objectSelected = computed(() => {
            const objects = store.getters["advertise/REQUEST_SELECT_OBJECTS"];
            const filtered = objects.filter((o: any) => o.isSelected);
            return filtered.length > 0 ? filtered[0] : false;
        })

        onMounted(async () => {

            window.scrollTo(0, 0);

            const sceneListData = store.getters['advertise/VDO_SCENE'];

            // reset default load scene list and sort type clear
            const objects = store.getters["advertise/REQUEST_SELECT_OBJECTS"];

            objects.map((o: any) => {
                Object.assign(o, {
                    isSelected: false
                });
                return o;
            });

            sceneListData.map((scene: any) => {
                Object.assign(scene, {
                    isShow: true
                });
                return scene;
            });

            if (sceneListData.length == 0) {
                const payload = { adid: route.params.id };
                await store.dispatch('advertise/VDO_FRAME', payload);
                await store.dispatch('advertise/REQUEST_ADS_STAT', payload);
            }

        });

        return {
            sceneData,
            eyeImpactShow,
            vdoScenesType,
            objectSelected,
        };
    },
});