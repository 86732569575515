import { defineComponent } from 'vue'
import { SecondToMinute } from "@/helpers";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";

export default defineComponent({
    name: "BaseSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        id: {
            type: Number,
            required: false
        },
        width: {
            type: Number,
            required: false
        },
        height: {
            type: Number,
            required: false
        }
    },
    setup() {

        // @swiper="onSwiper"
        // @slideChange="onSlideChange"

        // const onSwiper = (swiper: any) => {
        //     console.log(swiper);
        // };
        // const onSlideChange = () => {
        //     console.log('slide change');
        // };

        return {
            SecondToMinute,
            // onSwiper,
            // onSlideChange,
            modules: [Pagination, Navigation],
        }
    }
})