import { RouteRecordRaw } from "vue-router";

// Views
import { LoginView } from "@/views";

// Layouts
import { LoginLayout } from "@/views/Layout";

// Routes
const routeAuthen: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "LoginView",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "LoginView",
        component: LoginView,
        meta: {
          title: "Sign in"
        }
      },
    ],
  }
];

export default routeAuthen;

