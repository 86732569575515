/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRef, watch, inject, computed } from "vue";
import VChart from "vue-echarts";
import { log, isInteger } from "@/helpers"

export default defineComponent({
  name: "video-emotion",
  props: ["sceneData"],
  components: {
    VChart,
  },
  setup(prop) {
    const activeCurrentTime = ref(true);
    const ViewerListPaging = ref(<any[]>[]);
    const ScenePerPage = ref<number>(9);
    const propsSceneData = toRef(prop, "sceneData");
    const page: any = ref(1);
    const dataViewers: any = ref({
      current: 1,
      data: [],
      total: 0,
      pageSize: 0,
    });

    const SummaryDonutGraph: any = inject("SummaryDonutGraph");
    const SummaryData: any = inject("AdsInfo");

    const SummaryDataAttentionSignals: any = computed(() => SummaryData.value.dataExpression.find(
      (expression: any) => expression.title == "Attention Signals Score"
    ))

    const SummaryDataEmotionIntensity: any = computed(() => SummaryData.value.dataExpression.find(
      (expression: any) => expression.title == "Emotion Intensity"
    ))

    const SummaryDataVisualImpact: any = computed(() => SummaryData.value.dataExpression.find(
      (expression: any) => expression.title == "Visual Impact"
    ))

    const SummaryDonutGraphAngry: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Angry"
    )?.value ?? 0)

    const SummaryDonutGraphDisgusted: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Disgusted"
    )?.value ?? 0)

    const SummaryDonutGraphHappy: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Happy"
    )?.value ?? 0)

    const SummaryDonutGraphSad: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Sad"
    )?.value ?? 0)

    const SummaryDonutGraphSurprised: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Surprised"
    )?.value ?? 0)

    const SummaryDonutGraphFearful: any = computed(() => SummaryDonutGraph.value.series[0]?.data.find(
      (serie: any) => serie.name == "Fearful"
    )?.value ?? 0)

    const ShowCurrentScene = ref<boolean>(false);

    const chunkArray = (arr: any, size: number) => {
      return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
    };

    const percentConvert = (percent: any) => {
      // in case as -, 0, '0.00'
      if (percent === '-') {
        return 0;
      } else if (percent === 0) {
        return 0;
      } else if (percent === '0.00') {
        return 0;
      }

      const number = parseFloat(percent);
      const numberConvert = isInteger(number.toFixed(1))
        ? number.toFixed(0)
        : number.toFixed(1);
      return parseFloat(numberConvert);
    };

    const handleTabClick = (NewState = null) => {
      if (propsSceneData.value.CurrentTime) {
        if (NewState) {
          activeCurrentTime.value = false;
          ShowCurrentScene.value = NewState;
        } else {
          ShowCurrentScene.value = !ShowCurrentScene.value;
        }

      } else {
        ShowCurrentScene.value = false;
      }


    };

    watch(
      () => propsSceneData.value,
      (scene) => {
        const { Participants } = scene;

        if (Participants) {
          // log({ Participants: Participants })
          if (Participants.length < ScenePerPage.value) {
            page.value = 1;
          }
          // participants
          const dataParticipants = chunkArray(Participants, ScenePerPage.value);
          ViewerListPaging.value = Participants;
          Object.assign(dataViewers.value, {
            current: 1,
            data: dataParticipants,
            total: Participants.length,
            pageSize: dataParticipants.length,
          });
        } else {
          const dataParticipants = [];
          page.value = 1;
          ViewerListPaging.value = [];
          Object.assign(dataViewers.value, {
            current: 1,
            data: ViewerListPaging.value,
            total: dataParticipants.length,
            pageSize: dataParticipants.length,
          });
        }
        // log({ ViewerListPaging: ViewerListPaging.value })
        // log({ dataViewers: dataViewers.value })
      }
    );

    return {
      page,
      dataViewers,
      propsSceneData,
      percentConvert,
      ViewerListPaging,
      ShowCurrentScene,
      activeCurrentTime,
      handleTabClick,
      SummaryDonutGraph,
      SummaryData,
      SummaryDataAttentionSignals,
      SummaryDataEmotionIntensity,
      SummaryDataVisualImpact,
      SummaryDonutGraphAngry,
      SummaryDonutGraphDisgusted,
      SummaryDonutGraphHappy,
      SummaryDonutGraphSad,
      SummaryDonutGraphSurprised,
      SummaryDonutGraphFearful
    };
  },
});
