import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_sidebar = _resolveComponent("the-sidebar")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_UploadAdsForm = _resolveComponent("UploadAdsForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_sidebar, {
      "is-expand": _ctx.isSidebarExpand,
      "is-visible": _ctx.isVisible,
      "data-logo": _ctx.logoData,
      "data-button": _ctx.buttonData,
      "data-menu": _ctx.listData,
      "data-setting": _ctx.settingData,
      "onAction:expand": _ctx.handleSidebarExpand,
      "onModal:visible": _ctx.handleCreateAds
    }, null, 8, ["is-expand", "is-visible", "data-logo", "data-button", "data-menu", "data-setting", "onAction:expand", "onModal:visible"]),
    _createVNode(_component_a_layout, { class: "main-layout" }, {
      default: _withCtx(() => [
        (_ctx.$route.name === 'AdsDetails' || _ctx.$route.name === 'AdsDetailsUpdate')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$route.meta.className)
            }, null, 2))
          : (_openBlock(), _createBlock(_component_the_header, { key: 1 })),
        _createVNode(_component_a_layout_content, {
          class: _normalizeClass(["main-layout-content", { collapse: _ctx.isSidebarExpand }])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component, route }) => [
                _createVNode(_Transition, { mode: "out-in" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock("div", {
                      key: route.fullPath
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }),
    _createVNode(_component_UploadAdsForm, {
      "is-visible": _ctx.isVisible,
      "onModal:visible": _ctx.handleModal
    }, null, 8, ["is-visible", "onModal:visible"])
  ], 64))
}