import { defineComponent, ref, toRef, toRefs, reactive, watch } from "vue";

// Ant design types
import type { TableProps } from 'ant-design-vue';
import { isInteger, SecondToMinute } from "@/helpers";

// Interfaces
import { StateTypes, TableDataType } from '@/interfaces/component.interface'

// Packages
import _ from 'lodash';

export default defineComponent({
    name: "DataTableContent",
    props: {
        titleName: {
            type: String,
            required: true,
        },
        dataColumns: {
            type: Array,
            required: true
        },
        dataSources: {
            type: Array,
            required: true
        }
    },
    emits: ["action:sorted"],
    setup(props, { emit }) {

        // Props
        const propsTitleName = toRef(props, 'titleName');
        const propsColumns = toRef(props, 'dataColumns');
        const propsSources: any = toRef(props, 'dataSources');
        const loading = ref(false);

        // State
        const state = reactive<StateTypes>({
            columns: propsColumns,
            data: propsSources,
        })

        // Method
        const handleChange: TableProps<TableDataType>['onChange'] = (pagination, filters, sorter) => {
            // console.log('params', pagination, filters, sorter);
            emit('action:sorted', sorter)
        };

        const convertToFloat = (value: any) => {
            const numberFloat = value;
            const numberConverted = isInteger(numberFloat.toFixed(1)) ? (numberFloat).toFixed(0) : (numberFloat).toFixed(1);
            return numberConverted;
        }

        const getMaxResults = (object: TableDataType[], name: string) => {
            const maxPercentage = _.maxBy(object, name);
            return maxPercentage;
        }

        watch(() => propsSources.value, (newVal) => {
            if (newVal.length > 0) {
                loading.value = true;
            }
        })

        return {
            loading,
            isInteger,
            handleChange,
            getMaxResults,
            propsTitleName,
            SecondToMinute,
            convertToFloat,
            ...toRefs(state)
        }
    },
});
