/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRefs, toRef, reactive, computed, nextTick, watch } from "vue";
import { PlusOutlined } from '@ant-design/icons-vue';
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import { log } from "@/helpers"
import { useStore } from 'vuex';
import { key } from "@/store";

export default defineComponent({
    name: "CreateProjectForm",
    components: {
        PlusOutlined
    },
    props: {
        isUpdate: {
            type: Boolean,
            default: false
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        dataEdit: {
            type: Object
        }
    },
    emits: ['action:submit'],
    setup(props, { emit }) {

        const inputRef = ref();
        const store = useStore(key);
        const projectList = ref([]);
        const countAccountText = ref(0);
        const countProjectText = ref(0);
        const accountNameUpdate: any = ref("");
        const projectNameUpdate: any = ref("");
        const isUpdateProp = toRef(props, "isUpdate");
        const isVisibleProp = toRef(props, "isVisible");
        const propdataEdit = toRef(props, "dataEdit");
        const brandData = computed(() => store.getters["advertise/REQUEST_BRANDS"]);

        const state = reactive({
            accountName: null,
            projectName: null,
            vdoName: null,
            tags: "" || [],
            inputVisible: false,
            inputValue: null
        });

        const rules = computed(() => ({
            accountName: { required },
            projectName: { required },
            vdoName: { required, maxLength: maxLength(500) },
            tags: {},
            inputVisible: {},
            inputValue: {}
        }));

        const v$ = useVuelidate(rules, state);

        const handleClose = (removedTag: string) => {
            const tags = state.tags.filter((tag: any) => tag !== removedTag);
            state.tags = tags;
        };

        const onChangeAccountName = (value: any) => {
            // const value = evt.target.value;
            log({ accountName: value });
            Object.assign(state, { accountName: null, projectName: null });
            if (value) {
                Object.assign(state, { accountName: value });
                const brand: any = brandData.value.filter((item: any) => item.value == value);
                countAccountText.value = brand[0].label.length;
                projectList.value = brand[0].projects;
                log({ projects: projectList.value[0] })
                return projectList.value;
            } else {
                Object.assign(state, { accountName: value });
            }
        }

        const onChangeProjectName = (value: any) => {
            // const value = evt.target.value;
            // Object.assign(state, { projectName: "" });
            log({ projectName: value });
            Object.assign(state, { projectName: null });
            if (value) {
                Object.assign(state, { projectName: value });
                const project: any = projectList.value.filter((item: any) => item.value == value);
                countProjectText.value = project[0].label.length;
            } else {
                Object.assign(state, { projectName: value });
            }
        }

        const handleInputConfirm = () => {
            const inputValue = state.inputValue;
            let tags: any = state.tags;
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue];
            }
            Object.assign(state, {
                tags,
                inputVisible: false,
                inputValue: '',
            });
        };

        const showInput = () => {
            Object.assign(state, {
                inputVisible: true,
            });
            nextTick(async () => {
                await inputRef.value.focus();
            });
        };

        const countVdoNameText = (name: any) => {
            let vdoName: any = 0;
            if (name !== null) {
                vdoName = name.length;
            }
            return vdoName;
        }

        watch(v$, (v) => {
            const data = {
                invalid: v.$invalid,
                accountName: state.accountName,
                projectName: state.projectName,
                vdoName: state.vdoName,
                tags: state.tags,
                inputVisible: state.inputVisible,
                inputValue: state.inputValue
            }

            emit("action:submit", data);
        });

        watch(() => ([isUpdateProp.value, isVisibleProp.value]), ([visible, update]) => {
            if (visible && update) {
                Object.assign(state, {
                    accountName: null,
                    projectName: null,
                    vdoName: null,
                    tags: "" || [],
                    inputVisible: false,
                    inputValue: null
                });
            }
        });

        watch(() => propdataEdit.value, (update) => {
            // update ads
            if (update) {
                const o: any = update;

                Object.assign(state, {
                    accountName: o.brandId,
                    projectName: o.projectId,
                    vdoName: o.title,
                });

                if (o.brandId) {
                    onChangeAccountName(o.brandId);
                    onChangeProjectName(o.projectId);
                    // const brand: any = brandData.value.filter((item: any) => item.brandid == o.brandId);

                    // projectList.value = brand[0].projects;

                    // const project: any = projectList.value.filter((item: any) => item.projectid == o.projectId);

                    // countAccountText.value = brand[0].brandtitle.length;
                    // countProjectText.value = project[0].projecttitle.length;

                    // accountNameUpdate.value = o.brandId;
                    // projectNameUpdate.value = o.projectId;

                    // return projectList.value;
                } else {
                    Object.assign(state, { accountName: o.brandId });
                }
            }
        });

        watch(() => brandData.value, (brand) => {
            const countBrand = brand.filter((b: any) => b.value != null).length;
            log('brand count:', countBrand);
            if (countBrand == 1) {
                brand.map((item: any, index: number) => {
                    if (index == 1) {
                        log(item.value);
                        Object.assign(state, { accountName: item.value });
                        onChangeAccountName(item.value);
                    }
                });
            }
        });

        return {
            v$,
            inputRef,
            showInput,
            brandData,
            handleClose,
            projectList,
            propdataEdit,
            ...toRefs(state),
            countProjectText,
            countAccountText,
            countVdoNameText,
            accountNameUpdate,
            projectNameUpdate,
            handleInputConfirm,
            onChangeAccountName,
            onChangeProjectName,
        }
    },
});
