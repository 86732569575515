/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRouter, createWebHistory } from "vue-router";
// RouterScrollBehavior

import routes from "./routeRoot";

// Store
import { store } from "../store";

// Helpers
import { log } from "@/helpers";

// path for await loading
const showLoaders: any = ['AdsListView', 'AdsDetails', 'VdoInformationView', 'PackageInformationView'];

// const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
//   if (savedPosition) {
//     return savedPosition
//   } else {
//     return { top: 0 }
//   }
// }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // scrollBehavior,
  routes
});

router.beforeEach(async (to, from, next) => {

  // log(`from: `, from);

  // Show loading
  store.commit("APP_LOADING", true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pageRoute: any = to.name;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const title: any = to.meta.title;
  if (title) document.title = title;

  // Check token and verify that the token is valid
  const AppToken = await store.getters["GET_APP_TOKEN"];

  await store.dispatch("APP_CONFIG", AppToken);
  await store.dispatch("VERIFY_APP_TOKEN", AppToken);
  const isLoggedIn = await store.getters["VERIFY_APP_TOKEN"];


  // log(`isLoggedIn: `, isLoggedIn);
  // log(`AppToken: `, AppToken);

  if (to.matched.some((m) => m.meta.requiresAuth)) {

    // Check expired token
    if (!isLoggedIn || !AppToken) {
      next({
        path: "/login",
      });
    } else {
      const { roles } = await store.dispatch("login/GET_USER_INFO", AppToken);
      log({ user: roles?.[0]?.role?.toLowerCase().includes('admin') });
      next();
    }
  } else {

    if (pageRoute == 'homepage' || pageRoute == 'watch' || pageRoute == 'calibration') {
      next();
    } else if (isLoggedIn && (AppToken !== 'null' || AppToken !== null)) {
      // Check logedin
      next({
        path: "/",
      });
    } else {
      next();
    }
  }

});

router.afterEach(async (to: any, from: any) => {
  // Hide loading
  log(`to: `, to?.name);
  log(`from: `, from?.name);
  log(`isPathValid: `, showLoaders.some((m: any) => to?.name.includes(m)));
  if (!showLoaders.some((m: any) => to?.name.includes(m))) {
    setTimeout(() => {
      // Hide loading
      store.commit("APP_LOADING", false);
    }, 300);
  } else {
    if (to?.name.toLowerCase().includes('compare'))
      store.commit("APP_LOADING", false);
    else
      store.commit("APP_LOADING", false);
  }
});

export default router;
