/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, ref, toRef, computed, reactive } from "vue";
import { useStore } from 'vuex';
import { key } from "@/store";

import ImagePlayScene from '@/components/Image-play-scene/Image-play-scene.vue';
import { RefTypes } from "@/interfaces/component.interface";
import { SecondToMinute, log, isInteger } from "@/helpers";

// Packages
import _ from 'lodash';

// Echart Package Import and Use
import { use, ComposeOption } from "echarts/core";
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    GridComponent,
    GridComponentOption
} from 'echarts/components';
import {
    PieChart,
    PieSeriesOption,
    LineChart,
    LineSeriesOption
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

use([
    PieChart,
    LineChart,
    LabelLayout,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    TooltipComponent,
    UniversalTransition
]);

type EChartsOption = ComposeOption<
    TooltipComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PieSeriesOption
    | LineSeriesOption
>;

export default defineComponent({
    name: "scene-lists",
    props: {
        sceneData: {
            type: Array,
            required: true
        },
        sorttype: {
            type: String,
            required: true
        }
    },
    components: {
        ImagePlayScene
    },
    // eslint-disable-next-line 
    setup(prop) {

        const store = useStore(key);
        const optionLine = ref<EChartsOption>({});
        const isVisibleScene = ref<boolean>(false);
        const allScenseData: any = toRef(prop, 'sceneData');
        const mergeAllScenes: any = ref([]);
        const playScene: any = ref(null);
        const percentConvert = (percent: any) => {
            const number = parseFloat(percent);
            const numberConvert = isInteger(number.toFixed(1)) ? (number).toFixed(0) : (number).toFixed(1)
            return parseFloat(numberConvert);
        }

        const getMaxEmotions = (object: any, current: any) => {
            const { expression, eyeimpact, eyeattention } = object;
            const allEmotions = [parseFloat(expression), parseFloat(eyeimpact), parseFloat(eyeattention)];
            const numebr = parseFloat(current);
            const maxPercentage = _.maxBy(allEmotions);
            return maxPercentage === numebr ? true : false;
        }

        const labelLineData = ref([
            // 'Angry',
            // 'Disgusted',
            // 'Happy',
            // 'Sad',
            // 'Surprised',
            'Signal',
        ]);

        const topAttentionSignalSceneData: RefTypes = reactive({
            head: 'Top Attention Signal Scene',
            hints: `
                <ul>
                    <li>
                    ระบบของ XSight จะใช้การตัดแบ่งวิดีออกเป็น Scenes
                    แบบอัตโนมัติ โดยใช้การรวมซีนที่ต่ำกว่า 4 วินาทีเข้าด้วย
                    กันเป็นหนึ่งซีน
                    </li>
                    <li>
                    Attention Signals Score  นั้นจะคำนวณจากการนำค่า
                    สูงสุดของ Emotion Intensity และ Visual Impact 
                    ของซีนนั้นมาเฉลี่ยกัน 
                    </li>
                    <li>
                    นอกจากนี้คุณยังสามารถกดที่ปุ่ม Show More เพื่อดู 
                    Scenes อื่นๆ ทั้งหมดในวิดีโอนี้ และสามารถเลือกการ
                    จัดเรียงได้ 3 รูปแบบ คือ Attention Signals, 
                    Emotion Intensity และ Visual Impact
                    </li>
                </ul>
            `,
            signalScores: {},
            eyeCatchObjects: [],
            graphSignal: [],
            emotionData: [],
            sceneData: [],
            sorttype: prop.sorttype
        })

        const progressTimeline = (index: number, time: number, totalSec: any, sceneObj: any) => {

            setTimeout(() => {

                ++time;

                if (time <= totalSec && allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {

                    const percentage = Math.round((time * 100) / totalSec);
                    log({ time, totalSec, percentage })

                    prop.sorttype === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = time
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = time

                    progressTimeline(index, time, totalSec, sceneObj);
                } else if (allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {

                    if (prop.sorttype === 'expression') {
                        allScense.value[index].peakScenesObj.playingVdo = !allScense.value[index].peakScenesObj.playingVdo;
                    } else {
                        allScense.value[index].peakScenesObj.playingVdoEyeImpact = !allScense.value[index].peakScenesObj.playingVdoEyeImpact;
                    }

                    prop.sorttype === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = 0
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = 0

                }

            }, 1000);
        }

        const handlePlay = (index: number, sceneObj: any) => {
            const {
                prev,
                peak,
                next,
                totalSec,
                playingTime,
                playingTimeEyeImpact } = sceneObj; // get scenes by 8 sec.

            const resetTime = 0;
            const time = prop.sorttype === 'expression' ? parseInt(playingTime) : parseInt(playingTimeEyeImpact);
            const totalTime = parseInt(totalSec) || 0;

            if (prop.sorttype === 'expression') {
                allScense.value[index].peakScenesObj.playingVdo = !allScense.value[index].peakScenesObj.playingVdo;
            } else {
                allScense.value[index].peakScenesObj.playingVdoEyeImpact = !allScense.value[index].peakScenesObj.playingVdoEyeImpact;
            }

            log('playingVdo', allScense.value[index].peakScenesObj.playingVdo)
            log('playingVdoEyeImpact', allScense.value[index].peakScenesObj.playingVdoEyeImpact)

            if (allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {
                mergeAllScenes.value[index] = [...prev, ...peak, ...next];
                progressTimeline(index, time, totalTime, sceneObj);
            } else {
                // check pause and replay
                if (time == totalSec) {
                    log({ time, totalSec })
                    mergeAllScenes.value[index] = [];
                    prop.sorttype === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = resetTime
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = resetTime
                } else {
                    prop.sorttype === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = time
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = time

                }
            }
        }

        const PinPosition = (peakScenesObj: any) => {

            let PlayingTime = 0;
            let TotalWidth = 0;

            if (prop.sorttype === 'expression') {
                PlayingTime = peakScenesObj.playingTime
            } else {
                PlayingTime = peakScenesObj.playingTimeEyeImpact
            }

            const TotalPrev = peakScenesObj.prev.length
            const TotalPeak = peakScenesObj.peak.length;
            const TotalNext = peakScenesObj.next.length;


            if (PlayingTime == 0) {
                TotalWidth = 0;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPrev) {
                // console.log(PlayingTime);
                // console.log(PlayingTime);
                // console.log("plus  IN TotalPrev");
                TotalWidth += PlayingTime * 18.29268292682927;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPrev) {
                // console.log("plus  ALL TotalPrev");
                TotalWidth += TotalPrev * 18.29268292682927;

                PlayingTime -= TotalPrev;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPeak) {
                TotalWidth += (PlayingTime * 23) / TotalPeak;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPeak) {
                TotalWidth += 26.82926829268293;
                PlayingTime -= TotalPeak;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalNext) {
                TotalWidth += PlayingTime * 18.29268292682927;
                PlayingTime = 0;
            } else if (PlayingTime > TotalNext) {
                TotalWidth += TotalNext * 18.29268292682927;
                PlayingTime -= TotalNext;
            }

            return {
                width: `${TotalWidth}%`,
            };

        }

        const handleModalScene = (v: boolean) => {
            isVisibleScene.value = v;
        };

        const clickSceneHandler = (idx: any) => {
            const option = store.getters['advertise/VDO_EMOTION'];
            const sceneData = allScense.value.filter((scene: any) => scene.index === idx);
            topAttentionSignalSceneData.sceneData = sceneData;
            isVisibleScene.value = !isVisibleScene.value;
            // console.log({ sceneData })

            // top attention signal scene

            const { StartTime, EndTime } = topAttentionSignalSceneData.sceneData[0];
            // console.log({ StartTime, EndTime })

            const signal = option.filter((o: any) => o.name == 'signal');
            const visualimpact = option.filter((o: any) => o.name == 'eye impact');
            const label = signal[0]?.data.map((v: any, index: number) => {
                return index
            })

            // const signalFilter = signal[0]?.data.map((v: any, index: number) => {
            //     if (index >= StartTime && index <= EndTime) {
            //         return v
            //     } else {
            //         return 0
            //     }
            // })
            // console.log({ signalFilter })
            // const visualimpactFilter = visualimpact[0]?.data.map((v: any, index: number) => {
            //     if (index >= StartTime && index <= EndTime) {
            //         return v
            //     } else {
            //         return 0
            //     }
            // })
            // console.log({ visualimpactFilter })

            Object.assign(optionLine.value, {
                color: ['#6C5CE7', '#FAAD14'],
                grid: {
                    // show: false,
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '0%',
                    containLabel: false,
                    backgroundColor: 'transparent',
                },
                legend: {
                    show: false,
                    padding: 0
                },
                aria: {
                    enabled: false,
                },
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        label: {
                            width: 10,
                            backgroundColor: '#6C5CE7'
                        },
                        lineStyle: {
                            color: '#00CEC9',
                            width: 3,
                            type: 'dotted',
                        }
                    },
                    borderWidth: 1,
                    borderColor: 'rgba(193, 17, 21, 0.3)',
                    backgroundColor: '#2D3436',
                    borderRadius: 14,
                    padding: [5, 10],
                    textStyle: {
                        fontFamily: 'IBM Plex Light',
                        fontSize: 10,
                        color: '#FFFFFF'

                    },
                    // formatter: '{b0} {c0}%',
                    formatter: (params: any) => {
                        if (params[0].color) {

                            // const TxtTooltip = `
                            // <span style="color:${params[0].color};">Signal</span> :  ${(params[0].data * 100).toFixed(2)}%`;

                            const TxtTooltip = `
                            <span style="color:var(--color-white); line-height:var(--font-xs);">Emotion<br/> 
Intensity</span>  ${(params[0].data * 100).toFixed(2)}%`;

                            return TxtTooltip;
                        } else {
                            return "";
                        }
                    },
                },
                xAxis: {
                    show: true,
                    type: 'category',
                    boundaryGap: false,
                    data: label,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        // show: false
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            // opacity: 0.2,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            opacity: 0.1,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: signal[0]?.data,
                        color: "#6C5CE7",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 1
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        silent: true,
                        markArea: {
                            itemStyle: {
                                color: '#DFE6E9'
                            },
                            zlevel: 10,
                            z: 10,
                            data: [
                                [
                                    {
                                        name: 'Signal',
                                        xAxis: StartTime
                                    },
                                    {
                                        xAxis: EndTime
                                    }
                                ]
                            ]
                        }
                    },
                    {
                        data: visualimpact[0]?.data,
                        color: "#FAAD14",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        silent: true,
                        lineStyle: {
                            width: 1,
                            color: '#FAAD14',
                            type: [2, 3],
                            cap: 'round',
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        // markArea: {
                        //     itemStyle: {
                        //         color: '#DFE6E9'
                        //     },
                        //     zlevel: 10,
                        //     z: 10,
                        //     data: [
                        //         [
                        //             {
                        //                 name: 'Visual Impact',
                        //                 xAxis: StartTime
                        //             },
                        //             {
                        //                 xAxis: EndTime
                        //             }
                        //         ]
                        //     ]
                        // }
                    }
                ]
            });
        };

        const allScense: any = computed(() => allScenseData.value);

        const optionLineData = computed(() => {

            const option = store.getters['advertise/VDO_EMOTION'];
            // let lineData: any = []
            const signal = option.filter((o: any) => o.name == 'signal');
            const visualimpact = option.filter((o: any) => o.name == 'eye impact');
            // lineData = [...lineData, signal[0]?.data, visualimpact[0]?.data]

            Object.assign(optionLine.value, {
                color: ['#6C5CE7'],
                grid: {
                    // show: false,
                    left: '2%',
                    right: '2%',
                    bottom: '2%',
                    top: '0%',
                    containLabel: false,
                    backgroundColor: 'transparent',
                },
                legend: {
                    show: false,
                    padding: 0
                },
                aria: {
                    enabled: false,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'line',
                        label: {
                            width: 10,
                            backgroundColor: '#6C5CE7'
                        },
                        lineStyle: {
                            color: '#00CEC9',
                            width: 3,
                            type: 'dotted',
                        }
                    },
                    borderWidth: 1,
                    borderColor: 'rgba(193, 17, 21, 0.3)',
                    backgroundColor: '#2D3436',
                    borderRadius: 14,
                    padding: [5, 10],
                    textStyle: {
                        fontFamily: 'IBM Plex Light',
                        fontSize: 10,
                        color: '#FFFFFF'

                    },
                    // formatter: '{b0} {c0}%',
                    formatter: (params: any) => {
                        if (params[0].color) {

                            // const TxtTooltip = `
                            // <span style="color:${params[0].color};">Signal</span> :  ${(params[0].data * 100).toFixed(2)}%`;

                            const TxtTooltip = `
                            <span style="color:var(--color-white); line-height:var(--font-xs);">Emotion<br/> 
Intensity</span>  ${(params[0].data * 100).toFixed(2)}%`;

                            return TxtTooltip;
                        } else {
                            return "";
                        }
                    },
                },
                xAxis: {
                    show: true,
                    type: 'category',
                    boundaryGap: false,
                    data: signal[0].data,
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        // show: false
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            opacity: 0.1,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        length: 2,
                        show: true,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "#636E72", //e3e3e3
                            width: 1,
                            opacity: 0.1,
                        },
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: signal[0]?.data,
                        color: "#6C5CE7",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 2
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        data: visualimpact[0]?.data,
                        color: "#FAAD14",
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 2,
                            color: '#FAAD14',
                            type: [2, 3],
                            cap: 'round',
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            });

            return optionLine.value;
        });

        return {
            allScense,
            playScene,
            handlePlay,
            allScenseData,
            SecondToMinute,
            percentConvert,
            getMaxEmotions,
            mergeAllScenes,
            PinPosition,
            isVisibleScene,
            handleModalScene,
            clickSceneHandler,
            optionLine,
            optionLineData,
            labelLineData,
            topAttentionSignalSceneData
        };

    },
});
