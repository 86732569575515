import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a395405"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "eyecontact"
}
const _hoisted_3 = {
  key: 1,
  class: "heatmap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SceneHeatmap = _resolveComponent("SceneHeatmap")!

  return (_openBlock(), _createElementBlock("div", {
    class: "image-box",
    style: _normalizeStyle(`width: ${_ctx.dataWidth ? _ctx.dataWidth + 'px' : '100%'}; min-height: ${_ctx.dataHeight ? _ctx.dataHeight + 'px' : 'auto'}; `)
  }, [
    _createElementVNode("img", {
      src: _ctx.dataSrc,
      alt: "image"
    }, null, 8, _hoisted_1),
    (_ctx.dataObjects?.switchEyecontact)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.coordinateStyle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "frame-inner",
                style: _normalizeStyle(_ctx.coordinateStyle)
              }, null, 4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.dataObjects?.switchHeatmap)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.dataObjects?.switchHeatmap && _ctx.HeatMapList?.length > 0)
            ? (_openBlock(), _createBlock(_component_SceneHeatmap, {
                key: 0,
                HeatMapList: _ctx.HeatMapList
              }, null, 8, ["HeatMapList"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}