import { createApp, computed } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import "ant-design-vue/dist/antd.css";

// Import header and footer layout
import {
    TheHeader,
    TheFooter,
    TheSidebar,
    TheSidebarDetail
} from "@/views/Layout";

// Components
import {
    ExpressionChart,
    SignalAverageChart,
    TopExpressionScore,
    TopEyeImpactScenes,
    UploadAdsForm,
    ModalUploaded,
    CreateTesterForm,
    CreateProjectForm,
    CreateProjectUpload,
    CreateProjectPreview,
    TopAttentionSignalScene,
    AttentionSignalScene,
    TopDesignScore,
    PackagingModalContent,
    PackageItems,
    PackageImportantItems,
    OutstandingHead
} from '@/components';

import {
    BaseLoader,
    BaseModal,
    BaseMessage,
    BaseSwitch,
    BaseSlider,
    BasePercentBar,
    BaseTitle,
    BaseLink,
    BaseImage,
    BasePopup,
    BaseDisplayImage,
} from "@/components/General";

import SceneLists from "@/components/Scene-lists/Scene-lists.vue";

// Lottie animate
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// Styles
import "@/assets/styles/main.scss";
import "firebaseui/dist/firebaseui.css";

import heatmapjsVue from "heatmapjs-vue";

// user role
const appConfig = computed(() => store.getters["APP_CONFIG"]);

const isCreateProject = computed(() => {
    const value = appConfig.value.filter((config: any) => config.key.toLowerCase() === "createproject")[0]?.value
    const isShow = value == '0' ? false : true;
    return isShow;
})

const userInfo = computed(() => store.getters["login/GET_USER_INFO"]);
const roleUser = computed(() => {
    return userInfo.value.roles.map((v: any) => {
        const roleName = v.role.toLowerCase();
        if (roleName == 'system admin')
            return 99; // super admin
        else if (roleName == 'admin')
            return 2; // admin
        else
            return 0; // viewers
    })?.[0];
});

const app = createApp(App);

app.use(store, key);
app.use(router);
app.use(Antd);
app.use(heatmapjsVue);
app.use(Vue3Lottie, { name: 'LottieAnimation' })
app.component("TheHeader", TheHeader);
app.component("TheFooter", TheFooter);
app.component("BaseModal", BaseModal);
app.component("BaseLoader", BaseLoader);
app.component("SceneLists", SceneLists);
app.component("TheSidebar", TheSidebar);
app.component("BaseMessage", BaseMessage);
app.component("ModalUploaded", ModalUploaded);
app.component("UploadAdsForm", UploadAdsForm);
app.component("ExpressionChart", ExpressionChart);
app.component("TheSidebarDetail", TheSidebarDetail);
app.component("SignalAverageChart", SignalAverageChart);
app.component("TopExpressionScore", TopExpressionScore);
app.component("TopEyeImpactScenes", TopEyeImpactScenes);
app.component("CreateProjectForm", CreateProjectForm);
app.component("CreateProjectUpload", CreateProjectUpload);
app.component("CreateProjectPreview", CreateProjectPreview);
app.component("CreateTesterForm", CreateTesterForm);
app.component("TopAttentionSignalScene", TopAttentionSignalScene);
app.component("AttentionSignalScene", AttentionSignalScene);
app.component("TopDesignScore", TopDesignScore);
app.component("PackagingModalContent", PackagingModalContent);
app.component("BaseSwitch", BaseSwitch);
app.component("BaseSlider", BaseSlider);
app.component("PackageItems", PackageItems);
app.component("BasePercentBar", BasePercentBar);
app.component("PackageImportantItems", PackageImportantItems);
app.component("BaseTitle", BaseTitle);
app.component("BaseLink", BaseLink);
app.component("OutstandingHead", OutstandingHead);
app.component("BaseImage", BaseImage);
app.component("BasePopup", BasePopup);
app.component("BaseDisplayImage", BaseDisplayImage);
app.provide("appConfig", appConfig);
app.provide("authUser", roleUser);
app.provide("isCreateProject", isCreateProject);
app.mount('#app');
