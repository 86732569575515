/* eslint-disable @typescript-eslint/no-explicit-any */
const isProduction = process.env.NODE_ENV === "production";

const log = (message: string | object, ...args: object[]): void => {
    if (!isProduction) console.log(message, args);
}

const SecondToMinute = (Second: any) => {
    if (typeof Second === 'string') {
        Second = parseInt(Second)
    } else {
        Second = parseInt(Second)
    }

    if (isNaN(Second) || Second === 0) {
        return 0
    } else {

        const minutes = Math.floor(Second / 60) + ':' + ('0' + Math.floor(Second % 60)).slice(-2)
        return minutes
    }
}

const capitalizeFirstLetter = (txt: string): string => {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
}

const uuid = () => {
    let d = new Date().getTime(); //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}

const formatNumber = (num: number) => {
    const p = num.toFixed(2).split(".");
    return p[0]
        .split("")
        .reverse()
        .reduce((previousValue, currentValue, currentIndex) => {
            return currentValue + (currentValue != "-" && currentIndex && !(currentIndex % 3) ? "," : "") + previousValue;
        }, "");
};

const isInteger = (n: any): any => {
    if (!/^["|']{0,1}[-]{0,1}\d{0,}(\.{0,1}\d+)["|']{0,1}$/.test(n)) return false;
    return !(n - parseInt(n));
}

const percentConvert = (percent: any) => {
    // in case as -, 0, '0.00'
    if (percent === "-") {
        return 0;
    } else if (percent === 0) {
        return 0;
    } else if (percent === "0.00") {
        return 0;
    }

    const number = parseFloat(percent) * 100;
    const numberConvert = isInteger(number.toFixed(1))
        ? number.toFixed(0)
        : number.toFixed(1);
    return parseFloat(numberConvert);
};

const chunkArray = (arr: any, size: number) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );
};

const validQueryDomains = new Set([
    'youtube.com',
    'www.youtube.com',
    'm.youtube.com',
    'music.youtube.com',
    'gaming.youtube.com',
]);

const validPathDomains = /^https?:\/\/(youtu\.be\/|(www\.)?youtube\.com\/(embed|v|shorts)\/)/;

const getURLVideoID = (link: string) => {
    try {
        if (link !== "") {
            const parsed = new URL(link.trim());
            let id = parsed.searchParams.get('v');
            if (validPathDomains.test(link.trim()) && !id) {
                const paths = parsed.pathname.split('/');
                id = parsed.host === 'youtu.be' ? paths[1] : paths[2];
            } else if (parsed.hostname && !validQueryDomains.has(parsed.hostname)) {
                return { status: false };
            }
            if (!id) {
                return { status: false };
            }
            id = id.substring(0, 11);
            if (!validateID(id)) {
                return { status: false };
            }
            return { status: true, id };
        }
        return { status: false };
    } catch (err) {
        return { status: false };
    }
};

const idRegex = /^[a-zA-Z0-9-_]{11}$/;
const validateID = (id: string) => idRegex.test(id.trim());

const urlRegex = /^https?:\/\//;
const getVideoID = (str: string) => {
    if (validateID(str)) {
        return str;
    } else if (urlRegex.test(str.trim())) {
        return getURLVideoID(str);
    } else {
        return false
    }
};

const getURLVideoMP4 = (str: string) => {
    return str.includes('.mp4') ? true : false;
}

const imgPreview = (files: any) => {
    // console.log(files);
    // let img: any = ""
    // const reader = new FileReader();
    // reader.onload = function () {
    //     img = reader.result
    // }
    // reader.readAsDataURL(files);
    // return img

    const urlCreator = window.URL || window.webkitURL
    return urlCreator.createObjectURL(files);
}

const paginateData = (arr: Array<string>, size: number) => {

    return arr.reduce((acc: any, val: any, i: number) => {
        const idx = Math.floor(i / size);
        const page: any = acc[idx] || (acc[idx] = []);

        page.push(val);

        return acc
    }, []);
}

const vdoLinkValid = (vdoLink: string) => {
    if (vdoLink !== "") {
        const result = getURLVideoID(vdoLink);
        if (result.status) {
            return result.status;
        } else {
            return getURLVideoMP4(vdoLink);
        }
    } else {
        return false;
    }
}

const getTesterLink = (adId: any, viewerId = null) => {
    const LOCAL_DOMAINS = ['localhost', '127.0.0.1', '', '::1']
    const TEST_DOMAINS = ['xsight-dashboard', 'xsightdev-dashboard', 'xsightstaging-dashboard']
    const HOSTNAME = window.location.hostname
    let DOMAIN = process.env.VUE_APP_TESTER_DOMAIN
    if (LOCAL_DOMAINS.includes(HOSTNAME) || (HOSTNAME.startsWith('192.168.')) || (HOSTNAME.startsWith('10.0.')) || (HOSTNAME.endsWith('.local'))) {
        DOMAIN = DOMAIN.replace('tester', `xsightdev-tester`)
    } else {
        const HOST = window.location.host
        const SUBDOMAIN = HOST.split('.')[0]
        if (TEST_DOMAINS.includes(SUBDOMAIN)) {
            DOMAIN = window.location.origin.replace('dashboard', `tester`)
        }
    }

    let TEST_LINK = `${DOMAIN}/watch/${adId}`;
    if (viewerId !== null) {
        TEST_LINK = `${DOMAIN}/watch/${adId}/${viewerId}`;
    }
    return TEST_LINK
}

export {
    log,
    uuid,
    SecondToMinute,
    capitalizeFirstLetter,
    formatNumber,
    isInteger,
    imgPreview,
    getVideoID,
    getURLVideoID,
    getURLVideoMP4,
    paginateData,
    vdoLinkValid,
    getTesterLink,
    percentConvert,
    chunkArray
};