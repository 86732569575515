/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, reactive, toRef, ref, onMounted, watch } from "vue";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { key } from "@/store";

// Components
import { BaseLogo, BaseList, BaseButtonSortBy, BaseSelectItems } from "@/components/General";
import { FilterComponent, ParticipantsComponent } from "@/components/SideBar";

// Interfaces
import { IParticipants, IFilters } from '@/interfaces/component.interface'

// Helpers
import { log } from '@/helpers';

export default defineComponent({
    name: "TheSidebarDetail",
    components: {
        BaseLogo,
        BaseList,
        FilterComponent,
        BaseSelectItems,
        BaseButtonSortBy,
        ParticipantsComponent,
    },
    props: {
        dataLogo: {
            type: Object,
        },
        dataButton: {
            type: Array,
        },
        dataSortby: {
            type: Array,
        },
        dataSelectItems: {
            type: Array,
        },
        dataTotalSelect: {
            type: Number,
        },
        dataParticipants: {
            type: Array,
        },
        isLoad: {
            type: Boolean,
        }
    },
    emits: ["action:expand", "action:clear", "set:participants", "action:sortby", 'action:SelectObject'],
    setup(prop, { emit }) {
        const route = useRoute();
        const store = useStore(key);
        const propLogo = toRef(prop, "dataLogo");
        const propButton = toRef(prop, "dataButton");
        const propSortBy = toRef(prop, "dataSortby");
        const isParticipantsLoad = toRef(prop, "isLoad");
        const propSelectItems = toRef(prop, "dataSelectItems");
        const propTotalSelect = toRef(prop, "dataTotalSelect");
        const propParticipants: any = toRef(prop, "dataParticipants");
        const isExpandParticipant = ref<boolean>(false);
        const isExpandFilter = ref<boolean>(false);
        const combineFilters = ref<object>({});
        const customFilters = ref<any>({
            ageRange: null,
            genders: null
        });
        const isTabChanged = ref<boolean>(true);
        const isActiveParticipant = ref<any>(null);
        const isActiveFilter = ref<any>(null);
        const countParticipantSelected = ref<any>(null);
        const dataAgeRange = reactive<IFilters[]>([
            {
                name: "All",
                value: "all",
                isSelected: false,
            },
            {
                name: "18-24",
                value: "18|24",
                isSelected: false,
            },
            {
                name: "25-32",
                value: "25|32",
                isSelected: false,
            },
            {
                name: "33-45",
                value: "33|45",
                isSelected: false,
            },
            {
                name: "46-57",
                value: "46|57",
                isSelected: false,
            },
            {
                name: "58-65",
                value: "58|65",
                isSelected: false,
            },
            {
                name: "66-72",
                value: "66|72",
                isSelected: false,
            },
        ]);

        const dataGender = reactive<IFilters[]>([
            {
                name: "All",
                value: "all",
                isSelected: false,
            },
            {
                name: "Male",
                value: "male",
                isSelected: false,
            },
            {
                name: "Female",
                value: "female",
                isSelected: false,
            }
        ]);

        const handleExpandParticipant = (val: boolean) => {
            isExpandParticipant.value = !val;
        }

        const handleExpandFilter = (val: boolean) => {
            isExpandFilter.value = !val;
        }

        const handleTabClick = () => {
            isTabChanged.value = !isTabChanged.value;

            // clear participant and filter set default
            // propParticipants.value.map((o: IParticipants, i: any) => {
            //     o.isSelected = true;
            //     return o;
            // });
            // dataAgeRange.map((o: IParticipants, i: any) => {
            //     o.isSelected = false;
            //     return o;
            // });
            // dataGender.map((o: IParticipants, i: any) => {
            //     o.isSelected = false;
            //     return o;
            // });

            // reset filtets

            // const participants = store.getters['advertise/REQUEST_ADS_PARTICIPANTS']
            // countParticipantSelected.value = propParticipants.value
            //     .filter((o: IParticipants) => !o.isSelected).length
            // Object.assign(customFilters.value, {
            //     ageRange: "",
            //     genders: ""
            // });
        }

        const handleExpand = (val: number) => {
            emit("action:expand", val);
        }

        const handleClear = () => {
            emit("action:clear");
        }

        const handleSelect = (item: any) => {
            emit('action:SelectObject', item)
        }

        const handleSortby = (val: number) => {
            emit("action:sortby", val);
        }

        const handleReset = () => {

            // reset filters age rang and genders
            isActiveParticipant.value = false;
            isActiveFilter.value = false;

            let payload: any = {
                adid: route.params.id,
                // crtiteriatype: isTabChanged.value ? "participant" : "filter",
            };

            if (isTabChanged.value) {

                propParticipants.value.map((o: IParticipants, i: any) => {
                    o.isSelected = true
                    return o;
                });

                const participants = propParticipants.value
                    .filter((o: IParticipants) => o.isSelected)
                    .map((m: IParticipants) => m.profileid).join('|');

                payload = {
                    ...payload,
                    crtiteriaparticipantlist: participants
                }

                countParticipantSelected.value = propParticipants.value
                    .filter((o: IParticipants) => !o.isSelected).length

            } else {
                dataAgeRange.map((o: IParticipants, i: any) => {
                    o.isSelected = false;
                    return o;
                });
                dataGender.map((o: IParticipants, i: any) => {
                    o.isSelected = false;
                    return o;
                });

                customFilters.value = {
                    ageRange: null,
                    genders: null
                }

                // reset
                payload = {
                    ...payload,
                    crtiteriafilteage: "",
                    crtiteriafiltegender: ""
                }

                // log({ reset: customFilters.value })

            }

            emit("set:participants", payload);
        }

        const handleSubmit = () => {

            let payload: any = {
                adid: route.params.id,
                crtiteriatype: isTabChanged.value ? "participant" : "filter",
            };

            if (isTabChanged.value) { // participant

                // reset filters age rang and genders
                isActiveParticipant.value = true;
                isActiveFilter.value = false;

                dataAgeRange.map((o: IParticipants, i: any) => {
                    o.isSelected = false;
                    return o;
                });

                dataGender.map((o: IParticipants, i: any) => {
                    o.isSelected = false;
                    return o;
                });

                customFilters.value = {
                    ageRange: null,
                    genders: null
                }
                // reset filters age rang and genders

                const participants = propParticipants.value
                    .filter((o: IParticipants) => o.isSelected)
                    .map((m: IParticipants) => m.profileid).join('|');

                payload = {
                    ...payload,
                    crtiteriaparticipantlist: participants
                }
            } else { // filters

                isActiveParticipant.value = false;
                isActiveFilter.value = true;

                // reset participants
                propParticipants.value.map((o: IParticipants, i: any) => {
                    o.isSelected = true
                    return o;
                });
                countParticipantSelected.value = propParticipants.value
                    .filter((o: IParticipants) => !o.isSelected).length
                // reset participants


                log({ filters: customFilters.value })
                const { ageRange, genders } = customFilters.value;

                payload = {
                    ...payload,
                    crtiteriafilteage: ageRange,
                    crtiteriafiltegender: genders
                }

            }

            emit("set:participants", payload);
        }

        const handleToggleFilters = (filters: any) => {
            log({ handleToggleFilters: filters })
            const { ageRange, genders } = filters;

            // set for reset button
            customFilters.value = {
                ageRange,
                genders
            }

            let payload: any = {
                adid: route.params.id,
                crtiteriatype: "filter",
            };

            payload = {
                ...payload,
                crtiteriafilteage: ageRange,
                crtiteriafiltegender: genders
            }

            // emit("set:participants", payload);
        }

        const handleToggle = (id: string) => {

            const Total_Participants = propParticipants.value.length
            const Total_Participants_Active = propParticipants.value.filter((item: any) => item.isSelected).length

            if (Total_Participants == Total_Participants_Active) {
                //กด Filter คนแรก
                propParticipants.value.forEach((element: IParticipants) => {
                    element.isSelected = false
                });
            }
            // console.log(Total_Participants)

            propParticipants.value.map((o: IParticipants, i: any) => {
                if (o.profileid == id)
                    if (o.isSelected)
                        o.isSelected = false
                    else
                        o.isSelected = true
            });

            const Total_Participants_ActiveFinal = propParticipants.value.filter((item: any) => item.isSelected).length
            if (Total_Participants_ActiveFinal == 0) {
                propParticipants.value.forEach((element: IParticipants) => {
                    element.isSelected = true
                });
            }

            store.commit("advertise/REQUEST_ADS_PARTICIPANTS", propParticipants.value);

            // call api ...
            if (propParticipants.value) {

                let payload: any = {
                    adid: route.params.id,
                    crtiteriatype: "participant",
                };

                const participants = propParticipants.value
                    .filter((o: IParticipants) => o.isSelected)
                    .map((m: IParticipants) => m.profileid).join('|');

                payload = {
                    ...payload,
                    crtiteriaparticipantlist: participants
                };

                countParticipantSelected.value = propParticipants.value
                    .filter((o: IParticipants) => !o.isSelected).length

                // emit("set:participants", payload);
            }

        }

        onMounted(() => {
            combineFilters.value = {
                ageRange: dataAgeRange,
                gender: dataGender
            };
        });

        return {
            propLogo,
            propSortBy,
            dataGender,
            propButton,
            handleClear,
            handleReset,
            handleSubmit,
            dataAgeRange,
            isTabChanged,
            handleExpand,
            handleToggle,
            handleSortby,
            handleSelect,
            customFilters,
            isActiveFilter,
            combineFilters,
            isExpandFilter,
            handleTabClick,
            propTotalSelect,
            propSelectItems,
            propParticipants,
            isParticipantsLoad,
            handleExpandFilter,
            handleToggleFilters,
            isExpandParticipant,
            isActiveParticipant,
            handleExpandParticipant,
            countParticipantSelected,
        };
    },
});