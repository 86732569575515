import { defineComponent } from "vue";

export default defineComponent({
    name: "BaseLink",
    props: {
        name: {
            type: String,
            required: true,
        }
    },
    emits: ["action:click"],
    setup(_, { emit }) {

        const clickSceneHandler = () => {
            emit('action:click')
        }

        return {
            clickSceneHandler
        }
    }
});
