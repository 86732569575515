import { defineComponent } from 'vue'
import { SecondToMinute } from "@/helpers";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";

export default defineComponent({
    name: "BaseSliderVdo",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        allScense: {
            type: Object,
            required: true,
        },
    },
    setup() {

        return {
            SecondToMinute,
            modules: [Pagination, Navigation],
        }
    }
})