import { defineComponent, toRef, watch } from "vue";

// Helpers
import { log } from '@/helpers';

export default defineComponent({
    name: "CreateProjectPreview",
    props: {
        indicator: {
            type: Object
        },
        isVdoLink: {
            type: Boolean
        },
        isUploadFile: {
            type: Boolean
        },
        isPreload: {
            type: Boolean
        },
        previewVdo: {
            type: String
        },
        fileInfo: {
            type: String
        },
        dataEdit: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    emits: ["action:remove", "update:upload"],
    setup(prop, { emit }) {

        const propdataEdit = toRef(prop, "dataEdit");

        const handleRemoveFile = () => {
            emit('action:remove');
        };

        watch(() => propdataEdit.value, (update) => {
            // update ads
            if (update) {
                log(update);
                emit('update:upload', false);
            }
        });

        return {
            propdataEdit,
            handleRemoveFile
        }
    }
});
