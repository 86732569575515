import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198b0116"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "package-list" }
const _hoisted_2 = { class: "package-inner" }
const _hoisted_3 = { class: "package" }
const _hoisted_4 = { class: "image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImage = _resolveComponent("BaseImage")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataItems, ({name, image, percent, color, highlight}, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "color",
              style: _normalizeStyle(`background-color: ${color};`)
            }, null, 4),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseImage, {
                data: image,
                width: _ctx.width,
                height: _ctx.height
              }, null, 8, ["data", "width", "height"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(`${highlight ? 'name max' : !_ctx.dataSummary && index == '0' && _ctx.locked ? 'name max' : _ctx.locked ? 'name' : 'default'}`)
            }, _toDisplayString(name), 3)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(`${highlight ? 'percent max' : !_ctx.dataSummary && index == '0' && _ctx.locked ? 'percent max' : _ctx.locked ? 'percent' : 'default'}`)
          }, _toDisplayString(percent) + "% ", 3)
        ])
      ]))
    }), 128))
  ]))
}