import { defineComponent, ref, computed } from "vue";
import { SecondToMinute, log, percentConvert } from "@/helpers";
import _ from "lodash";

import VChart from "vue-echarts";

import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";

// Echart Package Import and Use
import { use, ComposeOption } from "echarts/core";
import {
    TooltipComponent,
    TooltipComponentOption,
    LegendComponent,
    LegendComponentOption,
    GridComponent,
    GridComponentOption,
    MarkAreaComponent,
    MarkAreaComponentOption
} from 'echarts/components';
import {
    PieChart,
    PieSeriesOption
} from 'echarts/charts';
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { useStore } from 'vuex';
import { key } from "@/store";

use([
    PieChart,
    LabelLayout,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    TooltipComponent,
    UniversalTransition,
    MarkAreaComponent
]);

type EChartsOption = ComposeOption<
    TooltipComponentOption
    | LegendComponentOption
    | GridComponentOption
    | PieSeriesOption
    | MarkAreaComponentOption
>;

export default defineComponent({
    name: "TopAttentionSignalScene",
    props: {
        packageData: {
            type: Object,
            required: true,
        },
        isVisibleScene: {
            type: Boolean,
            required: false,
        },
        isVisible: {
            type: Boolean,
            required: false,
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false,
        },
        packageType: {
            type: String,
            required: true,
            default: () => {
                return 'design'
            }
        },
        packageList: {
            type: Object,
            required: false
        },
        packageDetails: {
            type: Object,
            required: false
        }
    },
    components: {
        VChart,
        Swiper,
        SwiperSlide,
    },
    emits: ["action:linkto"],
    setup(prop) {
        const store = useStore(key);
        const optionChart = ref<EChartsOption>({});
        const sorttype: any = computed(() => prop.packageData.sorttype.value);
        const mergeAllScenes: any = ref([]);
        const playScene: any = ref(null);
        const switchHeatmap = ref(false);
        const switchEyecontact = ref(false);
        const packageId = ref(0);

        const PinPosition = (peakScenesObj: any) => {
            let PlayingTime = 0;
            let TotalWidth = 0;

            if (sorttype.value === "expression") {
                PlayingTime = peakScenesObj.playingTime;
            } else {
                PlayingTime = peakScenesObj.playingTimeEyeImpact;
            }

            const TotalPrev = peakScenesObj.prev.length;
            const TotalPeak = peakScenesObj.peak.length;
            const TotalNext = peakScenesObj.next.length;

            if (PlayingTime == 0) {
                TotalWidth = 0;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPrev) {
                // console.log(PlayingTime);
                // console.log(PlayingTime);
                // console.log("plus  IN TotalPrev");
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPrev) {
                // console.log("plus  ALL TotalPrev");
                TotalWidth += TotalPrev * 17.88488382564841;

                PlayingTime -= TotalPrev;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPeak) {
                TotalWidth += (PlayingTime * 28) / TotalPeak;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPeak) {
                TotalWidth += 28.46046469740634;
                PlayingTime -= TotalPeak;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalNext) {
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalNext) {
                TotalWidth += TotalNext * 17.88488382564841;
                PlayingTime -= TotalNext;
            }

            return {
                width: `${TotalWidth}%`,
            };
        };

        const getMaxEmotions = (object: any, current: any) => {
            const { expression, eyeimpact, eyeattention } = object;
            const allEmotions = [
                parseFloat(expression),
                parseFloat(eyeimpact),
                parseFloat(eyeattention),
            ];
            const numebr = parseFloat(current);
            const maxPercentage = _.maxBy(allEmotions);
            return maxPercentage === numebr ? true : false;
        };

        const progressTimeline = (
            index: number,
            time: number,
            totalSec: any,
            sceneObj: any
        ) => {
            setTimeout(() => {

                ++time;

                if (
                    time <= totalSec &&
                    packageVdoFrame.value?.[index]?.peakScenesObj &&
                    (packageVdoFrame.value?.[index]?.peakScenesObj?.playingVdo ||
                        packageVdoFrame.value?.[index].peakScenesObj?.playingVdoEyeImpact)
                ) {
                    const percentage = Math.round((time * 100) / totalSec);
                    log({ time, totalSec, percentage });

                    sorttype.value === "expression"
                        ? (packageVdoFrame.value[index].peakScenesObj.playingTime = time)
                        : (packageVdoFrame.value[index].peakScenesObj.playingTimeEyeImpact =
                            time);

                    progressTimeline(index, time, totalSec, sceneObj);
                } else if (
                    packageVdoFrame.value?.[index]?.peakScenesObj &&
                    (packageVdoFrame.value?.[index]?.peakScenesObj?.playingVdo ||
                        packageVdoFrame.value?.[index].peakScenesObj?.playingVdoEyeImpact)
                ) {
                    if (sorttype.value === "expression") {
                        packageVdoFrame.value[index].peakScenesObj.playingVdo =
                            !packageVdoFrame.value[index].peakScenesObj.playingVdo;
                    } else {
                        packageVdoFrame.value[index].peakScenesObj.playingVdoEyeImpact =
                            !packageVdoFrame.value[index].peakScenesObj.playingVdoEyeImpact;
                    }

                    sorttype.value === "expression"
                        ? (packageVdoFrame.value[index].peakScenesObj.playingTime = 0)
                        : (packageVdoFrame.value[index].peakScenesObj.playingTimeEyeImpact = 0);
                }
            }, 1000);
        };

        const handlePlay = (index: number, sceneObj: any) => {

            const {
                prev,
                peak,
                next,
                totalSec,
                playingTime,
                playingTimeEyeImpact
            } = sceneObj; // get scenes by 8 sec.

            const resetTime = 0;
            const time =
                sorttype.value === "expression"
                    ? parseInt(playingTime)
                    : parseInt(playingTimeEyeImpact);
            const totalTime = parseInt(totalSec) || 0;

            if (sorttype.value === "expression") {
                packageVdoFrame.value[index].peakScenesObj.playingVdo =
                    !packageVdoFrame.value[index].peakScenesObj.playingVdo;
            } else {
                packageVdoFrame.value[index].peakScenesObj.playingVdoEyeImpact =
                    !packageVdoFrame.value[index].peakScenesObj.playingVdoEyeImpact;
            }

            log("playingVdo", packageVdoFrame.value[index].peakScenesObj.playingVdo);
            log(
                "playingVdoEyeImpact",
                packageVdoFrame.value[index].peakScenesObj.playingVdoEyeImpact
            );

            if (
                packageVdoFrame.value?.[index]?.peakScenesObj &&
                (packageVdoFrame.value?.[index]?.peakScenesObj?.playingVdo ||
                    packageVdoFrame.value?.[index].peakScenesObj?.playingVdoEyeImpact)
            ) {
                mergeAllScenes.value[index] = [...prev, ...peak, ...next];
                progressTimeline(index, time, totalTime, sceneObj);
            } else {
                // check pause and replay
                if (time == totalSec) {
                    log({ time, totalSec });
                    mergeAllScenes.value[index] = [];
                    sorttype.value === "expression"
                        ? (packageVdoFrame.value[index].peakScenesObj.playingTime = resetTime)
                        : (packageVdoFrame.value[index].peakScenesObj.playingTimeEyeImpact =
                            resetTime);
                } else {
                    sorttype.value === "expression"
                        ? (packageVdoFrame.value[index].peakScenesObj.playingTime = time)
                        : (packageVdoFrame.value[index].peakScenesObj.playingTimeEyeImpact =
                            time);
                }
            }
        };

        const switchHeatmapHandler = () => {
            switchHeatmap.value = !switchHeatmap.value;
        };

        const switchEyecontactHandler = () => {
            switchEyecontact.value = !switchEyecontact.value;
        };

        const packageItemById: any = computed(() => {
            const data: any = prop.packageList
            // console.log({ data, type: prop.packageType, packages: prop.packageData })
            const designColors = store.getters['advertise/REQUEST_DESIGN_COLORS'];
            const competitorColors = store.getters['advertise/REQUEST_COMPETITOR_COLORS'];

            const legend: any[] = [];
            const series: any[] = [];

            data?.map(({ name, percent }: any) => {
                legend.push(name)
                // series.push({ value: (percent * 100).toFixed(0) ?? 0, name })
                series.push({ value: percentConvert(percent > 0 ? percent : 0) ?? 0, name })
            });

            Object.assign(optionChart.value, {
                tooltip: {
                    show: false,
                    trigger: 'item',
                    borderWidth: 1,
                    borderColor: 'rgba(193, 17, 21, 0.3)',
                    backgroundColor: '#2D3436',
                    borderRadius: 14,
                    padding: [5, 10],
                    textStyle: {
                        fontFamily: 'IBM Plex Light',
                        fontSize: 10,
                        color: '#FFFFFF'

                    },
                    formatter: '{b0}: {c0}%',
                },
                legend: {
                    show: false,
                    orient: "vertical",
                    left: "left",
                    data: legend
                },
                series: [
                    {
                        type: 'pie',
                        radius: '85%',
                        avoidLabelOverlap: false,
                        color: prop.packageType === 'design' ? designColors : competitorColors,
                        label: {
                            rotate: 0,
                            position: 'inside',
                            alignTo: 'none',
                            color: '#FFFFFF',
                            fontSize: 10,
                            fontFamily: 'IBM Plex Bold',
                            padding: 0,
                            align: '50',
                            verticalAlign: 'middle',
                            formatter: '{d}%'
                        },
                        emphasis: {
                            label: {
                                // show: false,
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: series
                    }
                ]
            });

            return data
        });

        const handlerChange = (event: any) => {
            packageId.value = event.target.value
        }

        const packageSlider = computed(() => {
            let slides = []
            if (prop.packageType === 'design') {
                slides = prop.packageDetails?.competeList[packageId.value]?.importantElement
            } else {
                slides = prop.packageDetails?.importantElement
            }
            return slides.sort((a: any, b: any) => b.eyecatch - a.eyecatch)
        })

        const packageVdoFrame = computed(() => {
            let frames = []
            if (prop.packageType === 'design') {
                frames = [prop.packageDetails?.competeList[packageId.value]?.videoData]
            } else {
                frames = [prop.packageDetails?.videoPreviewFrames]
            }
            return frames
        })

        return {
            sorttype,
            playScene,
            packageId,
            handlePlay,
            PinPosition,
            optionChart,
            switchHeatmap,
            handlerChange,
            packageSlider,
            SecondToMinute,
            getMaxEmotions,
            mergeAllScenes,
            packageItemById,
            packageVdoFrame,
            switchEyecontact,
            switchHeatmapHandler,
            switchEyecontactHandler,
            modules: [Pagination, Navigation],
        };
    },
});
