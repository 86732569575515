import { defineComponent } from "vue";

// Components
import { LoginForm } from "@/components";

export default defineComponent({
  name: "LoginView",
  components: {
    LoginForm
  },
});