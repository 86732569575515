import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-175f3ef2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseMessage = _resolveComponent("BaseMessage")!
  const _component_AdsList = _resolveComponent("AdsList")!
  const _component_CompareBar = _resolveComponent("CompareBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseMessage, {
      "is-visible": _ctx.isVisible,
      message: _ctx.message,
      type: _ctx.type
    }, null, 8, ["is-visible", "message", "type"]),
    (_ctx.brandList.length > 0)
      ? (_openBlock(), _createBlock(_component_AdsList, {
          key: 0,
          data: _ctx.brandList,
          "ads-selected": _ctx.adsSelected,
          "compare-selected": _ctx.compateSelected,
          "onAction:link": _ctx.handleLink,
          "onAction:edit": _ctx.handleEdit,
          "onAction:expand": _ctx.handleExpand,
          "onAction:delete": _ctx.handleDelete,
          "onAction:compare": _ctx.handleCompare,
          "onAction:copylink": _ctx.handleCopyLink
        }, null, 8, ["data", "ads-selected", "compare-selected", "onAction:link", "onAction:edit", "onAction:expand", "onAction:delete", "onAction:compare", "onAction:copylink"]))
      : _createCommentVNode("", true),
    (_ctx.compateSelected.length > 0)
      ? (_openBlock(), _createBlock(_component_CompareBar, {
          key: 1,
          "amount-selected": _ctx.compateSelected.length,
          "onAction:linkto": _ctx.handleClick
        }, null, 8, ["amount-selected", "onAction:linkto"]))
      : _createCommentVNode("", true)
  ]))
}