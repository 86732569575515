import { defineComponent } from "vue";
import TheFooter from "../TheFooter/TheFooter.vue";
import TheHeader from "../TheHeader/TheHeader.vue";

export default defineComponent({
    components: { TheHeader, TheFooter },
    name: "LoginLayout",
    setup() {
        return {};
    },
});