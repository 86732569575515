import { defineComponent } from "vue";
import { SecondToMinute } from '@/helpers'
import ImageAnimate from '@/components/Image-animate/Image-animate.vue'

export default defineComponent({
    name: "TopEyeImpactScenes",
    props: {
        title: {
            type: String,
            required: true
        },
        sceneData: {
            type: Object,
            required: true,
        },
        sorttype: {
            type: String,
            required: true,
        }
    },
    components: {
        ImageAnimate
    },
    // eslint-disable-next-line 
    setup(props) {
        return { SecondToMinute };
    },
});
