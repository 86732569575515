import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff877658"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, {
    placement: "rightTop",
    overlayClassName: "top-scenes"
  }, {
    title: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.data.head), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.data.body
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: _normalizeClass(`icon ${_ctx.data.class}`),
        src: _ctx.data.icon,
        alt: "infomation"
      }, null, 10, _hoisted_2)
    ]),
    _: 1
  }))
}