import { defineComponent, toRef, ref } from "vue";

export default defineComponent({
    name: "BaseSelectItems",
    props: {
        data: {
            type: Object,
            required: true
        },
        totalSelect: {
            type: Number,
            required: true
        }
    },
    emits: ['action:expand', 'action:clear','action:SelectObject'],
    setup(prop, { emit }) {
        const sortType = ref<string | null>(null);
        const propsItems = toRef(prop, 'data');
        const propsTotalSelect = toRef(prop, 'totalSelect');
        const selectItems = ref(0);

        const handleExpand = (id: number) => {
            emit('action:expand', id)
        }

        const handleClearSelected = () => {
            emit('action:clear')
        }

        const SelectObject = (item:any) =>{
            emit('action:SelectObject', item)
        }

        return {
            sortType,
            propsItems,
            selectItems,
            handleExpand,
            propsTotalSelect,
            handleClearSelected,
            SelectObject
        }
    }
});
