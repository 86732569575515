import { defineComponent, ref, onMounted, onUpdated, onBeforeUnmount, watch } from 'vue';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { log } from '@/helpers';

type PlayerPropsType = {
  id: string
  src: string
  title: string
  token?: string
  createdAt: string
}

export default defineComponent({
  name: 'video-js-player',
  props: {
    options: {
      type: Object as () => PlayerPropsType,
      default: () => ({}),
      required: true,
    },
    stateVdo: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['ToggleHeatmap', 'ToggleEyeContact', 'ToggleScene', 'UpdateTime'],
  setup(props, { emit }) {
    // const isPlaying = ref<boolean>(false)
    // const isSupported = ref<boolean>(false)
    const VPlayer = ref<videojs.Player | null>(null);

    const ToggleHeatmap = () => {
      emit('ToggleHeatmap')
    }

    const ToggleEyeContact = () => {
      emit('ToggleEyeContact')
    }

    const ToggleScene = () => {
      emit('ToggleScene')
    }

    onUpdated(() => {
      // console.log('update')
      // VPlayer.value?.dispose()
      // VPlayer.value = videojs(
      //   'player-element',
      //   props.options,
      //   async function onPlayerReady() {
      //     // log('Player Is: ', VPlayer.value)

      //     // log( VPlayer.value?.currentTime())
      //     // var self= this;
      //     VPlayer.value?.on('timeupdate', () => {
      //       emit('UpdateTime', VPlayer.value?.currentTime())
      //     })

      //     CreateHeatmapButton();
      //     CreateEyeContactButton();
      //     CreateScenetButton();
      //   },
      // )
    })


    onMounted(() => {
      // console.log('mount video js player')
      // log( props.options)

      VPlayer.value = videojs(
        'player-element',
        props.options,
        async function onPlayerReady() {
          // log('Player Is: ', VPlayer.value)

          // log( VPlayer.value?.currentTime())
          // var self= this;
          VPlayer.value?.on('timeupdate', () => {
            emit('UpdateTime', VPlayer.value?.currentTime())
          })

          CreateHeatmapButton();
          CreateEyeContactButton();
          CreateScenetButton();
        },
      )
    })

    const CreateHeatmapButton = () => {
      if (VPlayer.value) {

        const HeatMapButton = VPlayer.value.controlBar.addChild("button", {}, VPlayer.value.controlBar.children_.length - 1);
        const HeatMapButtonDom = HeatMapButton.el();
        // HeatMapButtonDom.innerHTML = "<div class='slider_heatmap'>heatmap</div>";
        HeatMapButtonDom.innerHTML = `<label class="slider_heatmap">
          <input type="checkbox" id="heatmap_dom">
          <span class="slider"></span>
          </label>`;
        // HeatMapButton.addClass("slider_heatmap");


        document.getElementById('heatmap_dom')?.removeEventListener("change", function () {
          log('remove listener')
        });

        document.getElementById('heatmap_dom')?.addEventListener("change", function () {
          ToggleHeatmap()
        });
      }

    }


    const CreateEyeContactButton = () => {
      if (VPlayer.value) {

        const EyeContactButton = VPlayer.value.controlBar.addChild("button", {}, VPlayer.value.controlBar.children_.length - 1);
        const EyeContactButtonDom = EyeContactButton.el();
        // EyeContactButtonDom.innerHTML = "<div class='slider_heatmap'>heatmap</div>";
        EyeContactButtonDom.innerHTML = `<label class="slider_eyecontact">
          <input type="checkbox" id="eyecontact_dom">
          <span class="slider"></span>
          </label>`;
        // HeatMapButton.addClass("slider_heatmap");


        document.getElementById('eyecontact_dom')?.removeEventListener("change", function () {
          log('remove listener')
        });

        document.getElementById('eyecontact_dom')?.addEventListener("change", function () {
          ToggleEyeContact()
        });
      }

    }

    const CreateScenetButton = () => {
      if (VPlayer.value) {
        const HeatMapButton = VPlayer.value.controlBar.addChild("button", {}, VPlayer.value.controlBar.children_.length - 1);
        const HeatMapButtonDom = HeatMapButton.el();
        // EyeContactButtonDom.innerHTML = "<div class='slider_heatmap'>heatmap</div>";
        // EyeContactButtonDom.innerHTML = `<label class="label_scenebutton">
        //   <button class="scenebutton">
        //   </label>`;
        HeatMapButton.addClass("scenebutton");


        HeatMapButtonDom.removeEventListener("click", function () {
          log('remove listener')
        });

        HeatMapButtonDom.addEventListener("click", function () {
          HeatMapButtonDom.classList.toggle("active")
          ToggleScene()
        });
      }

    }

    const VideoSeek = (Showtime: number) => {
      VPlayer.value?.currentTime(Showtime)
    }

    onBeforeUnmount(() => {
      // console.log('unmoust VDO')
      VPlayer.value?.dispose()
    })

    // check filter and pause vdo
    watch(() => props.stateVdo, (buffer) => {
      //
      log({ buffer });
      // if (!buffer || buffer) {
      //   VPlayer.value?.pause();
      // }
    })

    return { VideoSeek }
  },
})