/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, toRef, ref, watch, computed } from "vue";
import type { SelectProps } from "ant-design-vue";
import { useRoute } from "vue-router";

// Helpers
import { log, formatNumber, isInteger } from "@/helpers";

export default defineComponent({
    name: "TheHeader",
    props: {
        data: { type: Object },
        dataExpandAds: {
            type: Boolean
        }
    },
    emits: ["action:expand-adslist"],
    setup(props, { emit }) {
        // Ads list data filter and sort
        const route = useRoute();
        const searchValue = ref("");
        const searchFilters = ref([]);
        const sortValue = ref("");
        const filterValue = ref("");
        const isShow = ref(false);
        const propsData = toRef(props, "data");
        const propsExpand = toRef(props, "dataExpandAds");

        const adsList = ref([
            {
                value: "ZEED SIM",
                label: "ZEED SIM",
            },
            {
                value: "ZEBRA ม้าลายสุดแสบ",
                label: "ZEBRA ม้าลายสุดแสบ",
            },
            {
                value: "Zoo บ้านนี้มีแต่สนุก",
                label: "Zoo บ้านนี้มีแต่สนุก",
            },
            {
                value: "Zoo บ้านนี้มีแต่สนุก 2",
                label: "Zoo บ้านนี้มีแต่สนุก 2",
            },
            {
                value: "Zee Zaa เผ็ดสึด",
                label: "Zee Zaa เผ็ดสึด",
            },
            {
                value: "UNZEEN item",
                label: "UNZEEN item",
            },
            {
                value: "Antez gadget wow",
                label: "Antez gadget wow",
            },
        ]);

        const optionsFitlerby = ref<SelectProps["options"]>([
            {
                value: "",
                label: "Filter object",
                disabled: true,
            },
            {
                value: "Mobile",
                label: "Mobile",
            },
            {
                value: "Car",
                label: "Car",
            },
            {
                value: "Clothing",
                label: "Clothing",
            },
            {
                value: "Laptop",
                label: "Laptop",
            },
            {
                value: "Outerwear",
                label: "Outerwear",
            },
            {
                value: "Table",
                label: "Table",
            },
            {
                value: "Toys",
                label: "Toys",
            },
        ]);

        const optionsSortby = ref<SelectProps["options"]>([
            {
                value: "",
                label: "Sort type",
                disabled: true,
            },
            {
                value: "Old",
                label: "Old",
            },
            {
                value: "Most Participants",
                label: "Most Participants",
            },
            {
                value: "Most Expression",
                label: "Most Expression",
            },
            {
                value: "Accounts",
                label: "Accounts",
            },
            {
                value: "Campaign",
                label: "Campaign",
            },
            {
                value: "A - Z",
                label: "A - Z",
            },
            {
                value: "Z - A",
                label: "Z - A",
            },
        ]);
        // end ads list

        const showFilter = () => {
            isShow.value = true;
            const data: any =
                searchFilters.value.length > 0 ? searchFilters.value : adsList.value;
            searchFilters.value = data;
        };

        const handleSearch = (search: string) => {
            searchValue.value = search;
            setTimeout(() => {
                isShow.value = false;
            }, 100);
        };

        const focus = () => {
            log('focus');
        };

        const handleChange = (value: string) => {
            log(`selected ${value}`);
        };

        const handleChangeAds = ({ adid, mode }: any) => {
            emit("action:expand-adslist", { adid, mode });
        };

        const handleExpandAds = () => {
            emit("action:expand-adslist");
        }

        const brandListFilter = computed(() => propsData.value?.dataBrand.adslist);

        watch(
            () => searchValue.value,
            (search) => {
                if (!search) return;

                isShow.value = true;

                const data = adsList.value;
                const filterData: any = data.filter((o: any) =>
                    o.value.toLowerCase().includes(search.toLocaleLowerCase())
                );

                if (filterData.length > 0) {
                    searchFilters.value = filterData;
                } else {
                    searchFilters.value = [];
                }

                return searchFilters.value;
            }
        );

        // get title page of vdo information
        watch(() => propsData.value, (value: any) => {
            const view = route.path.toLocaleLowerCase()
            if (view.indexOf("package") !== -1 || view.indexOf("vdo") !== -1) {
                document.title = value.dataBrand.adtitle;
            }
        });

        return {
            focus,
            isShow,
            adsList,
            propsData,
            isInteger,
            sortValue,
            showFilter,
            propsExpand,
            searchValue,
            filterValue,
            formatNumber,
            handleChange,
            handleSearch,
            searchFilters,
            optionsSortby,
            brandListFilter,
            optionsFitlerby,
            handleChangeAds,
            handleExpandAds
        };
    },
});