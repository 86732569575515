import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-846d444c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vdo-graph-legend" }
const _hoisted_2 = { class: "legend-groups" }
const _hoisted_3 = { class: "legend-group" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "legend-group legend-emotion" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "legend-group" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "legend-subgroups" }
const _hoisted_10 = { class: "legend-subgroup" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DashboardConfig.filter(
                        (item) => item.name == 'attentionsignalscore'
                    ), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "legend-bt",
            key: item.name
          }, [
            _createElementVNode("div", {
              class: "legend-bt-content",
              onClick: _withModifiers(($event: any) => (_ctx.ToggleSerie(item)), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["legend-ico", [{ active: item.Isshow }, item.name]])
              }, null, 2),
              _createTextVNode(" Attention Signals Score ")
            ], 8, _hoisted_4)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DashboardConfig.filter(
                        (item) => item.name == 'signal'
                    ), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "legend-bt",
            key: item.name
          }, [
            _createElementVNode("div", {
              class: "legend-bt-content",
              onClick: _withModifiers(($event: any) => (_ctx.ToggleSerie(item)), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["legend-ico", [{ active: item.Isshow }, item.name]])
              }, null, 2),
              _createTextVNode(" Emotion Intensity ")
            ], 8, _hoisted_6)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DashboardConfig.filter(
                        (item) => item.name == 'eye impact'
                    ), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "legend-bt",
            key: item.name
          }, [
            _createElementVNode("div", {
              class: "legend-bt-content",
              onClick: _withModifiers(($event: any) => (_ctx.ToggleSerie(item)), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["legend-ico", [{ active: item.Isshow }, item.name]])
              }, null, 2),
              _createTextVNode(" Visual Impact ")
            ], 8, _hoisted_8)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DashboardConfig.filter(
                    (item) =>
                        item.name !== 'attentionsignalscore' &&
                        item.name !== 'signal' &&
                        item.name !== 'eye impact'
                ), (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.name,
            class: "legend-bt"
          }, [
            _createElementVNode("div", {
              class: "legend-bt-content",
              onClick: _withModifiers(($event: any) => (_ctx.ToggleSerie(item)), ["prevent"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["legend-ico", [{ active: item.Isshow }, item.name]])
              }, null, 2),
              _createTextVNode(" " + _toDisplayString(_ctx.capitalizeFirstLetter(item.name)), 1)
            ], 8, _hoisted_11)
          ]))
        }), 128))
      ])
    ])
  ]))
}