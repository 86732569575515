import { defineComponent, ref, toRef, onMounted, computed } from "vue";
import { SecondToMinute, log, isInteger } from "@/helpers";
import _ from 'lodash';

import VChart from "vue-echarts";

import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper";

export default defineComponent({
    name: "TopAttentionSignalScene",
    props: {
        dataAttention: {
            type: Object,
            required: true,
        },
        labelLine: {
            type: Object,
            required: true
        },
        optionLineChart: {
            type: Object,
            required: true
        },
        isVisibleScene: {
            type: Boolean,
            required: false
        },
        isVisible: {
            type: Boolean,
            required: false,
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        VChart,
        Swiper,
        SwiperSlide,
    },
    emits: ["action:linkto"],
    setup(prop) {

        const lineChart = ref();
        const data = toRef(prop, "dataAttention");
        const allScenseData: any = computed(() => data.value.sceneData);
        const sorttype: any = computed(() => data.value.sorttype.value);
        const mergeAllScenes: any = ref([]);
        const playScene: any = ref(null);
        const switchHeatmap = ref(false);
        const switchEyecontact = ref(false);
        const percentConvert = (percent: any) => {

            const number = parseFloat(percent);
            const numberConvert = isInteger(number.toFixed(1)) ? (number).toFixed(0) : (number).toFixed(1)
            return parseFloat(numberConvert);
        }

        const PinPosition = (peakScenesObj: any) => {

            let PlayingTime = 0;
            let TotalWidth = 0;

            if (sorttype.value === 'expression') {
                PlayingTime = peakScenesObj.playingTime
            } else {
                PlayingTime = peakScenesObj.playingTimeEyeImpact
            }

            const TotalPrev = peakScenesObj.prev.length
            const TotalPeak = peakScenesObj.peak.length;
            const TotalNext = peakScenesObj.next.length;


            if (PlayingTime == 0) {
                TotalWidth = 0;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPrev) {
                // console.log(PlayingTime);
                // console.log(PlayingTime);
                // console.log("plus  IN TotalPrev");
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPrev) {
                // console.log("plus  ALL TotalPrev");
                TotalWidth += TotalPrev * 17.88488382564841;

                PlayingTime -= TotalPrev;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalPeak) {
                TotalWidth += (PlayingTime * 28) / TotalPeak;
                PlayingTime = 0;
            } else if (PlayingTime > TotalPeak) {
                TotalWidth += 28.46046469740634;
                PlayingTime -= TotalPeak;
            }

            if (PlayingTime > 0 && PlayingTime <= TotalNext) {
                TotalWidth += PlayingTime * 17.88488382564841;
                PlayingTime = 0;
            } else if (PlayingTime > TotalNext) {
                TotalWidth += TotalNext * 17.88488382564841;
                PlayingTime -= TotalNext;
            }

            return {
                width: `${TotalWidth}%`,
            };

        }

        const getMaxEmotions = (object: any, current: any) => {
            const { expression, eyeimpact, eyeattention } = object;
            const allEmotions = [parseFloat(expression), parseFloat(eyeimpact), parseFloat(eyeattention)];
            const numebr = parseFloat(current);
            const maxPercentage = _.maxBy(allEmotions);
            return maxPercentage === numebr ? true : false;
        }

        const progressTimeline = (index: number, time: number, totalSec: any, sceneObj: any) => {

            setTimeout(() => {

                ++time;

                if (time <= totalSec && allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {

                    const percentage = Math.round((time * 100) / totalSec);
                    log({ time, totalSec, percentage })

                    sorttype.value === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = time
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = time

                    progressTimeline(index, time, totalSec, sceneObj);
                } else if (allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {

                    if (sorttype.value === 'expression') {
                        allScense.value[index].peakScenesObj.playingVdo = !allScense.value[index].peakScenesObj.playingVdo;
                    } else {
                        allScense.value[index].peakScenesObj.playingVdoEyeImpact = !allScense.value[index].peakScenesObj.playingVdoEyeImpact;
                    }

                    sorttype.value === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = 0
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = 0

                }

            }, 1000);
        }

        const handlePlay = (index: number, sceneObj: any) => {

            const {
                prev,
                peak,
                next,
                totalSec,
                playingTime,
                playingTimeEyeImpact } = sceneObj; // get scenes by 8 sec.

            const resetTime = 0;
            const time = sorttype.value === 'expression' ? parseInt(playingTime) : parseInt(playingTimeEyeImpact);
            const totalTime = parseInt(totalSec) || 0;

            if (sorttype.value === 'expression') {
                allScense.value[index].peakScenesObj.playingVdo = !allScense.value[index].peakScenesObj.playingVdo;
            } else {
                allScense.value[index].peakScenesObj.playingVdoEyeImpact = !allScense.value[index].peakScenesObj.playingVdoEyeImpact;
            }

            log('playingVdo', allScense.value[index].peakScenesObj.playingVdo)
            log('playingVdoEyeImpact', allScense.value[index].peakScenesObj.playingVdoEyeImpact)

            if (allScense.value?.[index]?.peakScenesObj && (allScense.value?.[index]?.peakScenesObj?.playingVdo || allScense.value?.[index].peakScenesObj?.playingVdoEyeImpact)) {
                mergeAllScenes.value[index] = [...prev, ...peak, ...next];
                progressTimeline(index, time, totalTime, sceneObj);
            } else {
                // check pause and replay
                if (time == totalSec) {
                    log({ time, totalSec })
                    mergeAllScenes.value[index] = [];
                    sorttype.value === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = resetTime
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = resetTime
                } else {
                    sorttype.value === 'expression'
                        ? allScense.value[index].peakScenesObj.playingTime = time
                        : allScense.value[index].peakScenesObj.playingTimeEyeImpact = time

                }
            }
        }

        const allScense: any = computed(() => allScenseData.value);

        const switchHeatmapHandler = () => {
            // 
            switchHeatmap.value = !switchHeatmap.value;
            // console.log("switchHeatmapHandler")
        }

        const switchEyecontactHandler = () => {
            // 
            switchEyecontact.value = !switchEyecontact.value
            // console.log("switchEyecontactHandler")
        }

        onMounted(() => {
            // console.log({ dataAttention: prop.dataAttention })
        })

        return {
            data,
            sorttype,
            lineChart,
            allScense,
            playScene,
            handlePlay,
            allScenseData,
            SecondToMinute,
            percentConvert,
            getMaxEmotions,
            mergeAllScenes,
            PinPosition,
            switchHeatmapHandler,
            switchEyecontactHandler,
            modules: [Pagination, Navigation],
            switchHeatmap,
            switchEyecontact
        }
    }
});
