/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, toRef, ref, watch, reactive, toRefs, computed } from "vue";
import { useStore } from 'vuex';
import { key } from "@/store";
import { useRouter } from "vue-router";

// Lottie Component
import { LoadingPlayback } from "@/components/LottesAnimate";

// Lottie Json
import LottiePlaybackJson from "@/assets/lottie/loading.json";

import { useVuelidate } from "@vuelidate/core";
// import { url, helpers } from "@vuelidate/validators";
import { getURLVideoID, getVideoID, getURLVideoMP4, imgPreview } from "@/helpers";

import { AnyObjectTypes, StateUploadTypes } from "@/interfaces/brand.interface";

const vdoLinkValid = (vdoLink: string) => {
    if (vdoLink !== "") {
        const result = getURLVideoID(vdoLink);
        if (result.status) {
            return result.status;
        } else {
            return getURLVideoMP4(vdoLink);
        }
    } else {
        return false;
    }
}

export default defineComponent({
    name: "UploadAdsForm",
    components: {
        LoadingPlayback
    },
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ["modal:visible"],
    setup(prop, { emit }) {

        const store = useStore(key);
        const router = useRouter();
        const uploadFile: AnyObjectTypes = ref(null);
        const isProgress = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const visible = ref<boolean>(false);
        const modalVisible = toRef(prop, 'isVisible');
        const maxSizeFileUpload = ref(500); // 500mb
        const fileUploadTypes = ref(['.MP4', '.MPEG4']);
        const uploadStatus = ref<boolean>(true);
        const previewImage = ref<any>('');
        const state: StateUploadTypes = reactive({
            vdoUploadTemp: "",
            vdoLink: "",
            errorMessage: {
                type: `ระบบไม่รองรับไฟล์ที่คุณอัปโหลดเข้ามา ไฟล์ที่ระบบรองรับคือ ${fileUploadTypes.value.join(' / ')}`,
                size: `ระบบไม่รองรับไฟล์ที่มี่ขนาดไฟล์เกิน ${maxSizeFileUpload.value} MB`,
            },
            alertMessage: "",
            updateProgress: [
                {
                    title: `Uploading item to XSight`,
                    description: "",
                    status: false
                },
                {
                    title: `Upload complete!`,
                    description: `ระบบจะใช้เวลาประมวลผลประมาณ 5-15 นาที`,
                    status: false

                }
            ]
        });

        const rules = computed(() => ({
            vdoUploadTemp: {},
            vdoLink: { urlx: vdoLinkValid },
        }));

        const v$ = useVuelidate(rules, state);

        const handleClose = () => {
            state.vdoUploadTemp = "";
            state.vdoLink = "";
            isProgress.value = false;
            state.updateProgress[0].status = false;
        }

        const handleAddInfo = () => {

            store.commit('APP_LOADING', true);
            // router.push('/dashboard/create-ads');

            setTimeout(() => {
                handleClose();
                visible.value = !visible.value;
                store.commit('APP_LOADING', false);
                router.push('/');
            }, 1000)
        }

        const handleAddVdo = () => {
            handleClose();
        }

        const handleBrowse = (evt: AnyObjectTypes) => {
            evt.preventDefault();
            uploadFile.value.click();
        };

        const handleChange = (evt: AnyObjectTypes) => {
            if (!evt.target.files) return;
            onChangeUploadFile(evt);
            evt.target.value = "";
        };

        const onChangeUploadFile = (evt: AnyObjectTypes) => {
            evt.preventDefault();

            const files = evt.target.files;
            const size = files[0].size / 1024 / 1024;
            const type = files[0].name.toString().toLowerCase().split('.').pop();

            if (size > maxSizeFileUpload.value) { // max size file upload
                uploadStatus.value = !uploadStatus.value;
                state.alertMessage = state.errorMessage.size;
                return;
            } else if (!fileUploadTypes.value.some((o: any) => o.toLowerCase().includes(type))) { // wrong file type
                uploadStatus.value = !uploadStatus.value;
                state.alertMessage = state.errorMessage.type;
                return;
            }

            processFile(files[0]);
        };

        const processFile = (file: any) => {
            state.vdoUploadTemp = file;
            handleUpload();
        }

        const handleUpload = async () => {

            // app preload
            store.commit("APP_LOADING", true);

            // upload preload
            store.commit('brands/PRELOAD_FILE', true);

            let fileData: any = "";
            const timeout = 500;
            const ytdl: any = getVideoID(state.vdoLink);

            // show progress
            // isProgress.value = true;

            // get vdo thumbnail by file type
            if (typeof (state.vdoUploadTemp) === 'object') {
                // File select
                fileData = state.vdoUploadTemp;
                previewImage.value = URL.createObjectURL(fileData);
            } else if (ytdl.status) {
                // Youtube 
                const ytid: any = getVideoID(state.vdoLink);
                fileData = state.vdoLink;
                previewImage.value = ytid.status ? `https://img.youtube.com/vi/${ytid.id}/0.jpg` : '';
            } else {
                // MP4
                fileData = state.vdoLink;
                const poster = await store.dispatch('brands/GET_VDO_POSTER', { url: state.vdoLink });
                previewImage.value = imgPreview(poster);
            }

            const payload = { upload: fileData, preview: previewImage.value };
            store.commit("brands/UPLOAD_FILE", payload);


            setTimeout(() => {
                // state.updateProgress[0].status = true;
                handleClose();
                visible.value = !visible.value;
                router.push('/create-ads');
            }, timeout);

        }

        watch(() => modalVisible.value, (newVisible) => {
            if (!newVisible) return
            visible.value = newVisible;
            emit("modal:visible");
        });

        watch(() => state.vdoLink, (ytlink) => {
            if (ytlink.length == 0) return;
            const data = vdoLinkValid(ytlink);
            if (data) handleUpload();

        });

        watch(() => uploadStatus.value, (upload) => {
            if (!upload) {
                setTimeout(() => {
                    uploadStatus.value = true;
                }, 8000)
            }
        });

        return {
            v$,
            loading,
            visible,
            uploadFile,
            isProgress,
            handleClose,
            uploadStatus,
            handleUpload,
            handleBrowse,
            handleChange,
            handleAddInfo,
            ...toRefs(state),
            LottiePlaybackJson,
            previewImage,
            maxSizeFileUpload,
            handleAddVdo
        }
    }
});
