import { defineComponent } from 'vue'

export default defineComponent({
    name: "PackageImportantItems",
    props: {
        importanceElement: {
            type: Object,
            required: true
        },
        width: {
            type: Number,
            required: false,
        },
        height: {
            type: Number,
            required: false,
        },
        max: {
            type: Number,
            required: false,
        }
    }
})