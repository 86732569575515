import { defineComponent, ref, toRef, watch, onMounted, onUpdated } from "vue";
import { useRouter } from "vue-router";
// import type { Ref } from 'vue'

// Echart Package Import and Use
import VChart from "vue-echarts";

// Dragable
import { VueDraggableNext } from 'vue-draggable-next'

// Helpers
import { log } from '@/helpers';

// Interfaces and Types
// import { IBrandAndProjectList } from '@/interfaces/component.interface';

// interface List extends IBrandAndProjectList {
//     id?: Ref<number>
// }

export default defineComponent({
    name: "CompareList",
    components: {
        VChart,
        draggable: VueDraggableNext,
    },
    props: {
        titleDonutChart: {
            type: String,
            required: true
        },
        titleLineChart: {
            type: String,
            required: true
        },
        optionDonutChart: {
            type: Object,
            required: true
        },
        optionLineChart: {
            type: Object,
            required: true
        },
        dataScenes: {
            type: Object,
            required: true
        },
        dataEyeImpact: {
            type: Object,
            required: true
        },
        dataCompareList: {
            type: Object,
            required: true
        }
    },
    emits: ["action:compare", "action:remove"],
    setup(props, { emit }) {

        const router = useRouter();
        const option = toRef(props, 'optionDonutChart');
        const titleLine = toRef(props, 'titleLineChart');
        const optionLine = toRef(props, 'optionLineChart');
        const titleDonut = toRef(props, 'titleDonutChart');
        const propsCompareList = toRef(props, 'dataCompareList');
        const dataCompareUpdate = ref<any>([]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkMove = (evt: any) => {
            log({ checkMove: evt.draggedContext.element });
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onEnd = (data: any) => {
            log({ onEnd: data });
        }

        const handleAddCompare = () => {
            router.push('/');
        }

        const handleRemoveCompare = (id: string) => {
            emit("action:remove", id);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const viewerPaginate = (viewers: any, current: number) => {

            const data = {
                data: viewers[current - 1],
                current: current == 1 ? 1 : current
            };
            // log(data)
            return data;
        }

        // const dataCompareUpdate = computed(() => {
        //     afterDrag.value = propsCompareList.value;
        //     return afterDrag.value
        // });

        watch(() => dataCompareUpdate.value, (newVal) => {
            if (!newVal) return;
            emit("action:compare", newVal);
        });

        onMounted(() => {
            dataCompareUpdate.value = propsCompareList.value;
            if (dataCompareUpdate.value.length == 0) {
                router.push('/');
            }
        });

        onUpdated(() => {
            dataCompareUpdate.value = propsCompareList.value;
            if (dataCompareUpdate.value.length == 0) {
                router.push('/');
            }
        });

        return {
            onEnd,
            option,
            checkMove,
            titleLine,
            optionLine,
            titleDonut,
            viewerPaginate,
            propsCompareList,
            handleAddCompare,
            dataCompareUpdate,
            handleRemoveCompare,
        }
    }
});
