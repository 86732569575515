import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa21ed1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "important-list" }
const _hoisted_2 = { class: "important-content" }
const _hoisted_3 = { class: "content-box" }
const _hoisted_4 = { class: "package-img" }
const _hoisted_5 = { class: "package-name" }
const _hoisted_6 = { class: "content-box end" }
const _hoisted_7 = { class: "important-items" }
const _hoisted_8 = { class: "sec" }
const _hoisted_9 = { class: "percent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImage = _resolveComponent("BaseImage")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importanceElement.slice(0, _ctx.max), ({ name, image, showtime, eyecatch }, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseImage, {
                data: image,
                width: _ctx.width,
                height: _ctx.height
              }, null, 8, ["data", "width", "height"])
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(name), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", null, [
                _createTextVNode("Showtime "),
                _createElementVNode("span", _hoisted_8, _toDisplayString(showtime), 1)
              ]),
              _createElementVNode("li", null, [
                _createTextVNode("Eye Catching "),
                _createElementVNode("span", _hoisted_9, _toDisplayString(eyecatch) + "%", 1)
              ])
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}