import { InjectionKey } from "vue";
import { createStore, createLogger, Store } from "vuex";
import AuthenService from "../services/authen.service";
// import { log } from '@/helpers'

// Import modules
import * as modules from "./modules";

export interface State {
  appToken: string | null;
  appLoading: boolean;
  appVerifyToken: boolean;
  [key: string]: any;
}

// Types for getters, mutations and actions
export const types = {
  APP_CONFIG: "APP_CONFIG",
  APP_LOADING: "APP_LOADING",
  GET_APP_TOKEN: "GET_APP_TOKEN",
  INIT_APP_TOKEN: "INIT_APP_TOKEN",
  VERIFY_APP_TOKEN: "VERIFY_APP_TOKEN",
  GET_TOKEN_WITH_GOOGLE_SIGNIN: "GET_TOKEN_WITH_GOOGLE_SIGNIN"
};

export const key: InjectionKey<Store<State>> = Symbol();

const authService = AuthenService.getInstance();

export const store = createStore<State>({
  state: {
    appConfig: [],
    appLoading: false,
    appVerifyToken: false,
    appToken: localStorage.getItem("hashToken"),
  },
  getters: {
    [types.APP_CONFIG]: (state) => state.appConfig,
    [types.APP_LOADING]: (state) => state.appLoading,
    [types.GET_APP_TOKEN]: (state) => state.appToken,
    [types.VERIFY_APP_TOKEN]: (state) => state.appVerifyToken,
  },
  mutations: {
    [types.APP_CONFIG](state, payload) {
      state.appConfig = payload
    },
    [types.APP_LOADING](state, payload) {
      state.appLoading = payload
    },
    [types.INIT_APP_TOKEN](state, payload) {
      state.appToken = payload
    },
    [types.VERIFY_APP_TOKEN](state, payload) {
      state.appVerifyToken = payload;
    }
  },
  actions: {
    async [types.APP_CONFIG]({ commit }, payload) {
      const { appconfig, result } = await authService.appConfig(payload);
      if (result === 'complete')
        commit("APP_CONFIG", appconfig);
    },
    async [types.GET_TOKEN_WITH_GOOGLE_SIGNIN]({ commit, dispatch }, payload) {
      const { token } = payload;
      // commit("APP_LOADING", true);
      commit("INIT_APP_TOKEN", token);
      await dispatch("VERIFY_APP_TOKEN", token);
    },
    async [types.VERIFY_APP_TOKEN]({ commit }, payload) {
      // Init Class AuthenService
      const validToken = await authService.verify(payload);
      const status = validToken?.data.status;
      // commit("APP_LOADING", false);
      commit("VERIFY_APP_TOKEN", status);
    },
    async [types.APP_LOADING]({ commit }, payload) {
      // Init Class AuthenService
      commit("APP_LOADING", payload);
    }
  },
  modules,
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
});
