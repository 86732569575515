import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb3df9dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-notice" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "link-item",
  href: "/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["message", { show: _ctx.isVisible, hide: !_ctx.isVisible }])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["message-notice-content", {
						'message-success': _ctx.type === 'success',
						'message-warning': _ctx.type === 'warning',
					}])
              }, [
                _createElementVNode("span", {
                  class: "message-notice-title",
                  innerHTML: _ctx.message
                }, null, 8, _hoisted_2),
                (_ctx.isButton)
                  ? (_openBlock(), _createElementBlock("a", _hoisted_3, "ยกเลิก"))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}