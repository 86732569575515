import { defineComponent, toRef, ref, watch, reactive, toRefs } from "vue";

export default defineComponent({
    name: "BaseModal",
    props: {
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isUpdate: {
            type: Boolean,
            required: false,
            default: false
        },
        dataTitle: {
            type: String,
            required: false
        },
        dataWidth: {
            type: String,
            required: false
        },
        dataClass: {
            type: String,
            required: false
        },
        dataMaskStyle: {
            type: Object,
            required: false
        },
        dataPopup: {
            type: Object,
            required: false
        }
    },
    emits: ["modal:update"],
    setup(prop, { emit }) {

        const visible = ref<boolean>(false);
        const modalVisible = toRef(prop, 'isVisible');
        const state = reactive({
            data: ""
        });

        const handleClose = () => {
            emit("modal:update", visible.value);
        }

        watch(() => modalVisible.value, (newVisible) => {
            if (!newVisible) return
            visible.value = newVisible;
        });

        return {
            visible,
            handleClose,
            ...toRefs(state)
        }
    }
});
