import { defineComponent, reactive, computed } from "vue";
import { useRoute } from 'vue-router'

// Interfaces
import {
    // IFilters,
    propsLogo,
    propsCompare,
    // propsEmotion,
    IBasePropsList,
} from "@/interfaces/component.interface";

export default defineComponent({
    name: "CompareLayout",
    setup() {

        const route = useRoute();

        // Logo
        const dataLogo = reactive<propsLogo>({
            image_expand: require('@/assets/images/logo_xsight.png'),
            image_collapse: require('@/assets/images/logo_xsight_sidebar.png'),
        });

        const dataHeader = reactive<propsCompare>({
            name: "Select project to compare",
            subtext: "",
        });

        // Create a reactive object to store the setting data
        const dataButton = computed(() => {
            const button: IBasePropsList[] = [];
            if (route.name === "CompareView") {
                Object.assign(button, [{
                    name: "Back to home",
                    icon: 'home',
                    path: "/"
                }])
            } else {
                Object.assign(button, [{
                    name: "Back",
                    icon: 'back',
                    path: "/"
                }])
            }
            return button;
        })

        return {
            dataLogo,
            dataButton,
            dataHeader
        };
    },
});