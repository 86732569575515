import { defineComponent } from "vue";

export default defineComponent({
    name: "BaseMessage",
    props: {
        isVisible: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        isButton: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});
