import { defineComponent, toRef, inject, computed } from "vue";

// Components
import { BaseLogo, BaseList, BaseButton } from '@/components/General';

export default defineComponent({
    name: "TheSidebar",
    components: {
        BaseLogo,
        BaseList,
        BaseButton
    },
    props: {
        isExpand: {
            type: Boolean,
            default: false
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        dataLogo: {
            type: Object,
        },
        dataButton: {
            type: Object,
        },
        dataMenu: {
            type: Array
        },
        dataSetting: {
            type: Object
        }
    },
    emits: ["action:expand", "modal:visible"],
    setup(prop, { emit }) {

        const isSidebarExpand = toRef(prop, 'isExpand');
        const isVisibleModal = toRef(prop, 'isVisible');
        const logoData = toRef(prop, 'dataLogo');
        const buttonData = toRef(prop, 'dataButton');
        const listData: any = toRef(prop, 'dataMenu');
        const settingData = toRef(prop, 'dataSetting');
        const roleUser: any = inject('authUser');
        const appConfig: any = inject('appConfig');

        const handleSidebarExpand = () => {
            emit("action:expand");
        }

        const handleModal = () => {
            emit("modal:visible", isVisibleModal);
        }

        const isCreateProject = computed(() => {
            const value = appConfig.value.filter((config: any) => config.key.toLowerCase() === "createproject")[0]?.value
            const isShow = value == '0' ? false : true;
            return isShow;
        })

        // handler sibebar config enable/disable
        const hideList = computed(() => appConfig.value.filter((o: any) => (o.value === '0')).map((config: any) => (config.key)))
        const sidebarMenu = computed(() => {
            listData.value.map((o: any) => {
                if (hideList.value.includes(o.key)) {
                    o.isEnable = false
                }
            })
            return listData.value;
        })

        return {
            sidebarMenu,
            roleUser,
            logoData,
            listData,
            buttonData,
            settingData,
            handleModal,
            isVisibleModal,
            isSidebarExpand,
            isCreateProject,
            handleSidebarExpand,
        };
    }
});
