import type { Ref } from 'vue'
import { defineComponent, toRef } from "vue";
import { IBasePropsList } from '@/interfaces/component.interface';

interface List extends IBasePropsList {
    id?: Ref<number>
}

export default defineComponent({
    name: "BaseList",
    props: {
        isExpand: {
            type: Boolean,
            // required: false,
            default: false
        },
        data: {
            type: Object as () => List[] | undefined,
            // required: true
        },
        align: {
            type: String,
            default: ""
        },
        customClass: {
            type: Boolean
        }
    },
    setup(prop) {

        const propsList = toRef(prop, 'data');
        const propsAlign = toRef(prop, 'align');
        const propsIsExpand = toRef(prop, 'isExpand');
        const propsClassName = toRef(prop, 'customClass');

        const handleLogout = () => {
            localStorage.removeItem('hashToken');
            window.location.reload();
        }

        const backwardToHome = () => {
            window.location.assign(`${window.location.origin}/`);
        }

        return {
            propsList,
            propsAlign,
            handleLogout,
            propsIsExpand,
            propsClassName,
            backwardToHome,
        }
    }
});
