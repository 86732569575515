/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, watch, computed, onBeforeUnmount, onUpdated } from "vue";
import VideoEmotion from "@/components/video-emotion/video-emotion.vue";
import videojsplayer from "@/components/video-js-player/video-js-player.vue";
import VideoHeatmap from "@/components/video-heatmap/video-heatmap.vue";
import VideoEyeimpact from "@/components/video-eyecontact/video-eyecontact.vue";
import VideoSceneSelect from "@/components/video-sceneselect/video-sceneselect.vue";

import WigetPackage from "@/components/wiget-package/wiget-package.vue";
import VideoLegends from "@/components/VideoLegends/VideoLegends.vue";
import PackageLegends from "@/components/PackageLegends/PackageLegends.vue";
import _ from "lodash";

import {
  DashboardEmotion,
  HeatMapPoint,
  EyeimpactPoint,
} from "@/interfaces/dashboard.interface";
import {
  IVDOFrame,
  IVDOParticipant,
  EyePoint,
  EyePointFrame,
  IScenedata,
} from "@/interfaces/advertise.interface";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import { log, SecondToMinute, capitalizeFirstLetter } from "@/helpers";
import { GridComponent, MarkPointComponent } from "echarts/components";

echarts.use([
  LineChart,
  GridComponent,
  CanvasRenderer,
  MarkPointComponent,
  UniversalTransition,
]);

import { useStore } from "vuex";
import { key } from "@/store";
import VChart from "vue-echarts";
import { useRoute } from 'vue-router'
// import { ConsoleSqlOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "video-main",
  components: {
    VChart,
    VideoEmotion,
    VideoHeatmap,
    videojsplayer,
    VideoEyeimpact,
    VideoSceneSelect,
    WigetPackage,
    VideoLegends,
    PackageLegends
  },
  props: ['SetVDO', 'optionDonutData', 'dataPackages'],
  setup(props) {
    const route = useRoute()
    const store = useStore(key);
    const TotalTimeVDO = ref<number>(0);
    const ShowHeatmap = ref<boolean>(false);
    const ShowEyeContact = ref<boolean>(false);
    const ShowSceneSelect = ref<boolean>(false);
    const maingraph = ref();
    const CurrentTime = ref<number>(0);
    const videoOptions = ref<any>({
      fluid: true,
      controls: true,
      autoplay: false,
      responsive: true,
      aspectRatio: "16:9",
      bigPlayButton: false,
      controlBar: {
        pictureInPictureToggle: false,
      },
      sources: [
        {
          src: null,
          type: 'video/mp4'
        }
      ]
    });
    const ShowGraph = ref<boolean>(false);
    const videoplayerModal = ref();
    const VideoEmotion = ref();
    const PackageEmotion = ref();
    const FrameLists = ref<IVDOFrame[]>();
    const Scenedata = ref<IScenedata[]>([]);
    const FrameDatail = ref<any>({
      emotiondata: {
        angry: "-",
        disgusted: "-",
        happy: "-",
        sad: "-",
        surprised: "-",
        fearful: "-",
      },
      expression: "-",
      Participants: [],
      eyeimpact: "-",
      eyeattention: "-",
    });


    const EyePointData = ref<EyePointFrame[]>();
    const EyePointMediawitdh = ref<number>(0);
    const EyePointMediaheight = ref<number>(0);
    const option = ref({
      xAxis: {
        show: true,
        type: "category",
        axisLabel: {
          formatter: (value: number) => {
            return `${SecondToMinute(value)}`;
          },
          color: "#636E72",
          fontSize: 8,
          fontFamily: "IBM Plex Light",
        },
        axisTick: {
          length: 2,
          show: true,
        },
        axisLine: {
          // show: false
          lineStyle: {
            color: "#636E72", //e3e3e3
            width: 1,
            opacity: 0.1,
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        show: true,
        type: "value",
        max: function (value: any) {

          const MaxY = GetMaxY(value.max);

          return MaxY;
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${(value * 100).toFixed(0)}%`;
          },
          color: "#636E72",
          fontSize: 8,
          fontFamily: "IBM Plex Light",
        },
        axisTick: {
          length: 2,
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#636E72", //e3e3e3
            width: 1,
            opacity: 0.1,
          },
        },
        splitLine: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          if (params[0].color) {


            let TxtTooltip = `
                ${SecondToMinute(params[0].name)}
                <br>`;

            for (let x = 0; x <= 9; x++) {
              if (params[x]) {

                const labelName = params[x].seriesName == "signal"
                  ? "Emotion Intensity" : params[x].seriesName == "eye attention"
                    ? "Video Attention" : params[x].seriesName == "eye impact"
                      ? "Visual Impact" : params[x].seriesName === "attentionsignalscore"
                        ? "Attention Signals Score" : capitalizeFirstLetter(params[x].seriesName);

                TxtTooltip += `<span style="color:${params[x].color};">${labelName}</span>:  ${(params[x].data * 100).toFixed(2)} %
                    <br>`;
              }
            }
            return TxtTooltip;
          } else {
            return "";
          }
        },
        borderWidth: 1,
        borderColor: "rgba(193, 17, 21, 0.3)",
        backgroundColor: "#2D3436",
        padding: [5, 10],
        textStyle: {
          color: "#FFFFFF",
          fontSize: 12,
          fontFamily: "IBM Plex Light",
        },
      },
      grid: {
        left: 40,
        top: 20,
        right: 20,
        bottom: 20,
      },
      series: [],
    });

    const stateVdo = ref(false);

    const DashboardConfig = ref<DashboardEmotion[]>([
      {
        name: "disgusted",
        Isshow: false,
      },
      {
        name: "sad",
        Isshow: false,
      },
      {
        name: "happy",
        Isshow: false,
      },
      {
        name: "surprised",
        Isshow: false,
      },
      {
        name: "fearful",
        Isshow: false,
      },
      {
        name: "angry",
        Isshow: false,
      },
      {
        name: "signal",
        Isshow: route.name === 'PackageInformationView' ? false : true,
      },
      {
        name: "attentionsignalscore",
        Isshow: route.name === 'PackageInformationView' ? false : true,
      },
      {
        name: "eye impact",
        Isshow: true,
      },
    ]);

    const DashboardSeries = ref<any[]>([]);

    const UpdateTime = (Playtime: string) => {
      const PlaytimeSec: any = Math.floor(parseFloat(Playtime));
      CurrentTime.value = PlaytimeSec;
      const FilterParticipant = store.getters["advertise/REQUEST_ADS_DETAIL"].FilterUser.map((user: any) => user.profileid);

      if (PlaytimeSec == 0) {
        FrameDatail.value = {
          CurrentTime: PlaytimeSec,
          emotiondata: {
            angry: "-",
            disgusted: "-",
            happy: "-",
            sad: "-",
            surprised: "-",
            fearful: "-",
          },
          expression: "-",
          signalscore: "-",
          Participants: null,
        };
      } else {
        let AngryPercent = 0,
          disgustedPercent = 0,
          happyPercent = 0,
          sadPercent = 0,
          surprisedPercent = 0,
          signalscore = 0,
          fearfulPercent = 0;
        // console.log(...DashboardSeries.value)
        // log({ PlaytimeSec });
        const dataSeriesAngry = DashboardSeries.value.find(emo => emo.name == 'angry').data || [];
        const dataSeriesDisgusted = DashboardSeries.value.find(emo => emo.name == 'disgusted').data || [];
        const dataSeriesHappy = DashboardSeries.value.find(emo => emo.name == 'happy').data || [];
        const dataSeriesSad = DashboardSeries.value.find(emo => emo.name == 'sad').data || [];
        const dataSeriesSurprised = DashboardSeries.value.find(emo => emo.name == 'surprised').data || [];
        const dataSeriesFearful = DashboardSeries.value.find(emo => emo.name == 'fearful').data || [];

        const dataSeriesSignalScore = DashboardSeries.value.find(serie => serie.name === 'attentionsignalscore').data || [];

        // log({ DashboardSeries: dataSeriesAngry })
        // log({ DashboardSerieshappy: dataSeriesHappy })


        // AngryPercent
        if (PlaytimeSec > dataSeriesAngry.length) {
          AngryPercent = 0;
        } else if (dataSeriesAngry) {
          AngryPercent = isNaN(dataSeriesAngry[PlaytimeSec] * 100)
            ? 0
            : dataSeriesAngry[PlaytimeSec] * 100;
        } else {
          AngryPercent = 0;
        }
        // log({ AngryScoreOrigin: dataSeriesAngry[PlaytimeSec] })
        // log({ AngryScore: AngryPercent })

        // disgustedPercent
        if (PlaytimeSec > dataSeriesDisgusted.length) {
          disgustedPercent = 0;
        } else if (dataSeriesDisgusted) {
          disgustedPercent = isNaN(dataSeriesDisgusted[PlaytimeSec] * 100)
            ? 0
            : dataSeriesDisgusted[PlaytimeSec] * 100;
        } else {
          disgustedPercent = 0;
        }

        // happyPercent
        if (PlaytimeSec > dataSeriesHappy.length) {
          happyPercent = 0;
        } else if (dataSeriesHappy) {
          happyPercent = isNaN(dataSeriesHappy[PlaytimeSec] * 100)
            ? 0
            : dataSeriesHappy[PlaytimeSec] * 100;
        } else {
          happyPercent = 0;
        }

        // sadPercent
        if (PlaytimeSec > dataSeriesSad.length) {
          sadPercent = 0;
        } else if (dataSeriesSad) {
          sadPercent = isNaN(dataSeriesSad[PlaytimeSec] * 100)
            ? 0
            : dataSeriesSad[PlaytimeSec] * 100;
        } else {
          sadPercent = 0;
        }

        // surprisedPercent
        if (PlaytimeSec > dataSeriesSurprised.length) {
          surprisedPercent = 0;
        } else if (dataSeriesSurprised) {
          surprisedPercent = isNaN(dataSeriesSurprised[PlaytimeSec] * 100)
            ? 0
            : dataSeriesSurprised[PlaytimeSec] * 100;
        } else {
          surprisedPercent = 0;
        }

        // fearfulPercent
        if (PlaytimeSec > dataSeriesFearful.length) {
          fearfulPercent = 0;
        } else if (dataSeriesFearful) {
          fearfulPercent = isNaN(dataSeriesFearful[PlaytimeSec] * 100)
            ? 0
            : dataSeriesFearful[PlaytimeSec] * 100;
        } else {
          fearfulPercent = 0;
        }

        const expressionPercent =
          AngryPercent +
          disgustedPercent +
          happyPercent +
          sadPercent +
          surprisedPercent +
          fearfulPercent;

        let FrameParticipants = <IVDOParticipant[] | null>null;
        if (FrameLists.value) {
          const FrameTime = FrameLists.value.find(
            (frame) => frame.time == PlaytimeSec.toString()
          );
          if (FrameTime) {
            const FrameTimeParticipants = FrameTime.participants.filter(
              (frame) => FilterParticipant.includes(frame.id)
            );

            // FilterParticipant
            FrameParticipants = FrameTimeParticipants;
          }
        }


        if (dataSeriesSignalScore[PlaytimeSec]) {
          signalscore = dataSeriesSignalScore[PlaytimeSec] * 100;
        }


        FrameDatail.value = {
          CurrentTime: PlaytimeSec,
          emotiondata: {
            angry: AngryPercent.toFixed(2),
            disgusted: disgustedPercent.toFixed(2),
            happy: happyPercent.toFixed(2),
            sad: sadPercent.toFixed(2),
            surprised: surprisedPercent.toFixed(2),
            fearful: fearfulPercent.toFixed(2),
          },
          expression: expressionPercent.toFixed(2),
          Participants: FrameParticipants,
          signalscore: signalscore,
        };
        // log({FrameDatail: FrameDatail.value.Participants})
      }


      if (PlaytimeSec > 0) {
        if (route.name !== 'PackageInformationView')
          VideoEmotion.value.handleTabClick(true);
      } else {
        if (route.name !== 'PackageInformationView')
          VideoEmotion.value.handleTabClick(false);
      }

      SetHeatMap(parseInt(Playtime));
      SetEyeContact(parseInt(Playtime));
    };

    const ChangeTime = () => {
      videoplayerModal.value.VideoSeek(CurrentTime.value);
    };

    const selectScene = (StartTime: number) => {
      // log(`select scenec ${StartTime}`)
      videoplayerModal.value.VideoSeek(StartTime);
    };

    const StopVDO = () => {
      // log(`StopVDO`)
    };

    const ToggleSerie = (item: DashboardEmotion) => {

      if (item.Isshow) {
        maingraph.value.dispatchAction({
          type: "legendUnSelect",
          name: item.name,
        });
        item.Isshow = !item.Isshow;
      } else {
        maingraph.value.dispatchAction({
          type: "legendSelect",
          name: item.name,
        });
        item.Isshow = !item.Isshow;
      }

    };

    const ToggleHeatmap = () => {
      ShowHeatmap.value = !ShowHeatmap.value;
      if (ShowHeatmap.value) {
        setTimeout(() => {
          SetHeatMap(CurrentTime.value);
        }, 500);
      }
    };

    const ToggleEyeContact = () => {
      ShowEyeContact.value = !ShowEyeContact.value;
    };

    const ToggleScene = () => {
      ShowSceneSelect.value = !ShowSceneSelect.value;
    };

    // HEATMAP
    const HeatMapList = ref<HeatMapPoint[]>();

    const EyeData = ref<EyeimpactPoint>({
      left: -10,
      top: -10,
      width: 0,
      height: 0,
    });

    const GetMaxY = (MaxData: number) => {
      if (MaxData <= 0.1) {
        return 0.1;
      } else if (MaxData <= 0.2) {
        return 0.2;
      } else if (MaxData <= 0.3) {
        return 0.3;
      } else if (MaxData <= 0.4) {
        return 0.4;
      } else if (MaxData <= 0.5) {
        return 0.5;
      } else if (MaxData <= 0.6) {
        return 0.6;
      } else if (MaxData <= 0.7) {
        return 0.7;
      } else if (MaxData <= 0.8) {
        return 0.8;
      } else if (MaxData <= 0.9) {
        return 0.9;
      } else {
        return 1;
      }
    };

    watch(
      () => store.getters["advertise/VDO_FRAME"],
      (FrameData) => {
        if (FrameData) {
          TotalTimeVDO.value = FrameData.length;
          FrameLists.value = FrameData;
        }
      }, { immediate: true }
    );

    watch(
      () => store.getters["advertise/VDO_EMOTION"],
      (FrameEmotionData) => {
        const frameCount: any = FrameEmotionData[0].data || [];
        // log({ FrameEmotionData })
        // log(Math.max(...FrameEmotionData[6].data))
        DashboardSeries.value = FrameEmotionData;
        ShowGraph.value = true;
        // console.log(DashboardSeries.value)
        // console.log(option.value)
        Object.assign(option.value, { series: DashboardSeries.value })

        // log({ option: option.value });
        // log({ videoOptions: videoOptions.value });

        // check option legendUnSelect & hide eye attention, eye impact
        if (typeof option.value === "object") {
          setTimeout(() => initialize(), 1000);
        }
      },
      { deep: true, immediate: true },
    );

    // watch(
    //   () => option.value,
    //   (newVal) => {
    //     console.log('xjfdkl;s')
    //     // if (!newVal) return;
    //     option.value = newVal;
    //     const { video } = store.getters["advertise/REQUEST_ADS_DETAIL"];
    //     console.log({ newVal: video })
    //     if (video) {
    //       Object.assign(videoOptions.value, {
    //         sources: [
    //           {
    //             src: video,
    //             type: 'video/mp4'
    //           }
    //         ]
    //       })
    //     }
    //   }, { immediate: false }
    // );

    watch(
      () => store.getters["advertise/REQUEST_ADS_DETAIL"],
      (ads) => {
        SetVDO();
      },
      { deep: true, immediate: false }
    );

    const SetVDO = () => {
      const ads = store.getters["advertise/REQUEST_ADS_DETAIL"]

      if (CurrentTime.value >= 1) {
        videoplayerModal.value.VideoSeek(CurrentTime.value);
      }

      const { video } = ads;
      if (video) {
        Object.assign(videoOptions.value, {
          sources: [
            {
              src: video,
              type: 'video/mp4'
            }
          ]
        })
      }
    }

    watch(
      () => store.getters["advertise/VDO_HEATMAP"],
      (newData: EyePoint) => {
        if (newData) {
          const { heatmap, vdoHeight, vdoWidth } = newData;
          EyePointData.value = heatmap;
          EyePointMediawitdh.value = vdoWidth;
          EyePointMediaheight.value = vdoHeight;
        }
      }, { immediate: true }
    );

    watch(
      () => store.getters["advertise/VDO_SCENE_ALL"],
      (sceneListData: IScenedata[]) => {

        if (sceneListData) {
          Scenedata.value = _.sortBy(sceneListData, ['StartTime']);
        }
        // if (TotalTimeVDO.value > 0) {

        //   const slider = document.getElementById("slider_second") as HTMLElement;
        //   console.log({ slider: slider.clientWidth })
        //   const width = (slider.clientWidth / TotalTimeVDO.value);
        //   console.log({ width })
        //   slider.setAttribute('style', `width: calc(100% - ${width}px); left: ${width}px;`)
        // }
      }, { immediate: true }
    );

    const SceneListdata = computed(() => {
      return Scenedata.value.map((scene: IScenedata) => {
        if (FrameLists.value) {
          if (scene.StartTime == 0) {
            const FrameSecdata = FrameLists.value.find(
              (frame) => frame.time == "1"
            );
            if (FrameSecdata) {
              scene.Photolist = [FrameSecdata.framethumburl];
            }
          } else {
            const FrameSecdata = FrameLists.value.find(
              (frame) => frame.time == scene.StartTime.toString()
            );
            if (FrameSecdata) {
              scene.Photolist = [FrameSecdata.framethumburl];
            }
          }
        }

        return scene;
      });
    });

    onMounted(() => {
      // log('xxz', props.SetVDO)
      if (props.SetVDO) {
        SetVDO()
      }
      // console.log('onMounted')
    });

    onBeforeUnmount(() => {
      // console.log('onBeforeUnmount')
      Object.assign(option.value, { series: [] })
    })

    const SetHeatMap = (Sec: number) => {
      if (EyePointData.value) {
        const EyePointCurrent = EyePointData.value.find(
          (point) => point.time == Sec
        );

        if (EyePointCurrent) {
          const MainVDODom = document.getElementById("MainVideo");
          const HeatmapDom = document.getElementById("heatmapjs-container");

          if (MainVDODom && HeatmapDom) {
            const Mainvdowidth = MainVDODom.offsetWidth;
            const HeatMapWidthPercent = Mainvdowidth / 1000;
            HeatmapDom.style.transform = `scale(${HeatMapWidthPercent})`;
          }

          const CompilePercent = 1000 / EyePointMediawitdh.value;

          // const HeatMapArray = EyePointData.value[Sec].heatmapdata;
          // log(EyePointData.value.find(point => point.time ==  Sec ))
          const HeatMapArray = EyePointCurrent.heatmapdata;

          HeatMapList.value = [];
          HeatMapArray.forEach((point) => {
            const PointData = {
              x: Math.ceil(
                ((point.left * EyePointMediawitdh.value) / 100) * CompilePercent
              ),
              y: Math.ceil(
                ((point.top * EyePointMediaheight.value) / 100) * CompilePercent
              ),
              value: point.value * 70,
            };
            HeatMapList.value?.push(PointData);
          });
        } else {
          HeatMapList.value = [];
        }
      }
    };

    const SetEyeContact = (Sec: number) => {
      if (EyePointData.value) {
        const EyePointCurrent = EyePointData.value.find(
          (point) => point.time == Sec
        );
        if (EyePointCurrent) {
          const eyeImpactData = EyePointCurrent.eyeImpact;
          const PointData = {
            left: eyeImpactData.left,
            top: eyeImpactData.top,
            width: eyeImpactData.width,
            height: eyeImpactData.height,
          };
          EyeData.value = PointData;

          FrameDatail.value.eyeimpact = eyeImpactData.percent.toFixed(2);
          FrameDatail.value.eyeattention =
            EyePointCurrent.eyeAttention.toFixed(2);
        } else {
          const PointData = {
            left: -10,
            top: -10,
            width: 0,
            height: 0,
          };
          EyeData.value = PointData;
          FrameDatail.value.eyeimpact = "-";
          FrameDatail.value.eyeattention = "-";
        }
      }
      // log(HeatMapList.value)
    };

    const markerClick = (event: any) => {
      // log('xxx')
      videoplayerModal.value.VideoSeek(event.dataIndex);
    };

    const markerClick2 = (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY];
      const pointInGrid = maingraph.value.convertFromPixel(
        "series",
        pointInPixel
      );
      videoplayerModal.value.VideoSeek(pointInGrid[0]);
    };

    const initialize = async () => {
      const frameCount: any = DashboardConfig.value.filter((emo) => !emo.Isshow).length
      if (frameCount > 0)
        DashboardConfig.value.filter((emo) => !emo.Isshow).forEach((serie) => {
          // log({ series: typeof maingraph.value });
          // log({ series: maingraph.value });
          if (maingraph.value)
            maingraph.value.dispatchAction({
              type: "legendUnSelect",
              name: serie.name,
            });
        });
    };

    return {
      option,
      StopVDO,
      EyeData,
      stateVdo,
      maingraph,
      ShowGraph,
      ChangeTime,
      UpdateTime,
      SetHeatMap,
      FrameDatail,
      ShowHeatmap,
      ToggleSerie,
      ToggleScene,
      CurrentTime,
      selectScene,
      HeatMapList,
      markerClick,
      markerClick2,
      TotalTimeVDO,
      videoOptions,
      VideoEmotion,
      ToggleHeatmap,
      SceneListdata,
      ShowEyeContact,
      ShowSceneSelect,
      DashboardSeries,
      DashboardConfig,
      videoplayerModal,
      ToggleEyeContact,
      capitalizeFirstLetter,
      PackageEmotion
    };
  },
});
